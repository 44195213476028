import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, Grid, useTheme } from "@material-ui/core";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import DndDeckDisplay from "../../../components/Dnd/DndDeckDisplay";
import DndCardSet from "../../../components/Dnd/DndCardSet";
import Text from "../../../components/Text";
import { DndCard, DndCardGroup, ROUTES } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";
import { DropTargetMonitor } from "react-dnd/dist/types/types";
import DndCardDisplay from "../../../components/Dnd/DndCardDisplay";
import DndCardHolder from "../../../components/Dnd/DndCardHolder";
import useDeckForCategory from "../../../state/dialogue/selectors/use-deck-for-category";
import { useHistory, useParams } from "react-router-dom";
import useNextAvailableDecks from "../../../state/dialogue/selectors/use-uncompleted-decks";
import { isAllCardsChosen, validateTop4Cards } from "./utils";
import ButtonTertiary from "../../../components/Button/ButtonTertiary";
import DialogueLayout from "../../../layouts/DialogueLayout";
import { moveBetweenDecks } from "../../../utils/deck-operations";
import useCurrentDialogue from "../../../state/dialogue/selectors/use-current-dialogue";

interface Props {}

const DialogueDeckCategory: FC<Props> = (): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const { category } = useParams<{ category: string }>();
  const [dialogue] = useCurrentDialogue();
  const [deck, { saveDeck }] = useDeckForCategory(category);
  const [toBeCompleted] = useNextAvailableDecks(deck.id);
  const [deckCards, setDeckCards] = useState<DndCard<DeckCategory>[]>([]);
  const [top4Cards, setTop4Cards] = useState<DndCard<DeckCategory>[]>([]);
  const [formError, setFormError] = useState("");
  const hasNextDeckAvailable = !!toBeCompleted[0];

  const swapCards = useCallback(
    (
      hoverCard: DndCard<DeckCategory>,
      monitor: DropTargetMonitor,
      card: DndCard<DeckCategory>
    ): void => {
      const isUnassigned = hoverCard.group === DndCardGroup.UNASSIGNED;

      const { fromDeck, toDeck } = moveBetweenDecks({
        id: hoverCard.id,
        fromDeck: isUnassigned ? deckCards : top4Cards,
        fromGroup: hoverCard.group,
        toIndex: card.index,
        toDeck: isUnassigned ? top4Cards : deckCards,
        toGroup: card.group,
      });

      setDeckCards(isUnassigned ? fromDeck : toDeck);
      setTop4Cards(isUnassigned ? toDeck : fromDeck);

      if (isAllCardsChosen(isUnassigned ? toDeck : fromDeck)) {
        setFormError("");
      }
    },
    [deckCards, top4Cards]
  );

  const submitDeck = useCallback(() => {
    const { isValid, errorMessage } = validateTop4Cards(top4Cards);
    setFormError(errorMessage);

    if (!isValid) {
      return;
    }

    saveDeck(deckCards, top4Cards, category);
    history.push(
      hasNextDeckAvailable
        ? toBeCompleted[0].category.toLowerCase()
        : ROUTES.dialogueTop4
    );
  }, [
    top4Cards,
    saveDeck,
    deckCards,
    category,
    history,
    hasNextDeckAvailable,
    toBeCompleted,
  ]);

  const resetDeck = useCallback(() => {
    setFormError("");
    setDeckCards(Array.from(deck.cards));
    setTop4Cards(Array.from(deck.top4));
  }, [deck]);

  useEffect(() => {
    resetDeck();
  }, [resetDeck]);

  return (
    <DialogueLayout
      title={deck.title}
      subTitle={dialogue.client.name}
      description={
        <Box textAlign={"center"}>
          <Text font={theme.font.din} size={1.8} mt={1} mb={1}>
            {deck.description}
          </Text>
          <Text font={theme.font.arialBlack} size={1.5}>
            Rearrange cards in order of importance by dragging them into place.
            Click 'Next Deck' when you are ready to progress.
          </Text>
        </Box>
      }
    >
      <Grid item mb={3}>
        <DndCardSet
          cards={deckCards}
          setCards={setDeckCards}
          onExternalDrop={swapCards}
          occupiedComponent={DndCardDisplay}
          emptyComponent={DndDeckDisplay}
        />
      </Grid>
      <Grid item mt={3}>
        <DndCardSet
          cards={top4Cards}
          setCards={setTop4Cards}
          onExternalDrop={swapCards}
          occupiedComponent={DndCardDisplay}
          emptyComponent={DndCardHolder}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent={"center"}
        columnSpacing={10}
        rowSpacing={4}
      >
        <Grid item xs={12} mt={5}>
          {formError && (
            <Text
              size={1.8}
              color={theme.palette.formError}
              textAlign={"center"}
            >
              {formError}
            </Text>
          )}
        </Grid>
        <Grid item>
          <ButtonTertiary size={"large"} onClick={resetDeck}>
            Reset
          </ButtonTertiary>
        </Grid>
        <Grid item>
          <ButtonPrimary size={"large"} onClick={submitDeck}>
            {hasNextDeckAvailable ? "Next Deck" : "Next Section"}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </DialogueLayout>
  );
};

export default DialogueDeckCategory;
