import { createHook } from "react-sweet-state";
import { DialogueState } from "../types";
import { DialogueActions, DialogueStore } from "..";
import { DndDialogue } from "../../../domain/frontend/models/dnd-dialogue";

const useCurrentDialogue = createHook<
  DialogueState,
  DialogueActions,
  DndDialogue
>(DialogueStore, {
  selector: (state): DndDialogue => state.dialogues[state.currentDialogueIndex],
});

export default useCurrentDialogue;
