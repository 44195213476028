import { DeckCategory } from "../../../domain/backend";
import { DndCard } from "../../../domain/frontend";

export function areAllCardsPrioritized(
  cards: DndCard<DeckCategory>[]
): boolean {
  return cards.every((card) => card.item?.priority && card.item.priority > 0);
}

export function isFormValid(cards: DndCard<DeckCategory>[]): {
  isValid: boolean;
  errorMessage: string;
} {
  const isValid = areAllCardsPrioritized(cards);

  return {
    isValid,
    errorMessage: isValid
      ? ""
      : "Please choose a health index for each shared top 4 card before continuing.",
  };
}
