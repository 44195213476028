import { DeckCategory } from "../../backend";
import { DndCardGroup } from "../enums/dnd-card-group";
import { DndDeck } from "../models/dnd-deck";
import { ROUTES } from "./routes";
import { deepFreeze } from "../../../utils/deep-freeze";
import { createDndCard } from "../../../utils/create-dnd-card";

export const RELATIONSHIP_DECK: DndDeck<DeckCategory.RELATIONSHIP> = deepFreeze(
  {
    id: "deck-rel",
    title: "Relationship",
    description: "What are your relationship priorities?",
    category: DeckCategory.RELATIONSHIP,
    href: ROUTES.dialogueDeckCategory.replace(":category", "relationship"),
    cards: [
      {
        id: "rel-0",
        index: 0,
        group: DndCardGroup.UNASSIGNED,
        item: {
          category: DeckCategory.RELATIONSHIP,
          title: "Spend Time with People I Love",
          priority: 0,
        },
      },
      {
        id: "rel-1",
        index: 1,
        group: DndCardGroup.UNASSIGNED,
        item: {
          category: DeckCategory.RELATIONSHIP,
          title: "Enhance & Improve Important Relationships",
          priority: 0,
        },
      },
      {
        id: "rel-2",
        index: 2,
        group: DndCardGroup.UNASSIGNED,
        item: {
          category: DeckCategory.RELATIONSHIP,
          title: "Educate Children & Grandchildren",
          priority: 0,
        },
      },
      {
        id: "rel-3",
        index: 3,
        group: DndCardGroup.UNASSIGNED,
        item: {
          category: DeckCategory.RELATIONSHIP,
          title: "Support/Provide for other Family Members",
          priority: 0,
        },
      },
    ],
    top4: [0, 1, 2, 3].map((num) => createDndCard(num, DndCardGroup.TOP4)),
  }
);
