import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Text from "../../../components/Text";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import useCurrentDialogue from "../../../state/dialogue/selectors/use-current-dialogue";
import DndCardSolitaireSet from "../../../components/Dnd/DndCardSolitaireSet";
import DndDeckDisplay from "../../../components/Dnd/DndDeckDisplay";
import DndCardDisplay from "../../../components/Dnd/DndCardDisplay";
import DndCardSet from "../../../components/Dnd/DndCardSet";
import DndCardHolder from "../../../components/Dnd/DndCardHolder";
import { DndCard, DndCardGroup, ROUTES } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";
import { DropTargetMonitor } from "react-dnd/dist/types/types";
import {
  insertIntoDeck,
  moveBetweenDecks,
  setPriorityForCardInDeck,
} from "../../../utils/deck-operations";
import { createDndCard } from "../../../utils/create-dnd-card";
import { useHistory } from "react-router-dom";
import { validateTop4Cards } from "./utils";
import { DndDecks } from "../../../domain/frontend";
import { isAllCardsChosen } from "../dialogue-deck-category/utils";
import ButtonTertiary from "../../../components/Button/ButtonTertiary";
import { useDialogueState } from "../../../state/dialogue";
import DialogueLayout from "../../../layouts/DialogueLayout";
import Clicker from "../../../components/Clicker";

interface Props {}

const DialogueTopFour: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const [{ dialogues, currentDialogueIndex }] = useDialogueState();
  const [
    { client, decks, overallTop4 },
    { saveOverallTopFour, moveToNextDialogue },
  ] = useCurrentDialogue();
  const [formError, setFormError] = useState("");
  const [finTop4Cards, setFinTop4Cards] = useState<DndCard<DeckCategory>[]>([]);
  const [relTop4Cards, setRelTop4Cards] = useState<DndCard<DeckCategory>[]>([]);
  const [legTop4Cards, setLegTop4Cards] = useState<DndCard<DeckCategory>[]>([]);
  const [expTop4Cards, setExpTop4Cards] = useState<DndCard<DeckCategory>[]>([]);
  const [overallTop4Cards, setOverallTop4Cards] = useState<
    DndCard<DeckCategory>[]
  >([]);

  const { hasNextDialogue, isMultipleClients } = useMemo(() => {
    return {
      isMultipleClients: dialogues.length > 1,
      hasNextDialogue: currentDialogueIndex + 1 < dialogues.length,
    };
  }, [currentDialogueIndex, dialogues.length]);

  const listOfCategoryStacks = useMemo(() => {
    return [
      { cards: relTop4Cards, setCards: setRelTop4Cards },
      { cards: finTop4Cards, setCards: setFinTop4Cards },
      { cards: legTop4Cards, setCards: setLegTop4Cards },
      { cards: expTop4Cards, setCards: setExpTop4Cards },
    ];
  }, [expTop4Cards, finTop4Cards, legTop4Cards, relTop4Cards]);

  const isShared = useMemo(() => {
    return dialogues.length > 1;
  }, [dialogues.length]);

  const createSetCardPriority = useCallback(
    (card: DndCard<DeckCategory>) => {
      return (value: number) =>
        setOverallTop4Cards(
          setPriorityForCardInDeck(card.id, value, overallTop4Cards)
        );
    },
    [overallTop4Cards]
  );

  const getTop4StackByCategory = useCallback(
    (category: string): DndCard<DeckCategory>[] => {
      const deck = decks[category as keyof DndDecks];

      if (!deck) {
        throw new Error(
          `Deck for category ${category} doesn't exist in list of decks.`
        );
      }

      return Array.from(
        deck.top4.map((card) => {
          card.group = DndCardGroup.TOP4;
          return card;
        })
      );
    },
    [decks]
  );

  const moveToOverallTop4 = useCallback(
    (
      hoverCard: DndCard<DeckCategory>,
      monitor: DropTargetMonitor,
      card: DndCard<DeckCategory>
    ): void => {
      const isUnassigned = hoverCard.group === DndCardGroup.TOP4;
      const shouldReplace = !!hoverCard.item && !!card.item;
      const stack = listOfCategoryStacks.find(({ cards }) =>
        cards.find((crd) => crd.id === hoverCard.id)
      );

      if (!stack) {
        throw new Error(`Card not found in Top 4 Stacks ${card.id}.`);
      }

      if (shouldReplace) {
        const { fromDeck, toDeck } = moveBetweenDecks({
          id: hoverCard.id,
          fromDeck: isUnassigned ? stack.cards : overallTop4Cards,
          fromGroup: hoverCard.group,
          toIndex: card.index,
          toDeck: isUnassigned ? overallTop4Cards : stack.cards,
          toGroup: card.group,
        });

        stack.setCards(isUnassigned ? fromDeck : toDeck);
        setOverallTop4Cards(isUnassigned ? toDeck : fromDeck);
      } else {
        const { fromDeck, toDeck } = insertIntoDeck({
          id: hoverCard.id,
          fromDeck: isUnassigned ? stack.cards : overallTop4Cards,
          toIndex: card.index,
          toDeck: isUnassigned ? overallTop4Cards : stack.cards,
          toGroup: card.group,
        });

        if (isAllCardsChosen(isUnassigned ? toDeck : fromDeck)) {
          setFormError("");
        }

        stack.setCards(isUnassigned ? fromDeck : toDeck);
        setOverallTop4Cards(isUnassigned ? toDeck : fromDeck);
      }
    },
    [listOfCategoryStacks, overallTop4Cards]
  );

  const createMoveToCategoryStack = useCallback(
    (
      cards: DndCard<DeckCategory>[],
      setCards: Dispatch<SetStateAction<DndCard<DeckCategory>[]>>
    ) => {
      return (
        hoverCard: DndCard<DeckCategory>,
        monitor: DropTargetMonitor,
        card: DndCard<DeckCategory>
      ): void => {
        const { fromDeck, toDeck } = insertIntoDeck({
          id: hoverCard.id,
          fromDeck: overallTop4Cards,
          toIndex: cards.length - 1,
          toDeck: cards,
          toGroup: card.group,
          backfill: createDndCard(hoverCard.index, hoverCard.group),
        });

        setOverallTop4Cards(fromDeck);
        setCards(toDeck);
      };
    },
    [overallTop4Cards]
  );

  const canDrop = useCallback(
    (
      dropItem: DndCard<DeckCategory>,
      dndCard: DndCard<DeckCategory>
    ): boolean => {
      if (!dndCard.item) {
        return true;
      }

      return dropItem.item?.category === dndCard.item?.category;
    },
    []
  );

  const submitOverallTop4 = useCallback(() => {
    const { isValid, errorMessage } = validateTop4Cards(
      isShared,
      overallTop4Cards
    );
    setFormError(errorMessage);

    if (!isValid) {
      return;
    }

    saveOverallTopFour({
      relationship: relTop4Cards,
      financial: finTop4Cards,
      experience: expTop4Cards,
      legacy: legTop4Cards,
      overall: overallTop4Cards,
    });

    if (hasNextDialogue) {
      moveToNextDialogue();
      history.push(ROUTES.dialogueDecks);
    } else {
      if (isMultipleClients) {
        history.push(ROUTES.dialogueSharedPriorities);
      } else {
        history.push(ROUTES.dialoguePriorities);
      }
    }
  }, [
    expTop4Cards,
    finTop4Cards,
    hasNextDialogue,
    history,
    isMultipleClients,
    isShared,
    legTop4Cards,
    moveToNextDialogue,
    overallTop4Cards,
    relTop4Cards,
    saveOverallTopFour,
  ]);

  const resetCards = useCallback(() => {
    setFormError("");
    setFinTop4Cards(getTop4StackByCategory(DeckCategory.FINANCIAL));
    setExpTop4Cards(getTop4StackByCategory(DeckCategory.EXPERIENCE));
    setLegTop4Cards(getTop4StackByCategory(DeckCategory.LEGACY));
    setRelTop4Cards(getTop4StackByCategory(DeckCategory.RELATIONSHIP));
    setOverallTop4Cards(Array.from(overallTop4));
  }, [getTop4StackByCategory, overallTop4]);

  useEffect(() => {
    resetCards();
  }, [resetCards]);

  return (
    <DialogueLayout
      title={"Top Four"}
      subTitle={client.name}
      description={
        <Text font={theme.font.arialBlack} size={1.5} textAlign={"center"}>
          Now that you have prioritized each set of cards, it is time to pick
          your top 4 overall from these cards displayed below.
          <br />
          Simply click on any 4 cards from the below options.
        </Text>
      }
    >
      <Grid item>
        <DndCardSet
          canDrop={canDrop}
          cards={overallTop4Cards}
          occupiedComponent={DndCardDisplay}
          emptyComponent={DndCardHolder}
          setCards={setOverallTop4Cards}
          onExternalDrop={moveToOverallTop4}
        />
      </Grid>
      {isShared && (
        <Grid
          item
          container
          spacing={isMdDown ? 3 : 4}
          justifyContent={"center"}
        >
          {overallTop4Cards.map((card) => (
            <Grid key={card.id} item>
              <Clicker
                value={card.item?.priority}
                onChange={createSetCardPriority(card)}
                isDisabled={!card.item}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid
        item
        container
        justifyContent={"center"}
        spacing={3}
        mt={isMdDown ? 0 : 2}
      >
        {listOfCategoryStacks.map(({ cards, setCards }, index) => (
          <Grid key={`top4-${index}`} item>
            <DndCardSolitaireSet
              canDrop={canDrop}
              cards={cards}
              faceUpComponent={DndCardDisplay}
              faceDownComponent={DndDeckDisplay}
              onExternalDrop={createMoveToCategoryStack(cards, setCards)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        justifyContent={"center"}
        columnSpacing={10}
        rowSpacing={4}
      >
        <Grid item xs={12} mt={5}>
          {formError && (
            <Text
              size={1.8}
              color={theme.palette.formError}
              textAlign={"center"}
            >
              {formError}
            </Text>
          )}
        </Grid>
        <Grid item>
          <Box>
            <ButtonTertiary size={"large"} onClick={resetCards}>
              Reset
            </ButtonTertiary>
          </Box>
        </Grid>
        <Grid item>
          <ButtonPrimary size={"large"} onClick={submitOverallTop4}>
            {hasNextDialogue ? "Next family member" : "Next Section"}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </DialogueLayout>
  );
};

export default DialogueTopFour;
