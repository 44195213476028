import styled from "styled-components/macro";
import { Grid } from "@material-ui/core";

export const DivDashboard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivDashboardPageContainer = styled.div`
  max-width: 98rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin: 0 1rem;
  }
`;

export const GridClientCardContainer = styled(Grid)`
  background-color: var(--black);
  padding: 2.8rem 2rem 1.4rem;

  &:nth-of-type(2n) {
    background-color: var(--darkGray);
  }
`;
