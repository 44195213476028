import { createGlobalStyle } from "styled-components/macro";
import { Theme } from "@material-ui/core/styles";

export default createGlobalStyle<{ theme: Theme }>`
  body {
    background-color: black;
    color: white;
  }
  
  :root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
    --grid-unit: ${(props) => props.theme.grid.unit};
    --gold: ${(props) => props.theme.palette.gold};
    --goldTint: ${(props) => props.theme.palette.goldTint};
    --black: ${(props) => props.theme.palette.black};
    --bgDropdown: ${(props) => props.theme.palette.bgDropdown};
    --white: ${(props) => props.theme.palette.white};
    --gray: ${(props) => props.theme.palette.gray};
    --darkGray: ${(props) => props.theme.palette.darkGray};
    --g1: calc(var(--grid-unit) * 1);
    --g2: calc(var(--grid-unit) * 2);
    --g3: calc(var(--grid-unit) * 3);
    --g4: calc(var(--grid-unit) * 4);
    --g5: calc(var(--grid-unit) * 5);
    --g6: calc(var(--grid-unit) * 6);
    --g7: calc(var(--grid-unit) * 7);
    --g8: calc(var(--grid-unit) * 8);
    --g9: calc(var(--grid-unit) * 9);
    --g10: calc(var(--grid-unit) * 10);
    --fontArial: ${(props) => props.theme.font.arial};
    --fontArialBlack: ${(props) => props.theme.font.arialBlack};
    --fontDin: ${(props) => props.theme.font.din};
  }
  
  .MuiCalendarPicker-root, .MuiPopover-paper {
    * {
      font-size: 1.4rem
    }
  }
`;
