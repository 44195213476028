import { createTheme } from "@material-ui/core";

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        xxl: 2400,
      },
    },
  },
  {
    card: {
      normal: {
        height: 31.6,
        width: 21.3,
      },
      medium: {
        height: 23.7,
        width: 16,
      },
      small: {
        height: 14.8,
        width: 10,
      },
    },
    navbar: {
      height: 9,
    },
    footer: {
      height: 35.8,
    },
    palette: {
      completed: "#BADA55",
      longTerm: "#3D9BE9",
      optimal: "#E8C061",
      improve: "#FA9600",
      immediate: "#F60419",
      formError: "red",
      gold: "#d5aa29",
      goldTint: "#9d7f1f",
      black: "#000000",
      bgDropdown: "#272727ff",
      white: "#ffffff",
      lightGray: "#989898",
      gray: "#646464ff",
      darkGray: "#2d2e2d",
      formSuccess: "#009345",
      financial: "#009345",
      relationship: "#ED1A24",
      legacy: "#0370BC",
      experience: "#FBB03B",
      successBanner: "#0E6006",
      errorBanner: "#AF0303",
      warningBanner: "#e89512",
    },
    grid: {
      unit: "0.8em",
    },
    font: {
      arial: "Arial, Helvetica, sans-serif",
      arialBlack: '"Arial Black", Helvetica, sans-serif',
      din: '"DIN", sans-serif',
      lulo: '"LuloCleanW01-OneBold", sans-serif',
    },
  }
);

export default theme;
