import React, { FC, forwardRef } from "react";
import { InputBase, useTheme } from "@material-ui/core";
import { InputProps } from "./types";

const Input: FC<InputProps> = forwardRef(
  (props: InputProps, ref): JSX.Element => {
    const theme = useTheme();

    return (
      <InputBase
        ref={ref}
        fullWidth
        sx={{
          backgroundColor: theme.palette.white,
          fontFamily: theme.font.arial,
          fontSize: "1.4rem",
          color: theme.palette.black,
          width: props.width,
          borderRadius: "4px",
          "& .MuiInputBase-input": {
            minHeight: props.multiline ? undefined : "5rem",
            padding: `${props.multiline ? "1rem" : "0"} 1.8rem`,
          },
          "& .MuiInputAdornment-root": {
            marginRight: "2rem",
          },
        }}
        {...props}
      />
    );
  }
);

export default Input;
