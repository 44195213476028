import styled from "styled-components/macro";
import { Box } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

const DividingLine = styled(Box)<{
  horizontal?: boolean;
  vertical?: boolean;
  color?: string;
  theme?: Theme;
}>`
  height: ${(props) => (props.vertical ? "100%" : "1px")};
  width: ${(props) => (props.vertical ? "1px" : "100%")};
  background-color: ${(props) => props.color ?? props.theme.palette.gold};
`;

export default DividingLine;
