import React, { FC } from "react";
import { useDrag, useDrop } from "react-dnd";
import CardDisplay from "../../CardDisplay";
import { DndCardComponentProps } from "../types";
import { DndCard, DndItemType } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";
import { DropTargetMonitor } from "react-dnd/dist/types/types";

interface Props extends DndCardComponentProps {}

const DndCardDisplay: FC<Props> = ({
  size,
  canDrag,
  canDrop,
  dndCard,
  onEnd,
  onHover,
  onExternalDrop,
  dragOpacity,
}): JSX.Element => {
  const [{ isDragging, isDragEnabled }, drag] = useDrag(
    () => ({
      canDrag,
      item: dndCard,
      type: DndItemType.CARD,
      collect: (monitor) => ({
        isDragEnabled: monitor.canDrag(),
        isDragging: monitor.isDragging(),
      }),
      end: (dragCard, monitor) => {
        if (dragCard.group === dndCard.group) {
          onEnd?.(dragCard, monitor, dndCard);
        }
      },
    }),
    [dndCard, canDrag, onEnd]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DndItemType.CARD,
      collect: (monitor: DropTargetMonitor<DndCard<DeckCategory>>) => ({
        canAcceptDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        isExternal:
          monitor.canDrop() && monitor.getItem().group !== dndCard.group,
      }),
      hover: (hoverCard: DndCard<DeckCategory>, monitor) => {
        if (hoverCard.group === dndCard.group) {
          onHover?.(hoverCard, monitor, dndCard);
        }
      },
      drop: (dropCard: DndCard<DeckCategory>, monitor) => {
        if (dropCard.group !== dndCard.group) {
          onExternalDrop?.(dropCard, monitor, dndCard);
        }
      },
      canDrop: (dropCard: DndCard<DeckCategory>, monitor) => {
        return canDrop?.(dropCard, dndCard, monitor) ?? true;
      },
    }),
    [dndCard, onHover, onExternalDrop]
  );

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        cursor: isDragEnabled ? "move" : "auto",
      }}
    >
      <CardDisplay
        size={size}
        card={dndCard.item}
        opacity={dragOpacity !== undefined && isDragging ? dragOpacity : 1}
      />
    </div>
  );
};

export default DndCardDisplay;
