import { AdminUser } from "../dashboard/types";

export type AdminUserDetails = AdminUser & {
  groups: string[];
};

export interface GetUserDetailsRequest {
  userId: string;
}

export interface SetUserGroupsRequest {
  username: string;
  groups: string[];
}

export interface ChangeUserStatusRequest {
  username: string;
  status: UserStatus;
}

export interface DeleteUserRequest {
  username: string;
}

export interface ResendUserInviteRequest {
  email: string;
}

export enum UserStatus {
  ENABLED,
  DISABLED,
}

export type GetUserDetailsResponse = AdminUserDetails;
