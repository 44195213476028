import config from "src/config";
import {
  Dialogue,
  PartialDialogue,
  SharedDialogue,
} from "../domain/backend/models/dialogue";
import httpClient from "./http-client";
import { DeckCategory } from "../domain/backend";
import { DndDialogue } from "../domain/frontend/models/dnd-dialogue";
import { DndCard } from "../domain/frontend";

export async function submitDialogue(
  dndDialogues: DndDialogue[],
  top4SharedCards?: DndCard<DeckCategory>[]
): Promise<void> {
  await httpClient.fetch(`${config.talksig.apiUrl}/dialogues/`, {
    method: "POST",
    json: mapCreateDialogueRequest(dndDialogues, top4SharedCards),
  });
}

function mapCreateDialogueRequest(
  dndDialogues: DndDialogue[],
  top4SharedCards?: DndCard<DeckCategory>[]
):
  | Omit<Dialogue, "id" | "createdDate">
  | Omit<SharedDialogue, "id" | "createdDate"> {
  if (!!dndDialogues?.[1] && !!top4SharedCards) {
    return {
      dialogueOne: mapPartialDialogue(dndDialogues[0]),
      dialogueTwo: mapPartialDialogue(dndDialogues[1]),
      top4SharedCards: top4SharedCards.map((dndCard) => {
        if (!dndCard.item) {
          throw new Error("Top4SharedCard cannot be undefined.");
        }

        return dndCard.item;
      }),
      actions: [],
    };
  }

  return {
    ...mapPartialDialogue(dndDialogues[0]),
    actions: [],
  };
}

function mapPartialDialogue({
  client,
  overallTop4,
}: DndDialogue): PartialDialogue {
  return {
    client,
    overallTop4: overallTop4.map((dndCard) => {
      if (!dndCard.item) {
        throw new Error("OverallTop4 card can't be undefined.");
      }

      return dndCard.item;
    }),
  };
}
