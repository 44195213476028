import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, useTheme } from "@material-ui/core";
import { FormButton } from "./types";
import Text from "../Text";
import NotificationBanner from "../NotificationBanner";

interface Props {
  onSubmit?: () => void;
  isSubmitSuccessful?: boolean;
  successMessage?: string;
  justifyButtons?: "flex-start" | "center" | "flex-end";
  buttons?: FormButton[];
  isLoading?: boolean;
  error?: string;
  children?: any;
}

const Form: FC<Props> = ({
  onSubmit,
  buttons,
  justifyButtons,
  isLoading,
  error,
  isSubmitSuccessful,
  successMessage,
  children,
}): JSX.Element => {
  const theme = useTheme();
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  const wrappedSubmit = useCallback(
    (event) => {
      event.preventDefault();

      onSubmit?.();
    },
    [onSubmit]
  );

  const handleBannerClose = useCallback(() => {
    setIsBannerClosed(true);
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setIsBannerClosed(false);
    }
  }, [isSubmitSuccessful]);

  return (
    <>
      <NotificationBanner
        message={successMessage ?? ""}
        isVisible={(!isBannerClosed && isSubmitSuccessful) ?? false}
        onClose={handleBannerClose}
      />
      <form onSubmit={wrappedSubmit}>
        {children}
        {error && (
          <Box my={2}>
            <Text
              size={1.7}
              color={theme.palette.formError}
              textAlign={"center"}
            >
              {error}
            </Text>
          </Box>
        )}
        <Box
          mt={5}
          sx={{
            display: "flex",
            justifyContent: justifyButtons,
          }}
        >
          {buttons?.map(
            (
              {
                type,
                text,
                component: Button,
                onClick,
                isLoading: btnIsLoading,
              },
              index
            ) => (
              <Box ml={index > 0 ? 3 : 0} key={text}>
                <Button
                  size={"large"}
                  type={type}
                  onClick={onClick}
                  isLoading={(type === "submit" && isLoading) || btnIsLoading}
                  isDisabled={isLoading}
                >
                  {text}
                </Button>
              </Box>
            )
          )}
        </Box>
      </form>
    </>
  );
};

export default Form;
