import { DndDeck } from "../models/dnd-deck";
import { DeckCategory } from "../../backend";
import { DndCardGroup } from "../enums/dnd-card-group";
import { ROUTES } from "./routes";
import { deepFreeze } from "../../../utils/deep-freeze";
import { createDndCard } from "../../../utils/create-dnd-card";

export const LEGACY_DECK: DndDeck<DeckCategory.LEGACY> = deepFreeze({
  id: "deck-leg",
  title: "Legacy",
  description: "What are your legacy priorities?",
  category: DeckCategory.LEGACY,
  href: ROUTES.dialogueDeckCategory.replace(":category", "legacy"),
  cards: [
    {
      id: "leg-0",
      index: 0,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.LEGACY,
        title: "Transfer Values, Wisdom, Heritage",
        priority: 0,
      },
    },
    {
      id: "leg-1",
      index: 1,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.LEGACY,
        title: "Create Family Memories",
        priority: 0,
      },
    },
    {
      id: "leg-2",
      index: 2,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.LEGACY,
        title: "Provide a Better Life for the Next Generation",
        priority: 0,
      },
    },
    {
      id: "leg-3",
      index: 3,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.LEGACY,
        title: "Support Important Charitable Causes",
        priority: 0,
      },
    },
  ],
  top4: [0, 1, 2, 3].map((num) => createDndCard(num, DndCardGroup.TOP4)),
});
