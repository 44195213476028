export enum ActionTask {
  GIVING_PLAN,
  TIME_WITH_LOVED,
  RETIREMENT_ASSETS,
  LIABILITY_REVIEW,
  BENEFICIARY_REVIEW,
  SABBATICAL,
  BALANCE_SHEET,
  LIFE_INSURANCE_STATUS,
  FRAGMENTED_INVESTMENTS,
  EDUCATION_STRATEGY,
  TAX_GUIDANCE,
  EMERGENCY_FUND,
  ANNUAL_BUDGET,
  FUND_LIFE,
  LONG_TERM_CARE,
  WILL_OR_TRUST,
  GUARANTEED_INCOME,
  ESTATE_PLAN,
  HEALTH_INSURANCE,
  DOCUMENT_STORAGE,
}
