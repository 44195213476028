import React, { FC, forwardRef } from "react";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import Text from "../../Text";
import DividingLine from "../../DividingLine";

interface Props {
  children?: string;
  ref?: any;
  isInverted?: boolean;
}

const HeadingSubtitle: FC<Props> = forwardRef(
  ({ children, isInverted }, ref): JSX.Element => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

    return (
      <div>
        <Box
          display={"flex"}
          justifyContent={isInverted ? "flex-start" : "flex-end"}
        >
          <Text
            ref={ref}
            py={1.5}
            pr={4}
            pl={7}
            font={theme.font.arial}
            size={1.8}
            color={"var(--darkGray)"}
            sx={{
              backgroundColor: "var(--gold)",
              textTransform: "uppercase",
              letterSpacing: "0.18rem",
            }}
          >
            {children}
          </Text>
        </Box>
        {isMdUp && <DividingLine />}
      </div>
    );
  }
);

export default HeadingSubtitle;
