import React, { FC, useCallback, useMemo, useState } from "react";
import Anchor from "src/components/Anchor";
import Input from "src/components/Input";
import FieldSet from "../../../components/Form/FieldSet";
import { Box } from "@material-ui/core";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { useQueryStrings } from "../../../hooks/use-query-strings";
import { AuthErrors, LoginForm } from "./types";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import { useHistory } from "react-router-dom";
import {
  CognitoChallenge,
  IS_TRUSTED_DEVICE,
  ROUTES,
} from "../../../domain/frontend";
import UserManagementLayout from "../../../layouts/UserManagementLayout";
import Form from "../../../components/Form";
import { loginFormSchema } from "./schema";
import { useDefaultForm } from "../../../hooks/use-default-form";
import InputPassword from "../../../components/Input/InputPassword";
import Checkbox from "../../../components/Checkbox";
import { Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { getErrorMessage } from "./utils";
import { useGlobalState } from "../../../state/global";
import Text from "../../../components/Text";

interface Props {}

const Login: FC<Props> = (props: Props): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useDefaultForm<LoginForm>(loginFormSchema);
  const history = useHistory();
  const { redirect } = useQueryStrings();
  const [, { setAuthenticatedUser }] = useGlobalState();
  const [formError, setFormError] = useState("");

  const isDeviceTrusted = useMemo(() => {
    return localStorage.getItem(IS_TRUSTED_DEVICE) === "true";
  }, []);

  const signIn = handleSubmit(
    async ({ username, password, canRememberDevice }) => {
      try {
        const user = await Auth.signIn(username, password);

        await routeToNextPage(user, canRememberDevice);
      } catch (error) {
        if (error.name === AuthErrors.NOT_CONFIRMED) {
          history.push({
            pathname: ROUTES.verifyCode,
            state: {
              username,
              canRememberDevice,
              isUnconfirmedUser: true,
            },
          });
        } else {
          setFormError(getErrorMessage(error));
        }
      }
    }
  );

  const routeToNextPage = useCallback(
    async (
      user: {
        challengeName: CognitoChallenge;
        challengeParam: Record<string, string>;
      },
      canRememberDevice: boolean
    ) => {
      switch (user.challengeName) {
        case CognitoChallenge.SMS:
          history.push({
            pathname: ROUTES.verifyCode,
            state: { user, canRememberDevice, isUnconfirmedUser: false },
          });
          break;
        case CognitoChallenge.NEW_PASSWORD:
          history.push({
            pathname: ROUTES.registerAdvisor,
            state: { user, canRememberDevice },
          });
          break;
        default:
          const { attributes } = await Auth.currentAuthenticatedUser();
          const session = await Auth.currentSession();
          const accessToken = session.getAccessToken().getJwtToken();

          setAuthenticatedUser(attributes, accessToken);
          history.push(redirect ?? ROUTES.dashboard);
      }
    },
    [history, redirect, setAuthenticatedUser]
  );

  return (
    <UserManagementLayout heading={"Log In"}>
      <Form
        onSubmit={signIn}
        isLoading={isSubmitting}
        error={formError}
        justifyButtons={"flex-end"}
        buttons={[{ type: "submit", text: "Log In", component: ButtonPrimary }]}
      >
        <FieldSet>
          <FieldWrapper name={"Username"} error={errors.username} required>
            <Input
              {...register("username")}
              autoComplete={"username"}
              placeholder={"Enter email or phone number"}
            />
          </FieldWrapper>
          <FieldWrapper name={"Password"} error={errors.password} required>
            <InputPassword
              {...register("password")}
              autoComplete={"current-password"}
              placeholder={"Enter password"}
            />
          </FieldWrapper>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              ml={0.5}
              sx={{
                width: "50%",
              }}
            >
              {!isDeviceTrusted && (
                <Controller
                  name={"canRememberDevice"}
                  control={control}
                  render={({ field }) => (
                    <Checkbox field={field}>Trust this device</Checkbox>
                  )}
                />
              )}
            </Box>
            <Box
              mt={1}
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Anchor href={`/#${ROUTES.forgottenPasswordCode}`}>
                Forgot password?
              </Anchor>
            </Box>
          </Box>
          {watch("canRememberDevice") && (
            <Box mt={1}>
              <Text>
                You won't be asked to enter a verification code when you log in
                on this device in the future.
              </Text>
            </Box>
          )}
        </FieldSet>
      </Form>
    </UserManagementLayout>
  );
};

export default Login;
