import { useLocation } from "react-router-dom";

export function useQueryStrings<T>(): T {
  const queryStrings: T = {} as T;

  Array.from(new URLSearchParams(useLocation().search).entries()).forEach(
    ([key, value]) => {
      (queryStrings as Record<string, unknown>)[key] = value;
    }
  );

  return queryStrings;
}
