import React, { FC, useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import Text from "../../../components/Text";
import TalkSigTextLogo from "../../../components/TalkSigTextLogo";
import { Box, Grid, MenuItem, useTheme } from "@material-ui/core";
import Input from "src/components/Input";
import FieldSet from "../../../components/Form/FieldSet";
import Select from "src/components/Select";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { DivClientRegisterPageContainer } from "./styles";
import { useDefaultForm } from "../../../hooks/use-default-form";
import { ClientRegistrationForm } from "./types";
import { clientRegistrationFormSchema } from "./schema";
import Form from "../../../components/Form";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import { Controller } from "react-hook-form";
import { OFFICES } from "../../../domain/frontend";
import AutoComplete from "../../../components/AutoComplete";
import {
  fetchAdvisorComboOptions,
  fetchLinkedClientComboOptions,
  mapErrorMessage,
  registerClient,
} from "./utils";
import { defaultForm } from "./constants";

interface Props {}

const RegisterClient: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const [formError, setFormError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useDefaultForm<ClientRegistrationForm>(
    clientRegistrationFormSchema,
    defaultForm
  );

  const submit = handleSubmit(async (form) => {
    setFormError("");
    setIsSubmitted(false);

    try {
      await registerClient(form);

      setIsSubmitted(true);
    } catch (error) {
      setFormError(mapErrorMessage(error.response?.status));
    }
  });

  useEffect(() => {
    if (isSubmitted) {
      reset(defaultForm);
    }
  }, [isSubmitted, reset]);

  return (
    <MainLayout>
      <DivClientRegisterPageContainer>
        <Grid container justifyContent={"center"}>
          <Grid item container justifyContent={"center"}>
            <Grid item>
              <Text font={theme.font.arialBlack} size={3.8}>
                Client Registration
              </Text>
              <Text font={theme.font.din} size={1.6}>
                Set up a new client profile
              </Text>
            </Grid>
            <Grid item>
              <Box ml={20}>
                <TalkSigTextLogo />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: "60rem",
              }}
            >
              <Box mt={5}>
                <Form
                  onSubmit={submit}
                  error={formError}
                  isLoading={isSubmitting}
                  isSubmitSuccessful={isSubmitted}
                  successMessage={"Client registered successfully."}
                  justifyButtons={"flex-end"}
                  buttons={[
                    {
                      type: "submit",
                      component: ButtonPrimary,
                      text: "Submit",
                    },
                  ]}
                >
                  <FieldSet rowSpan={6}>
                    <FieldWrapper name={"Name"} error={errors.name} required>
                      <Input {...register("name")} placeholder={"Name"} />
                    </FieldWrapper>
                    <FieldWrapper name={"Phone"} error={errors.phone}>
                      <Input {...register("phone")} placeholder={"Phone"} />
                    </FieldWrapper>
                    <FieldWrapper name={"Email"} error={errors.email} required>
                      <Input {...register("email")} placeholder={"Email"} />
                    </FieldWrapper>
                    <FieldWrapper
                      name={"City/State"}
                      error={errors.location}
                      required
                    >
                      <Input
                        {...register("location")}
                        placeholder={"City/State"}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      name={"Office"}
                      error={errors.office}
                      required
                    >
                      <Controller
                        control={control}
                        name={"office"}
                        render={({ field }) => (
                          <Select {...field}>
                            {Object.entries(OFFICES).map(([value, details]) => (
                              <MenuItem key={details.title} value={value}>
                                {details.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      name={"Advisor Name"}
                      error={errors.advisor?.title}
                      required
                    >
                      <Controller
                        control={control}
                        name={"advisor"}
                        defaultValue={defaultForm.advisor}
                        render={({ field }) => (
                          <AutoComplete
                            {...field}
                            placeholder={"Start typing first name"}
                            fetchOptions={fetchAdvisorComboOptions}
                          />
                        )}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      error={errors.linkedClient}
                      name={"Spouse Name"}
                    >
                      <Controller
                        control={control}
                        name={"linkedClient"}
                        render={({ field }) => (
                          <AutoComplete
                            {...field}
                            placeholder={"Start typing first name"}
                            fetchOptions={fetchLinkedClientComboOptions}
                          />
                        )}
                      />
                    </FieldWrapper>
                  </FieldSet>
                </Form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DivClientRegisterPageContainer>
    </MainLayout>
  );
};

export default RegisterClient;
