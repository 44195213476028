import { Action } from "react-sweet-state";
import { DialogueState } from "../types";
import { DeckCategory } from "../../../domain/backend";
import { DndCard } from "../../../domain/frontend";
import dcopy from "deep-copy";
import { DndDecks } from "../../../domain/frontend/models/dnd-decks";

const saveDeck =
  (
    deckCards: DndCard<DeckCategory>[],
    top4: DndCard<DeckCategory>[],
    category: string
  ): Action<DialogueState> =>
  ({ getState, setState }) => {
    const prevState = getState();
    const { decks } = prevState.dialogues[prevState.currentDialogueIndex];

    const deck = decks[category.toUpperCase() as keyof DndDecks];

    if (!deck) {
      throw new Error(`Can't find deck with category ${category}.`);
    }

    deck.cards = deckCards;
    deck.top4 = top4;
    deck.isComplete = true;

    setState(dcopy(prevState));
  };

export default saveDeck;
