import React, { FC } from "react";
import { Box, useTheme, Modal as MuiModal, Backdrop } from "@material-ui/core";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  children?: any;
}

const Modal: FC<Props> = ({ isOpen, onClose, children }): JSX.Element => {
  const theme = useTheme();

  return (
    <Backdrop style={{ zIndex: theme.zIndex.modal - 1 }} open={isOpen ?? false}>
      <MuiModal
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "30rem",
          width: "60rem",
          backgroundColor: theme.palette.black,
          boxShadow: 24,
          p: 4,
        }}
        open={isOpen ?? false}
        onClose={onClose}
        aria-labelledby="delete-user-confirm"
        aria-describedby="confirm that you want to delete the user"
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </MuiModal>
    </Backdrop>
  );
};

export default Modal;
