import React, { FC, useCallback, useEffect, useState } from "react";
import TalkSigWheel from "../../../components/TalkSigWheel";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Text from "../../../components/Text";
import ButtonSpecial from "../../../components/Button/ButtonSpecial";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useDialogueState } from "../../../state/dialogue";
import { useHistory } from "react-router-dom";
import { Client } from "../../../domain/backend";
import { ROUTES } from "../../../domain/frontend";
import { useLocation } from "react-router-dom";
import { DialogueStartLocationState } from "./types";
import { mapErrorMessage } from "./utils";
import Checkbox from "../../../components/Checkbox";
import { SvgLinkIcon } from "./styles";
import { getClient } from "../../../utils/get-client";
import MainLayout from "../../../layouts/MainLayout";

interface Props {}

const DialogueStart: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const [, { startDialogue }] = useDialogueState();
  const [client, setClient] = useState<Client>();
  const [linkedClient, setLinkedClient] = useState<Client>();
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldIncludeLinked, setShouldIncludeLinked] = useState(false);
  const { state: locationState } = useLocation<DialogueStartLocationState>();

  const handleStartDialogue = useCallback(() => {
    if (!client) {
      throw new Error("Can't start a dialogue without first loading a client.");
    }

    startDialogue(client, shouldIncludeLinked ? linkedClient : undefined);

    history.push(ROUTES.dialogueDecks);
  }, [client, startDialogue, shouldIncludeLinked, linkedClient, history]);

  const toggleShouldIncludeLinked = useCallback(() => {
    setShouldIncludeLinked((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const client = locationState?.client;

    if (!client) {
      history.push(ROUTES.dashboard);
      return;
    }

    setClient(client);

    if (client.linkedClientId) {
      setIsLoading(true);

      getClient(client.linkedClientId)
        .then((linkedClient) => setLinkedClient(linkedClient))
        .catch((error) => setFormError(mapErrorMessage(error.response?.status)))
        .finally(() => setIsLoading(false));
    }
  }, [history, locationState]);

  return (
    <MainLayout isBgTiled>
      <Grid item container justifyContent={"center"} alignItems={"center"}>
        {formError && (
          <Grid item xs={12}>
            <Text
              color={theme.palette.formError}
              size={2}
              textAlign={"center"}
              mb={5}
            >
              {formError}
            </Text>
          </Grid>
        )}
        <Grid item xs={12} md={"auto"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TalkSigWheel isStatic />
          </Box>
        </Grid>
        <Grid item xs={12} md={"auto"}>
          <Box
            mt={{
              xs: 5,
              md: 0,
            }}
            ml={7}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Text
                font={theme.font.arialBlack}
                size={isMdDown ? 4.9 : 9.4}
                letterSpacing={"-0.05em"}
                lineHeight={isMdDown ? "5.1rem" : "10.3rem"}
              >
                Clarify.
                <br />
                Simplify.
                <br />
                Amplify.
              </Text>
              <Text
                mt={4}
                mb={5}
                font={theme.font.arialBlack}
                size={isMdDown ? 1.8 : 2.1}
                color={theme.palette.gold}
              >
                We strive to help our clients move
                <br />
                Beyond Success, to Significance.
              </Text>
              {isLoading && (
                <Box mb={3}>
                  <Text>Checking for linked client...</Text>
                </Box>
              )}
              {!!linkedClient && (
                <Box mb={5}>
                  <Text size={1.4} ml={0.5}>
                    Linked client
                  </Text>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SvgLinkIcon />
                    <Text color={theme.palette.financial} size={2} ml={1}>
                      {linkedClient.name}
                    </Text>
                  </Box>
                  <Box ml={1}>
                    <Checkbox
                      value={shouldIncludeLinked}
                      onChange={toggleShouldIncludeLinked}
                    >
                      Include in dialogue
                    </Checkbox>
                  </Box>
                </Box>
              )}
              <ButtonSpecial
                size={"large"}
                icon={ArrowForwardIcon}
                onClick={handleStartDialogue}
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                Start
              </ButtonSpecial>
            </div>
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default DialogueStart;
