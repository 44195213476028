import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import {
  Box,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Text from "../../../../components/Text";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import DividingLine from "../../../../components/DividingLine";
import DatePicker from "src/components/DatePicker";
import ActionStatusIndicator from "../../../../components/ActionStatusIndicator";
import { GridActionItemContainer } from "../styles";
import {
  ActionStatus,
  ActionTask,
  TrackerAction,
} from "../../../../domain/backend";
import { ACTION_STATUSES, ACTION_TASKS } from "../../../../domain/frontend";
import { findActionInList, replaceActionInList } from "../utils";

interface Props {
  action: TrackerAction;
  setActions: Dispatch<SetStateAction<TrackerAction[] | undefined>>;
}

const TrackerActionGridItem: FC<Props> = ({
  action,
  setActions,
}): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const changeTask = useCallback(
    (event: any) => {
      setActions((prevState) => {
        const actions = prevState!;

        const { action: existingAction } = findActionInList(action.id, actions);
        existingAction.type = Number(event.target.value) as ActionTask;

        return replaceActionInList(action, actions);
      });
    },
    [action, setActions]
  );

  const changeStatus = useCallback(
    (event: any) => {
      setActions((prevState) => {
        const actions = prevState!;

        const { action: existingAction } = findActionInList(action.id, actions);
        existingAction.status = Number(event.target.value) as ActionStatus;

        return replaceActionInList(action, actions);
      });
    },
    [action, setActions]
  );

  const changeDescription = useCallback(
    (event: any) => {
      setActions((prevState) => {
        const actions = prevState!;

        const { action: existingAction } = findActionInList(action.id, actions);
        existingAction.description = event.target.value;

        return replaceActionInList(action, actions);
      });
    },
    [action, setActions]
  );

  const changeCheckInDate = useCallback(
    (date: unknown) => {
      setActions((prevState) => {
        const actions = prevState!;

        const { action: existingAction } = findActionInList(action.id, actions);
        existingAction.checkInDate = date as Date;

        return replaceActionInList(action, actions);
      });
    },
    [action, setActions]
  );

  return (
    <GridActionItemContainer item container>
      <Grid item container xs={4} spacing={2}>
        <Grid item xs={12}>
          <Text size={1.8} color={theme.palette.gold} mb={1}>
            Task
          </Text>
          <Select value={action.type} onChange={changeTask}>
            {Object.entries(ACTION_TASKS).map(([value, description]) => (
              <MenuItem key={description} value={value}>
                {description}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <Text size={1.8} color={theme.palette.gold} mb={1}>
            Status
          </Text>
          <Select value={action.status} onChange={changeStatus}>
            {Object.entries(ACTION_STATUSES).map(([value, { color }]) => (
              <MenuItem key={color} value={value}>
                <ActionStatusIndicator color={color} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={8}>
          <Text size={1.8} color={theme.palette.gold} mb={1}>
            Check in Date
          </Text>
          <DatePicker value={action.checkInDate} onChange={changeCheckInDate} />
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <DividingLine vertical />
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Text size={1.8} color={theme.palette.gold} mb={1}>
            Action
          </Text>
        </Box>
        <Box>
          <Input
            multiline
            value={action.description}
            placeholder={"Describe task actions"}
            minRows={isMdDown ? 12 : 7}
            onChange={changeDescription}
          />
        </Box>
      </Grid>
      <Grid item xs={12} mt={isMdDown ? 3 : 0}>
        <Box pt={2}>
          <DividingLine />
        </Box>
      </Grid>
    </GridActionItemContainer>
  );
};

export default TrackerActionGridItem;
