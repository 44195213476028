import { createHook } from "react-sweet-state";
import { DialogueState } from "../types";
import { DeckCategory } from "../../../domain/backend";
import { DialogueActions, DialogueStore } from "..";
import { DndDeck } from "../../../domain/frontend";

const useDeckForCategory = createHook<
  DialogueState,
  DialogueActions,
  DndDeck<DeckCategory>,
  string
>(DialogueStore, {
  selector: (
    { dialogues, currentDialogueIndex },
    category
  ): DndDeck<DeckCategory> => {
    const currentDialogue = dialogues[currentDialogueIndex];

    const deck = Object.values(currentDialogue.decks).find(
      (deck) => deck.category === category.toUpperCase()
    );

    if (!deck) {
      throw new Error(`Can't find deck with category ${category}.`);
    }

    return deck;
  },
});

export default useDeckForCategory;
