export function getSplitName(name: string): {
  firstName: string;
  lastName: string;
} {
  const [firstName, lastName] = name.split(" ");

  return {
    firstName,
    lastName,
  };
}

export function getFirstName(name: string): string {
  const [firstName, lastName] = name.split(" ");

  return lastName ? firstName : name;
}

export function getLastName(name: string): string {
  const [, lastName] = name.split(" ");

  return lastName ? lastName : name;
}
