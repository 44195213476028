import styled from "styled-components/macro";

export const SummaryContainer = styled.div`
  .dialogue {
    padding-top: 2.4rem;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-top: 2.4rem;
    }

    .card {
      &:last-of-type {
        .divider {
          width: 0;
        }
      }
    }
  }
`;

export const PdfCardText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  text-align: center;
  color: white;
  font-size: 0.7rem;
  padding: 0.75rem;
  font-family: ${(props) => props.theme.font.arialBlack};
  width: 100%;
  height: 100%;
`;

export const PdfCard = styled.img`
  width: 100%;
  height: 100%;
`;

export const ImgLogo = styled.img`
  width: 22rem;
  height: auto;
`;

export const Divider = styled.div<{ width: string }>`
  background-color: #727272;
  height: 1px;
  width: ${(props) => props.width};
`;

export const StatusColorCircle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
`;
