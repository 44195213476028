import React, { FC } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "./pages/public/home";
import AdvisorDashboard from "./pages/secure/dashboard";
import AdminDashboard from "./pages/secure/admin/dashboard";
import RegisterClient from "./pages/secure/register-client";
import ConfirmSignup from "./pages/public/confirm-signup";
import Login from "./pages/public/login";
import DialogueStart from "./pages/secure/dialogue-start";
import DialogueEnd from "./pages/secure/dialogue-end";
import DialogueDecks from "./pages/secure/dialogue-decks";
import DialogueDeckCategory from "./pages/secure/dialogue-deck-category";
import DialoguePriorities from "./pages/secure/dialogue-priorities";
import DialogueSharedPriorities from "./pages/secure/dialogue-shared-priorities";
import DialogueSummary from "./pages/secure/dialogue-summary";
import DialogueTracker from "./pages/secure/dialogue-tracker";
import DialogueTopFour from "./pages/secure/dialogue-top-four";
import ChangePassword from "./pages/secure/change-password";
import VerifyCode from "./pages/secure/verify-code";
import Logout from "./pages/secure/logout";
import ForgottenPasswordCode from "./pages/public/forgotten-password-code";
import ForgottenPasswordSubmit from "./pages/public/forgotten-password-submit";
import { ROUTES } from "./domain/frontend";
import UserDetails from "./pages/secure/admin/user-details";
import CreateUser from "./pages/secure/admin/create-user";
import InternalError from "./pages/public/internal-error";
import ScrollToTop from "./components/ScrollToTop";
import SecureRoute from "./components/SecureRoute";
import Templates from "./pages/templates";
import DialogueRoute from "./components/DialogueRoute";

const Router: FC = (): JSX.Element => {
  return (
    <HashRouter hashType={"slash"}>
      <ScrollToTop />
      <Switch>
        <Route exact path={ROUTES.home}>
          <Home />
        </Route>
        <Route exact path={ROUTES.error}>
          <InternalError />
        </Route>
        <Route exact path={ROUTES.login}>
          <Login />
        </Route>
        <Route exact path={ROUTES.forgottenPasswordCode}>
          <ForgottenPasswordCode />
        </Route>
        <Route exact path={ROUTES.forgottenPasswordSubmit}>
          <ForgottenPasswordSubmit />
        </Route>
        <Route exact path={ROUTES.registerAdvisor}>
          <ConfirmSignup />
        </Route>
        <Route exact path={ROUTES.verifyCode}>
          <VerifyCode />
        </Route>
        <Route exact path={ROUTES.logout}>
          <Logout />
        </Route>
        <SecureRoute exact path={ROUTES.dashboard}>
          <AdvisorDashboard />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.registerClient}>
          <RegisterClient />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.changePassword}>
          <ChangePassword />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.dialogueStart}>
          <DialogueStart />
        </SecureRoute>
        <DialogueRoute exact path={ROUTES.dialogueDecks}>
          <DialogueDecks />
        </DialogueRoute>
        <DialogueRoute exact path={ROUTES.dialogueDeckCategory}>
          <DialogueDeckCategory />
        </DialogueRoute>
        <DialogueRoute exact path={ROUTES.dialogueTop4}>
          <DialogueTopFour />
        </DialogueRoute>
        <DialogueRoute exact path={ROUTES.dialoguePriorities}>
          <DialoguePriorities />
        </DialogueRoute>
        <DialogueRoute exact path={ROUTES.dialogueSharedPriorities}>
          <DialogueSharedPriorities />
        </DialogueRoute>
        <SecureRoute exact path={ROUTES.dialogueThankYou}>
          <DialogueEnd />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.dialogueSummary}>
          <DialogueSummary />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.dialogueTracker}>
          <DialogueTracker />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.adminDashboard} requiresAdmin>
          <AdminDashboard />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.adminUserDetails} requiresAdmin>
          <UserDetails />
        </SecureRoute>
        <SecureRoute exact path={ROUTES.adminCreateUser} requiresAdmin>
          <CreateUser />
        </SecureRoute>
        <Route exact path={"/admin/template/:template"}>
          <Templates />
        </Route>
      </Switch>
    </HashRouter>
  );
};

export default Router;
