import {
  Box,
  Grid,
  Portal,
  SvgIconProps,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import {
  DivNotificationBannerClose,
  DivNotificationBannerContainer,
  DivNotificationBannerSection,
} from "./styles";
import { NotificationBannerProps } from "./types";
import { FC, useMemo } from "react";
import Text from "../Text";
import {
  Cancel,
  CheckCircleOutline,
  ErrorOutline,
  Warning,
} from "@material-ui/icons";

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  isVisible,
  message,
  onClose,
  variant,
}: NotificationBannerProps): JSX.Element => {
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("sm"));

  const variantColor = useMemo((): string => {
    switch (variant) {
      case "error":
        return theme.palette.errorBanner;
      case "warning":
        return theme.palette.warningBanner;
      default:
        return theme.palette.successBanner;
    }
  }, [theme.palette, variant]);

  const Icon = useMemo((): FC<SvgIconProps> => {
    switch (variant) {
      case "success":
        return CheckCircleOutline;
      case "warning":
        return Warning;
      default:
        return ErrorOutline;
    }
  }, [variant]);

  return (
    <>
      {isVisible && (
        <Portal>
          <DivNotificationBannerContainer bgColor={variantColor}>
            <Grid container>
              <Grid item xs={1}>
                <DivNotificationBannerSection
                  justify={"center"}
                  isMobile={isMobile}
                >
                  <Icon
                    sx={{
                      fontSize: "3rem",
                    }}
                  />
                </DivNotificationBannerSection>
              </Grid>
              <Grid item xs={9}>
                <DivNotificationBannerSection
                  justify={"flex-start"}
                  isMobile={isMobile}
                >
                  <Text font={theme.font.arial} size={isMobile ? 1.4 : 1.6}>
                    {message}
                  </Text>
                </DivNotificationBannerSection>
              </Grid>
              <Grid item xs={2}>
                <DivNotificationBannerClose
                  justify={"center"}
                  onClick={onClose}
                  isMobile={isMobile}
                >
                  <Box>
                    <Cancel
                      sx={{
                        fontSize: "2rem",
                      }}
                    />
                  </Box>
                  {!isMobile && (
                    <Text size={1.4} ml={1.5}>
                      Close
                    </Text>
                  )}
                </DivNotificationBannerClose>
              </Grid>
            </Grid>
          </DivNotificationBannerContainer>
        </Portal>
      )}
    </>
  );
};

export default NotificationBanner;
