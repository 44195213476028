import React, { FC, useCallback } from "react";
import {
  AnchorNavbar,
  DivDropdownContainer,
  DivProfileContainer,
  DivProfileIconsContainer,
  LiDropdownItem,
  SvgChevDownIcon,
  SvgProfileIcon,
  UlDropdownList,
} from "../styles";
import { useHash } from "react-use";
import { Box, ClickAwayListener } from "@material-ui/core";
import { NavbarLinkModel } from "../types";
import { isActiveLink } from "../functions";

interface Props {
  links: NavbarLinkModel[];
}

const ProfileSection: FC<Props> = (props: Props): JSX.Element => {
  const [url] = useHash();
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  const toggleDropdownVisible = useCallback(() => {
    setIsDropdownVisible((prevState) => !prevState);
  }, []);

  const setDropdownHidden = useCallback(() => {
    setIsDropdownVisible(false);
  }, []);

  const renderLinkItem = (link: NavbarLinkModel): JSX.Element => (
    <AnchorNavbar key={link.label} onClick={setDropdownHidden} href={link.href}>
      <LiDropdownItem isActive={isActiveLink(url, link)}>
        <Box display={"flex"} alignContent={"center"}>
          {!!link.icon && <link.icon fontSize={"large"} />}
          <Box ml={1}>{link.label}</Box>
        </Box>
      </LiDropdownItem>
    </AnchorNavbar>
  );

  const renderSeparator = (key: number): JSX.Element => (
    <LiDropdownItem key={key} isSeparator>
      <hr />
    </LiDropdownItem>
  );

  return (
    <ClickAwayListener onClickAway={setDropdownHidden}>
      <DivProfileContainer>
        <DivProfileIconsContainer onClick={toggleDropdownVisible}>
          <SvgProfileIcon />
          <SvgChevDownIcon />
        </DivProfileIconsContainer>
        <DivDropdownContainer isVisible={isDropdownVisible}>
          <UlDropdownList>
            {props.links.map((link, index) =>
              link.isSeparator ? renderSeparator(index) : renderLinkItem(link)
            )}
          </UlDropdownList>
        </DivDropdownContainer>
      </DivProfileContainer>
    </ClickAwayListener>
  );
};

export default ProfileSection;
