import React, { FC } from "react";
import { useTheme } from "@material-ui/core";

import ButtonBase from "../index";
import { ButtonProps } from "../types";

const ButtonPrimary: FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        color: theme.palette.darkGray,
        backgroundColor: theme.palette.gold,
        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.white,
        },
        "&:active,&:focus": {
          color: theme.palette.darkGray,
          backgroundColor: theme.palette.gold,
        },
      }}
      {...props}
    >
      {props.children}
    </ButtonBase>
  );
};

export default ButtonPrimary;
