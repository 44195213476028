import { motion, Variants } from "framer-motion";
import React, { useCallback } from "react";
import Waypoint from "../Waypoint";

interface Props {
  children: any;
  distance?: number;
  down?: boolean;
  noReplay?: boolean;
  delay?: number;
}

const FadeIn: React.FC<Props> = (props: Props): JSX.Element => {
  const { noReplay, distance, down, children, delay } = props;
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        setVisible(true);
      } else if (!isVisible && !noReplay) {
        setVisible(false);
      }
    },
    [noReplay]
  );

  const fadeIn: Variants = {
    hidden: {
      opacity: 0,
      translateY: `${down ? "-" : ""}${distance || 0}rem`,
    },
    visible: {
      opacity: 1,
      translateY: "0rem",
      transition: {
        type: "tween",
        delay: delay ?? 0.2,
        duration: 0.5,
      },
    },
  };

  return (
    <Waypoint onChange={toggleVisible}>
      <motion.div
        variants={fadeIn}
        initial={"hidden"}
        animate={visible ? "visible" : "hidden"}
      >
        {children}
      </motion.div>
    </Waypoint>
  );
};

export default FadeIn;
