import { DndDeck } from "../domain/frontend";
import { DeckCategory } from "../domain/backend";
import dcopy from "deep-copy";

export function createScopedDeck(
  scope: string,
  deck: DndDeck<DeckCategory>
): DndDeck<DeckCategory> {
  return dcopy({
    ...deck,
    cards: deck.cards.map((card) => ({ ...card, id: `${scope}-${card.id}` })),
  });
}
