import React, { FC, forwardRef } from "react";
import { Select as MuiSelect, SelectProps, useTheme } from "@material-ui/core";
import { SelectIcon } from "./styles";
import Input from "../Input";

interface Props extends SelectProps {
  children?: any;
  width?: string;
  field?: any;
}

const Select: FC<Props> = forwardRef((props: Props, ref): JSX.Element => {
  const theme = useTheme();

  return (
    <MuiSelect
      sx={{
        backgroundColor: theme.palette.white,
        fontFamily: theme.font.arial,
        fontSize: "1.4rem",
        color: theme.palette.black,
        borderRadius: "4px",
        "& .MuiInputBase-input": {
          padding: "1.5rem 1.8rem",
        },
      }}
      input={<Input {...props.field} />}
      placeholder={"Office Location"}
      IconComponent={SelectIcon}
      {...{ ...props, field: undefined, ref: undefined }}
    >
      {props.children}
    </MuiSelect>
  );
});

export default Select;
