import React, { FC } from "react";
import Text from "../../Text";
import DividingLine from "../../DividingLine";
import { DivUnderlineEmphasis } from "../styles";
import { Box } from "@material-ui/core";

interface Props {
  width?: number;
  fontSize?: number;
  children?: string;
  isInverted?: boolean;
}

const HeadingTitle: FC<Props> = ({
  children,
  width,
  fontSize,
  isInverted,
}): JSX.Element => {
  return (
    <div>
      <Text
        mb={1.5}
        font={"Arial Black"}
        size={fontSize ?? 3.8}
        sx={{
          textAlign: isInverted ? "right" : "left",
        }}
      >
        {children}
      </Text>
      <Box
        sx={{
          display: "flex",
          justifyContent: isInverted ? "flex-end" : "flex-start",
        }}
      >
        <DivUnderlineEmphasis width={width ?? 29} />
      </Box>
      <DividingLine />
    </div>
  );
};

export default HeadingTitle;
