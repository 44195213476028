import Joi from "joi";

export const verifyCodeFormSchema = Joi.object({
  code: Joi.string().min(6).max(6).required().label("Code").messages({
    "any.required": "Please enter Code",
    "string.empty": "Please enter Code",
    "string.min": "Please enter a valid 6-digit code",
    "string.max": "Please enter a valid 6-digit code",
  }),
}).label("VerifyCodeFormSchema");
