import { PdfType, TrackerAction } from "../../../domain/backend";
import httpClient from "../../../utils/http-client";
import config from "../../../config";
import {
  Dialogue,
  SharedDialogue,
} from "../../../domain/backend/models/dialogue";

export async function setDialogueActions(
  id: string,
  actions: TrackerAction[]
): Promise<void> {
  await httpClient.fetch(`${config.talksig.apiUrl}/dialogues/${id}/actions`, {
    method: "PUT",
    json: actions,
  });
}

export async function generateSignificanceTracker(
  dialogue: Dialogue | SharedDialogue
): Promise<Blob> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/pdf/generate`,
    {
      method: "POST",
      json: {
        type: PdfType.SIGNIFICANCE_TRACKER,
        dialogue,
      },
    }
  );

  return response.blob();
}

export function findActionInList(
  id: string,
  actions: TrackerAction[]
): { index: number; action: TrackerAction } {
  const action = actions.find((act) => act.id === id);

  if (!action) {
    throw new Error(`Can't find action ${id} in action list for dialogue.`);
  }

  return {
    action,
    index: actions.indexOf(action),
  };
}

export function replaceActionInList(
  action: TrackerAction,
  actions: TrackerAction[]
): TrackerAction[] {
  const { index } = findActionInList(action.id, actions);

  actions[index] = action;

  return Array.from(actions);
}
