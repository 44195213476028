import React, { FC } from "react";
import { ImgTalkSigWheel, ImgTalkSigText, Container } from "./styles";
import { Variants } from "framer-motion";
import config from "../../config";

interface Props {
  height?: string;
  isStatic?: boolean;
}

const TalkSigWheel: FC<Props> = ({ height, isStatic }: Props): JSX.Element => {
  const spin: Variants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  return (
    <Container
      variants={spin}
      initial={isStatic ? "visible" : "hidden"}
      animate={"visible"}
      transition={{ duration: 1 }}
      height={height ?? "55vh"}
    >
      <ImgTalkSigWheel
        animate={isStatic ? undefined : { rotate: 360 }}
        transition={{ duration: 2.5, delay: 1 }}
        src={`${config.env.publicUrl}/talksig-wheel.png`}
      />
      <ImgTalkSigText
        src={`${config.env.publicUrl}/talksig-text.png`}
        alt={"Talking Significance"}
      />
    </Container>
  );
};

export default TalkSigWheel;
