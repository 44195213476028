import { DndDeck } from "../models/dnd-deck";
import { DeckCategory } from "../../backend";
import { DndCardGroup } from "../enums/dnd-card-group";
import { ROUTES } from "./routes";
import { deepFreeze } from "../../../utils/deep-freeze";
import { createDndCard } from "../../../utils/create-dnd-card";

export const EXPERIENCE_DECK: DndDeck<DeckCategory.EXPERIENCE> = deepFreeze({
  id: "deck-exp",
  title: "Experience",
  description: "What are your experience priorities?",
  category: DeckCategory.EXPERIENCE,
  href: ROUTES.dialogueDeckCategory.replace(":category", "experience"),
  cards: [
    {
      id: "exp-0",
      index: 0,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.EXPERIENCE,
        title: "Learn and Grow as a Person",
        priority: 0,
      },
    },
    {
      id: "exp-1",
      index: 1,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.EXPERIENCE,
        title: "Work/Life Balance",
        priority: 0,
      },
    },
    {
      id: "exp-2",
      index: 2,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.EXPERIENCE,
        title: "Improve Health (Physical, Mental, Spiritual)",
        priority: 0,
      },
    },
    {
      id: "exp-3",
      index: 3,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.EXPERIENCE,
        title: "Expand Passions and Hobbies",
        priority: 0,
      },
    },
  ],
  top4: [0, 1, 2, 3].map((num) => createDndCard(num, DndCardGroup.TOP4)),
});
