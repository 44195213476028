import React, { FC } from "react";
import { useTheme } from "@material-ui/core";

const TalkSigLogo: FC = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width={"5rem"}
      xmlns="http://www.w3.org/2000/svg"
      fill={theme.palette.gold}
      viewBox="0 0 250 250"
    >
      <path d="M203.29 220.11v-8.88h-3.32v-1.19h7.98v1.19h-3.33v8.88h-1.33zM209.28 220.11v-10.07h2.01l2.38 7.13c.22.66.38 1.16.48 1.49.12-.37.29-.9.54-1.61l2.41-7.01h1.79v10.07h-1.28v-8.43l-2.93 8.43h-1.2l-2.91-8.57v8.57h-1.29z" />
      <g>
        <path d="M157.44 30.54c2.39.26 5.49-.63 9.44-2.73 2.17-1.11 3.32-2.1 3.85-3.32.41-.98.35-2.04-.17-2.93-.88-1.49-2.58-1.86-3.97-2.01-19.15-2.18-42.36-1.24-69 2.8-1.53-.62-3.58-.7-6.43-.23-2.89.48-5.12 1.27-6.8 2.41-23.55 4.27-42.78 10.5-57.15 18.51-1.28.73-4.29 2.44-3.8 5.24.37 1.84 1.94 2.87 4.69 3.07 1.46.12 3.13.04 4.99-.24 1.95-.33 3.43-.79 4.5-1.39 10.46-5.83 24.95-10.6 43.08-14.18-3.31 21.98-6.26 45.97-8.79 71.32L70 125.22c-.73 7.15-1.26 13.38-1.57 18.51-.07 1.05-.16 2.46-.26 4.21l-.31 4.39c-.25 3.51-.37 6.52-.37 8.92-.07 7.06.75 13.12 2.45 18.02.49 1.4 1.78 3.08 5.1 3.08.89 0 1.91-.12 3.02-.36 1.11-.23 2.34-.57 3.64-1.02.45-.15.93-.32 1.54-.56l.81-.35c3.18-1.36 6.07-3.22 8.59-5.51 1.69-1.49 1.44-3.04 1.26-3.64-.43-1.37-1.61-2.18-3.5-2.41-.98-.13-2.1-.15-3.42-.04-.47-3-.66-6.51-.58-10.45.03-1.48.12-3.72.26-6.72l.26-6.9c.1-5.2.5-11.29 1.18-18.09l4.12-37.74c.83-7.3 1.58-13.78 2.24-19.44 1.63-13.55 3.3-25.92 4.96-36.79 21.3-3.15 40.82-3.75 58.02-1.79z" />
        <path d="M201.49 170.39c-2.46-7.36-6.56-15.09-12.73-22.8-4.01-4.96-9.95-11.2-17.66-18.56-1.58-1.51-4.15-3.74-7.63-6.62l-4.07-3.45c-1.47-1.24-2.79-2.41-3.93-3.47-5.97-5.51-9.78-10.62-11.34-15.19-2.86-8.28-1.92-16.33 2.87-24.61 4.22-7.33 10.54-13.34 18.8-17.88 3.45-1.9 6.83-2.25 10.31-1.07 3.47 1.2 6.01 3.52 7.76 7.1 1.99 4.07 1.14 8.33-2.62 13.04-1.54 1.96-3.56 3.95-5.97 5.92-1.9 1.5-4.06 3.03-6.39 4.53-1.21.76-2.6 1.86-2.64 3.62-.02 1.03.49 2.03 1.29 2.57 1.01.77 2.47 1.07 4.87.97 1.68-.04 3.43-.23 5.21-.57 2.08-.43 3.46-.89 4.33-1.46 8.16-5.16 14.11-10.67 17.68-16.38 5.25-8.39 4.81-16.37-1.31-23.73-4.58-5.48-11.39-8.09-20.24-7.79-6.88.26-13.76 2.05-20.45 5.34-6.11 3.06-11.8 7.05-16.91 11.87-5.43 5.1-9.62 10.64-12.46 16.47-3.2 6.54-4.5 13.08-3.86 19.42.68 7.09 3.75 13.72 9.14 19.7 2.31 2.52 5.14 5.39 8.43 8.54l8.94 8.36c7.91 7.29 13.74 13.05 17.35 17.13 5.81 6.52 10.12 12.88 12.81 18.93 3.21 7.18 4.59 14.74 4.1 22.48-.54 8.83-4.67 16.75-12.26 23.53-4.86 4.34-9.98 5.84-15.66 4.6-5.06-1.1-10.17-4.27-15.19-9.43-2-2.06-3.67-5.1-4.97-9.01-1.36-4.01-2.01-8.09-1.95-12.13.09-4.25.99-7.74 2.66-10.37 1.8-2.84 4.36-4.46 7.81-4.94 3.79-.51 6.68-1.99 8.58-4.4.83-1.08 1.14-2.19.92-3.28-.2-.97-.84-1.82-1.75-2.33-1.31-.75-3.17-.9-6.07-.49-8.65 1.19-15.78 4.54-21.19 9.94-5 5.04-7.94 11.22-8.74 18.38-.79 7.03.62 13.85 4.18 20.26 3.76 6.81 9.49 12.2 17.05 16.01 8.2 4.11 17.16 5.04 26.47 2.71 7.74-1.96 15.61-6.1 23.39-12.29 7.19-5.75 11.94-13.36 14.13-22.64.11-.42 3.62-12.45-1.09-26.53z" />
      </g>
    </svg>
  );
};

export default TalkSigLogo;
