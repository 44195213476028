import { Action } from "react-sweet-state";
import { DialogueState } from "../types";
import { Client, DeckCategory } from "../../../domain/backend";
import {
  DndCardGroup,
  EXPERIENCE_DECK,
  FINANCIAL_DECK,
  LEGACY_DECK,
  RELATIONSHIP_DECK,
} from "../../../domain/frontend";
import { createDndCard } from "../../../utils/create-dnd-card";
import { createScopedDeck } from "../../../utils/create-scoped-deck";

const startDialogue =
  (client: Client, linkedClient?: Client): Action<DialogueState> =>
  ({ setState }) => {
    const clients: Client[] = [client];

    if (linkedClient) {
      clients.push(linkedClient);
    }

    setState({
      currentDialogueIndex: 0,
      dialogues: clients.map((client) => ({
        client,
        decks: {
          [DeckCategory.FINANCIAL]: createScopedDeck(client.id, FINANCIAL_DECK),
          [DeckCategory.LEGACY]: createScopedDeck(client.id, LEGACY_DECK),
          [DeckCategory.RELATIONSHIP]: createScopedDeck(
            client.id,
            RELATIONSHIP_DECK
          ),
          [DeckCategory.EXPERIENCE]: createScopedDeck(
            client.id,
            EXPERIENCE_DECK
          ),
        },
        overallTop4: [0, 1, 2, 3].map((num) =>
          createDndCard(num, DndCardGroup.OVERALL_TOP4)
        ),
      })),
      topFourSharedCards: clients?.[1]
        ? [0, 1, 2, 3].map((num) =>
            createDndCard(num, DndCardGroup.SHARED_TOP4)
          )
        : undefined,
    });
  };

export default startDialogue;
