import React, { FC } from "react";
import MainLayout from "../MainLayout";
import { Box, Grid } from "@material-ui/core";
import TalkSigTextLogo from "../../components/TalkSigTextLogo";
import Heading from "../../components/Heading";

interface Props {
  title?: string;
  subTitle?: string;
  description?: JSX.Element;
  children?: any;
}

const DialogueLayout: FC<Props> = ({
  title,
  subTitle,
  description,
  children,
}: Props): JSX.Element => {
  return (
    <MainLayout isBgTiled>
      <Grid item>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <TalkSigTextLogo />
        </Box>
      </Grid>
      <Grid item>
        <Heading title={title} subTitle={subTitle} />
      </Grid>
      <Grid item>
        <Box my={4}>{description}</Box>
      </Grid>
      {children}
    </MainLayout>
  );
};

export default DialogueLayout;
