import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import TalkSigTextLogo from "../../../components/TalkSigTextLogo";
import HeadingTitle from "src/components/Heading/HeadingTitle";
import Text from "../../../components/Text";
import CardDisplay from "../../../components/CardDisplay";
import Input from "../../../components/Input";
import DividingLine from "src/components/DividingLine";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { defaultErrorMessage, ROUTES } from "../../../domain/frontend";
import { Card, DeckCategory } from "../../../domain/backend";
import { useHistory, useParams } from "react-router-dom";
import {
  generateSummarySession,
  setCardNotes,
  setDialogueNotes,
} from "./utils";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { getDialogue } from "../../../utils/get-dialogue";
import MainLayout from "../../../layouts/MainLayout";
import Clicker from "../../../components/Clicker";
import {
  Dialogue,
  SharedDialogue,
} from "../../../domain/backend/models/dialogue";
import { isSharedDialogue } from "../../../utils/is-dialogue";
import NotificationBanner from "../../../components/NotificationBanner";
import { handleHttpError } from "../../../utils/handle-http-error";
import Heading from "../../../components/Heading";
import dcopy from "deep-copy";
import ButtonTertiary from "../../../components/Button/ButtonTertiary";

interface Props {}

const DialogueSummary: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { id: dialogueId } = useParams<{ id: string }>();
  const [dialogue, setDialogue] = useState<Dialogue | SharedDialogue>();
  const [currentDialogue, setCurrentDialogue] = useState("one");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const overallTop4 = useMemo(() => {
    if (!isSharedDialogue(dialogue)) {
      return dialogue?.overallTop4 ?? [];
    }

    if (currentDialogue === "one") {
      return dialogue.dialogueOne.overallTop4;
    } else if (currentDialogue === "two") {
      return dialogue.dialogueTwo.overallTop4;
    } else {
      return dialogue.top4SharedCards;
    }
  }, [currentDialogue, dialogue]);

  const currentClientName = useMemo(() => {
    if (!isSharedDialogue(dialogue)) {
      return dialogue?.client.name;
    }

    if (currentDialogue === "one") {
      return dialogue.dialogueOne.client.name;
    } else {
      return dialogue.dialogueTwo.client.name;
    }
  }, [currentDialogue, dialogue]);

  const date = useMemo((): string => {
    return dialogue?.createdDate
      ? new Date(dialogue.createdDate).toLocaleDateString()
      : "Unavailable";
  }, [dialogue]);

  const createSetCardNotes = useCallback(
    (card: Card<DeckCategory>) => (event: any) =>
      setDialogue((prevState) => {
        let deck: Card<DeckCategory>[];
        const note = event.target.value;

        if (!prevState) {
          return prevState;
        }

        if (isSharedDialogue(prevState)) {
          if (currentDialogue === "one") {
            deck = prevState.dialogueOne.overallTop4;
          } else if (currentDialogue === "two") {
            deck = prevState.dialogueTwo.overallTop4;
          } else {
            deck = prevState.top4SharedCards;
          }
        } else {
          deck = prevState.overallTop4;
        }

        setCardNotes(card, deck, note);

        return dcopy(prevState);
      }),
    [currentDialogue]
  );

  const nextSummary = useCallback(() => {
    if (currentDialogue === "one") {
      setCurrentDialogue("two");
    } else {
      setCurrentDialogue("shared");
    }

    window.scrollTo(0, 0);
  }, [currentDialogue]);

  const save = useCallback(
    async (shouldRedirect = true) => {
      try {
        setIsSubmitting(true);

        await setDialogueNotes(dialogue!);

        if (shouldRedirect) {
          history.push(ROUTES.dashboard);
        }
      } catch (error) {
        setFormError(defaultErrorMessage);
      } finally {
        setIsSubmitting(false);
      }
    },
    [dialogue, history]
  );

  const print = useCallback(async () => {
    if (!dialogue) {
      return;
    }

    try {
      setIsGeneratingPdf(true);

      const [, url] = await Promise.all([
        save(false),
        generateSummarySession(dialogue),
      ]);

      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = URL.createObjectURL(url);
      }
    } catch (error) {
      setFormError(defaultErrorMessage);
    } finally {
      setIsGeneratingPdf(false);
    }
  }, [dialogue, save]);

  useEffect(() => {
    setIsLoading(true);

    getDialogue(dialogueId)
      .then((dialogue) => {
        setDialogue(dialogue);
      })
      .catch((error) => handleHttpError(error, history))
      .finally(() => setIsLoading(false));
  }, [dialogueId, history]);

  return (
    <>
      <NotificationBanner
        isVisible={!!formError}
        message={formError}
        variant={"error"}
        onClose={() => setFormError("")}
      />
      <FullPageSpinner isOpen={isLoading} />
      <MainLayout isBgTiled>
        <Grid
          container
          direction={"column"}
          px={5}
          sx={{
            backgroundColor: theme.palette.black,
            textAlign: "center",
          }}
        >
          <Grid item>
            <Box
              my={5}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TalkSigTextLogo />
            </Box>
          </Grid>
          <Grid item pr={isMdDown ? 4 : 0}>
            {currentDialogue === "shared" || isMdDown ? (
              <HeadingTitle fontSize={isMdDown ? 2.5 : undefined}>
                Session Summary
              </HeadingTitle>
            ) : (
              <Heading title={"Session Summary"} subTitle={currentClientName} />
            )}
          </Grid>
          {dialogue && (
            <Grid item my={3} pr={isMdDown ? 4 : 0}>
              <Box sx={{ display: "flex" }}>
                <Text
                  font={theme.font.arialBlack}
                  size={1.7}
                  color={theme.palette.gold}
                  textAlign={"left"}
                  width={"50%"}
                >
                  {isMdDown &&
                    currentDialogue !== "shared" &&
                    currentClientName}
                </Text>
                <Text
                  font={theme.font.arialBlack}
                  size={1.7}
                  color={theme.palette.gold}
                  textAlign={"right"}
                  width={"50%"}
                >
                  {date}
                </Text>
              </Box>
            </Grid>
          )}
          {isLoading && (
            <Text size={2} my={5}>
              Loading dialogue...
            </Text>
          )}
          {isSharedDialogue(dialogue) && currentDialogue === "shared" && (
            <Box>
              <Grid item container spacing={10}>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <HeadingTitle fontSize={2.4} width={19}>
                      {dialogue?.dialogueOne.client.name}
                    </HeadingTitle>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <HeadingTitle fontSize={2.4} width={19} isInverted>
                      {dialogue?.dialogueTwo?.client.name}
                    </HeadingTitle>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container spacing={10}>
                <Grid item container xs={6} justifyContent={"space-around"}>
                  {dialogue?.dialogueOne.overallTop4.map((card, index) => (
                    <Box mt={isMdDown ? 2 : 0} key={card.id}>
                      <CardDisplay card={card} size={"small"} />
                    </Box>
                  ))}
                </Grid>
                <Grid item container xs={6} justifyContent={"space-around"}>
                  {dialogue?.dialogueTwo?.overallTop4.map((card, index) => (
                    <Box mt={isMdDown ? 2 : 0} key={card.id}>
                      <CardDisplay card={card} size={"small"} />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
          )}
          {overallTop4.map((card) => (
            <Grid
              mt={isMdDown ? 5 : 2}
              key={`overall-${card.id}`}
              item
              container
              alignItems={"flex-end"}
              justifyContent={"space-between"}
            >
              <Grid item xs={6} md={"auto"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardDisplay card={card} />
                </Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Clicker value={card.priority} />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} mt={isMdDown ? 3 : 0}>
                <Input
                  multiline
                  minRows={10}
                  placeholder={"Enter notes for summary"}
                  value={card.commentary}
                  onChange={createSetCardNotes(card)}
                />
              </Grid>
            </Grid>
          ))}
          {!isLoading && (
            <Grid item>
              <Box mt={5}>
                <DividingLine />
              </Box>
            </Grid>
          )}
          <Grid item>
            <Box
              my={5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box mr={5}>
                <ButtonPrimary
                  size={"large"}
                  isLoading={isSubmitting}
                  isDisabled={isLoading || isSubmitting}
                  onClick={save}
                >
                  Save
                </ButtonPrimary>
              </Box>
              <Box mr={5}>
                <ButtonTertiary
                  size={"large"}
                  isLoading={isGeneratingPdf}
                  onClick={print}
                >
                  Print
                </ButtonTertiary>
              </Box>
              {isSharedDialogue(dialogue) && currentDialogue !== "shared" && (
                <ButtonPrimary
                  size={"large"}
                  isDisabled={isLoading}
                  onClick={nextSummary}
                >
                  {currentDialogue === "one"
                    ? "Next Summary"
                    : "Shared Summary"}
                </ButtonPrimary>
              )}
            </Box>
          </Grid>
        </Grid>
      </MainLayout>
    </>
  );
};

export default DialogueSummary;
