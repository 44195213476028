import React, { FC, useCallback } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Text from "../../../../components/Text";
import DividingLine from "../../../../components/DividingLine";
import ButtonTertiary from "../../../../components/Button/ButtonTertiary";
import { GridClientCardContainer } from "../styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ListAltIcon from "@material-ui/icons/ListAlt";
import IconLink from "../../../../components/IconLink";
import Anchor from "../../../../components/Anchor";
import { ROUTES } from "../../../../domain/frontend";
import { useHistory } from "react-router-dom";
import { OFFICES } from "../../../../domain/frontend";
import { ClientSummary } from "../types";

interface Props {
  clientSummary: ClientSummary;
}

const ClientCard: FC<Props> = ({
  clientSummary: { client, dialogues },
}): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const startDialogueForClient = useCallback(
    () =>
      history.push({
        pathname: ROUTES.dialogueStart,
        state: { client },
      }),
    [client, history]
  );

  return (
    <GridClientCardContainer item container>
      <Grid item container xs={4} md={3}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Text size={1.8} color={theme.palette.gold} mb={2.5}>
              {client.name}
            </Text>
          </Grid>
          <Grid item>
            <Text size={1.4} color={theme.palette.formSuccess}>
              {client.linkedClientName}
            </Text>
            <Text size={1.4} my={1}>
              {client.email}
            </Text>
            <Text size={1.4} my={1}>
              {OFFICES[client.office]?.title}
            </Text>
            <Text size={1.4}>{client.advisorName}</Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <DividingLine vertical height={"80%"} />
        </Box>
      </Grid>
      <Grid item container xs={7} md={8}>
        <Grid item container direction={"column"} xs={11} md={7}>
          <Grid item>
            <Text size={1.8} color={theme.palette.gold} mb={3}>
              Completed Dialogues
            </Text>
          </Grid>
          {dialogues.map((stub) => (
            <Grid key={stub.id} item>
              <Box sx={{ display: "flex", alignItems: "center" }} mb={1}>
                <Text size={1.4}>
                  {new Date(stub.createdDate).toLocaleDateString()}
                </Text>
                <Box mx={4}>
                  <Anchor
                    noStyle
                    href={`/#${ROUTES.dialogueSummary.replace(":id", stub.id)}`}
                  >
                    <IconLink icon={VisibilityIcon}>View</IconLink>
                  </Anchor>
                </Box>
                <Anchor
                  noStyle
                  href={`/#${ROUTES.dialogueTracker.replace(":id", stub.id)}`}
                >
                  <IconLink icon={ListAltIcon}>Tracker</IconLink>
                </Anchor>
              </Box>
            </Grid>
          ))}
          {!dialogues[0] && (
            <Grid item>
              <Text>No previous dialogues.</Text>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={4} mt={isMdDown ? 2 : 0}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <ButtonTertiary onClick={startDialogueForClient}>
              New dialogue
            </ButtonTertiary>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={4}>
        <DividingLine horizontal />
      </Grid>
    </GridClientCardContainer>
  );
};

export default ClientCard;
