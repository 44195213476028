import { useForm, UseFormReturn } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

export function useDefaultForm<FormType>(
  schema: Joi.AnySchema,
  defaultValues?: FormType
): UseFormReturn<FormType> {
  return useForm<FormType>({
    defaultValues: defaultValues as any,
    mode: "onBlur",
    resolver: joiResolver(schema),
  });
}
