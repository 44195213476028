import { Client } from "../domain/backend";
import httpClient from "./http-client";
import config from "../config";

export async function getClient(clientId: string): Promise<Client> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/clients/${clientId}`,
    {
      method: "GET",
    }
  );

  return response.json();
}
