import httpClient from "./http-client";
import config from "../config";
import { Dialogue, SharedDialogue } from "../domain/backend/models/dialogue";

export async function getDialogue(
  id: string
): Promise<Dialogue | SharedDialogue> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/dialogues/${id}`,
    {
      method: "GET",
    }
  );

  return response.json();
}
