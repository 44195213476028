import { CreateUserForm } from "./types";
import config from "../../../../config";
import { defaultErrorMessage } from "../../../../domain/frontend";
import httpClient from "../../../../utils/http-client";

export async function createUser(request: CreateUserForm): Promise<Response> {
  return httpClient.fetch(`${config.talksig.apiUrl}/admin/users`, {
    method: "POST",
    json: request,
  });
}

export function mapErrorMessage(statusCode: number): string {
  switch (statusCode) {
    case 409:
      return "A user with that email address already exists.";
    default:
      return defaultErrorMessage;
  }
}
