import { Card, DeckCategory, PdfType } from "../../../domain/backend";
import httpClient from "../../../utils/http-client";
import config from "../../../config";
import {
  Dialogue,
  SharedDialogue,
} from "../../../domain/backend/models/dialogue";

export async function setDialogueNotes(
  dialogue: Dialogue | SharedDialogue
): Promise<void> {
  await httpClient.fetch(
    `${config.talksig.apiUrl}/dialogues/${dialogue.id}/notes`,
    {
      method: "PUT",
      json: dialogue,
    }
  );
}

export async function generateSummarySession(
  dialogue: Dialogue | SharedDialogue
): Promise<Blob> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/pdf/generate`,
    {
      method: "POST",
      json: {
        type: PdfType.SUMMARY_SESSION,
        dialogue,
      },
    }
  );

  return response.blob();
}

export function setCardNotes(
  { id }: Card<DeckCategory>,
  deck: Card<DeckCategory>[],
  notes: string
): Card<DeckCategory>[] {
  const existingCard = deck.find((crd) => crd.id === id);

  if (!existingCard) {
    throw new Error(`Card ${id} doesn't exist in deck.`);
  }

  const index = deck.indexOf(existingCard);

  deck[index].commentary = notes;

  return Array.from(deck);
}
