import { Box, useTheme } from "@material-ui/core";
import React, { FC } from "react";
import Text from "../Text";

interface Props {
  color: string;
  title?: string;
}

const ActionStatusIndicator: FC<Props> = ({ color, title }): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        mr={1.5}
        sx={{
          backgroundColor: color,
          borderRadius: "50%",
          width: "2.5rem",
          height: "2.5rem",
        }}
      />
      {title && (
        <Text font={theme.font.arialBlack} size={1.3}>
          {title}
        </Text>
      )}
    </Box>
  );
};

export default ActionStatusIndicator;
