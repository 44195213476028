import React, { FC } from "react";
import { useDrag, useDrop } from "react-dnd";
import DeckDisplay from "../../DeckDisplay";
import { DndCardComponentProps } from "../types";
import { DndCard, DndItemType } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";

interface Props extends DndCardComponentProps {}

const DndDeckDisplay: FC<Props> = ({
  size,
  canDrag,
  canDrop,
  dndCard,
  onHover,
  onEnd,
  onExternalDrop,
}): JSX.Element => {
  const [{ isDragEnabled }, drag] = useDrag(
    () => ({
      canDrag,
      item: dndCard,
      type: DndItemType.CARD,
      collect: (monitor) => ({
        isDragEnabled: monitor.canDrag(),
      }),
      end: (dragCard: DndCard<DeckCategory>, monitor) =>
        onEnd?.(dragCard, monitor, dndCard),
    }),
    [dndCard, canDrag, onEnd]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DndItemType.CARD,
      hover: (hoverCard: DndCard<DeckCategory>, monitor) => {
        if (hoverCard.group === dndCard.group) {
          onHover?.(hoverCard, monitor, dndCard);
        }
      },
      drop: (dropCard: DndCard<DeckCategory>, monitor) => {
        if (dropCard.group !== dndCard.group) {
          onExternalDrop?.(dropCard, monitor, dndCard);
        }
      },
      canDrop: (dropCard: DndCard<DeckCategory>, monitor) => {
        return canDrop?.(dropCard, dndCard, monitor) ?? true;
      },
    }),
    [dndCard, onHover, onExternalDrop]
  );

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        cursor: isDragEnabled ? "move" : "auto",
      }}
    >
      <DeckDisplay size={size} />
    </div>
  );
};

export default DndDeckDisplay;
