import React, { FC, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import CardHolder from "../../CardHolder";
import { DropTargetMonitor } from "react-dnd/dist/types/types";
import { useTheme } from "@material-ui/core";
import { DndCardComponentProps } from "../types";
import { DndCard, DndItemType } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";

interface Props extends DndCardComponentProps {}

const DndCardHolder: FC<Props> = ({
  canDrag,
  canDrop,
  index,
  dndCard,
  onEnd,
  onHover,
  onExternalDrop,
}): JSX.Element => {
  const theme = useTheme();

  const [{ isDragEnabled }, drag] = useDrag(
    () => ({
      canDrag,
      item: dndCard,
      type: DndItemType.CARD,
      collect: (monitor) => ({
        isDragEnabled: monitor.canDrag(),
      }),
      end: (dragCard: DndCard<DeckCategory>, monitor) => {
        if (dragCard.group === dndCard.group) {
          onEnd?.(dragCard, monitor, dndCard);
        }
      },
    }),
    [dndCard, canDrag, onEnd]
  );

  const [{ isExternal, isOver, canAcceptDrop }, drop] = useDrop(
    () => ({
      accept: DndItemType.CARD,
      collect: (monitor: DropTargetMonitor<DndCard<DeckCategory>>) => ({
        canAcceptDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        isExternal:
          monitor.canDrop() && monitor.getItem().group !== dndCard.group,
      }),
      hover: (hoverCard: DndCard<DeckCategory>, monitor) => {
        if (hoverCard.group === dndCard.group) {
          onHover?.(hoverCard, monitor, dndCard);
        }
      },
      drop: (dropCard: DndCard<DeckCategory>, monitor) => {
        if (dropCard.group !== dndCard.group) {
          onExternalDrop?.(dropCard, monitor, dndCard);
        }
      },
      canDrop: (dropCard: DndCard<DeckCategory>, monitor) => {
        return canDrop?.(dropCard, dndCard, monitor) ?? true;
      },
    }),
    [dndCard, onHover, onExternalDrop]
  );

  const border = useMemo(() => {
    if (isOver && isExternal && canAcceptDrop) {
      return `5px solid ${theme.palette.formSuccess}`;
    }
  }, [canAcceptDrop, isExternal, isOver, theme.palette.formSuccess]);

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        cursor: isDragEnabled ? "move" : "auto",
      }}
    >
      <CardHolder border={border} number={index! + 1} />
    </div>
  );
};

export default DndCardHolder;
