import { ActionStatus } from "src/domain/backend";
import theme from "../../../theme";
import { deepFreeze } from "../../../utils/deep-freeze";

export const ACTION_STATUSES: {
  [Status in ActionStatus]: { title: string; color: string };
} = deepFreeze({
  [ActionStatus.COMPLETED]: {
    title: "Completed",
    color: theme.palette.completed,
  },
  [ActionStatus.IMMEDIATE]: {
    title: "Immediate",
    color: theme.palette.immediate,
  },
  [ActionStatus.IMPROVE]: { title: "Improve", color: theme.palette.improve },
  [ActionStatus.OPTIMAL]: { title: "Optimal", color: theme.palette.optimal },
  [ActionStatus.LONG_TERM]: {
    title: "Long Term",
    color: theme.palette.longTerm,
  },
});
