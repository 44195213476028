import Joi from "joi";
import {
  linkedUserSchema,
  phoneSchema,
  requiredString,
} from "../../../utils/schema";
import { OfficeLocation } from "../../../domain/backend";

export const clientRegistrationFormSchema = Joi.object({
  name: requiredString("Name", Joi.string().max(200)),
  phone: phoneSchema.allow("").optional(),
  email: requiredString(
    "Email",
    Joi.string().email({ tlds: false }).min(1).max(200)
  ),
  location: requiredString("City/State", Joi.string().min(1).max(200)),
  office: Joi.number()
    .label("Office location")
    .valid(...Object.values(OfficeLocation))
    .required()
    .messages({
      "any.required": "Please select an Office Location",
    }),
  advisor: Joi.object({
    title: requiredString("Advisor", Joi.string()),
    value: linkedUserSchema.required(),
  }).required(),
  linkedClient: Joi.any().optional(),
});
