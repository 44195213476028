import React, { FC } from "react";
import { Box, Grid } from "@material-ui/core";
import useCurrentDialogue from "../../../state/dialogue/selectors/use-current-dialogue";
import ExploreDeckTile from "./parts/explore-deck-tile";
import DialogueLayout from "../../../layouts/DialogueLayout";
import Text from "../../../components/Text";

interface Props {}

const DialogueDecks: FC<Props> = (props: Props): JSX.Element => {
  const [dialogue] = useCurrentDialogue();

  return (
    <DialogueLayout
      title={"Dialogue"}
      subTitle={dialogue.client.name}
      description={
        <Text size={1.5} textAlign={"center"}>
          Each of these decks represents a key focus area that impacts the way
          you think about wealth. Click on a deck to start exploring the wealth
          principles that guide you.
        </Text>
      }
    >
      <Grid item container justifyContent={"space-around"} spacing={1}>
        {Object.values(dialogue.decks).map((deck) => (
          <Grid key={deck.title} item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ExploreDeckTile deck={deck} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </DialogueLayout>
  );
};

export default DialogueDecks;
