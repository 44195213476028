import React, { FC, useState } from "react";
import UserManagementLayout from "../../../layouts/UserManagementLayout";
import FieldSet from "../../../components/Form/FieldSet";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import Form from "../../../components/Form";
import { ChangePasswordForm } from "./types";
import { Auth } from "aws-amplify";
import { changePasswordFormSchema } from "./schema";
import { useDefaultForm } from "../../../hooks/use-default-form";
import InputPassword from "../../../components/Input/InputPassword";
import { getErrorMessage } from "./utils";

interface Props {}

const ChangePassword: FC<Props> = (props: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useDefaultForm<ChangePasswordForm>(changePasswordFormSchema);
  const [formError, setFormError] = useState("");

  const changePassword = handleSubmit(async ({ oldPassword, newPassword }) => {
    const user = await Auth.currentAuthenticatedUser();

    try {
      await Auth.changePassword(user, oldPassword, newPassword);

      reset();
    } catch (error) {
      setFormError(getErrorMessage(error));
    }
  });

  return (
    <UserManagementLayout heading={"Change Password"}>
      <Form
        error={formError}
        onSubmit={changePassword}
        isSubmitSuccessful={isSubmitSuccessful}
        successMessage={"Password changed successfully!"}
        isLoading={isSubmitting}
        justifyButtons={"flex-end"}
        buttons={[{ type: "submit", text: "Submit", component: ButtonPrimary }]}
      >
        <FieldSet>
          <FieldWrapper
            error={errors.oldPassword}
            name={"Old Password"}
            required
          >
            <InputPassword
              {...register("oldPassword")}
              placeholder={"Enter old password"}
              autoComplete={"current-password"}
            />
          </FieldWrapper>
          <FieldWrapper
            error={errors.newPassword}
            name={"New Password"}
            required
          >
            <InputPassword
              {...register("newPassword")}
              placeholder={"Enter new password"}
              autoComplete={"new-password"}
            />
          </FieldWrapper>
          <FieldWrapper
            error={errors.confirmPassword}
            name={"Confirm New Password"}
            required
          >
            <InputPassword
              {...register("confirmPassword")}
              placeholder={"Enter new password"}
              autoComplete={"new-password"}
            />
          </FieldWrapper>
        </FieldSet>
      </Form>
    </UserManagementLayout>
  );
};

export default ChangePassword;
