import { DndCard } from "../domain/frontend";
import { DeckCategory } from "../domain/backend";

export function setPriorityForCardInDeck(
  id: string,
  priority: number,
  deck: DndCard<DeckCategory>[]
): DndCard<DeckCategory>[] {
  const { item } = findInDeck(id, deck);

  if (item.item) {
    item.item.priority = priority;
  }

  return Array.from(deck);
}

export function findInDeck(
  id: string,
  deck: DndCard<DeckCategory>[]
): { item: DndCard<DeckCategory>; index: number } {
  const item = deck.find((item) => item.id === id);

  if (!item) {
    throw new Error(`Card ${id} doesn't exist in this deck.`);
  }

  return { item, index: deck.indexOf(item) };
}

export function insertIntoDeck({
  id,
  fromDeck,
  toIndex,
  toDeck,
  toGroup,
  backfill,
}: {
  id: string;
  fromDeck: DndCard<DeckCategory>[];
  toIndex: number;
  toDeck: DndCard<DeckCategory>[];
  toGroup: string;
  backfill?: DndCard<DeckCategory>;
}): { fromDeck: DndCard<DeckCategory>[]; toDeck: DndCard<DeckCategory>[] } {
  fromDeck = snapCardsToIndex(fromDeck);
  const { item: fromItem, index: fromIndex } = findInDeck(id, fromDeck);

  toDeck.splice(toIndex, backfill ? 0 : 1, {
    ...fromItem,
    group: toGroup,
  });

  if (backfill) {
    fromDeck.splice(fromIndex, 1, backfill);
  } else {
    fromDeck.splice(fromIndex, 1);
  }

  return {
    fromDeck: Array.from(reIndexDeck(fromDeck)),
    toDeck: Array.from(reIndexDeck(toDeck)),
  };
}

export function moveBetweenDecks({
  id,
  fromDeck,
  fromGroup,
  toIndex,
  toDeck,
  toGroup,
}: {
  id: string;
  fromDeck: DndCard<DeckCategory>[];
  fromGroup: string;
  toIndex: number;
  toDeck: DndCard<DeckCategory>[];
  toGroup: string;
}): { fromDeck: DndCard<DeckCategory>[]; toDeck: DndCard<DeckCategory>[] } {
  fromDeck = snapCardsToIndex(fromDeck);
  const { item: fromItem, index: fromIndex } = findInDeck(id, fromDeck);

  const [toItem] = toDeck.splice(toIndex, 1, {
    ...fromItem,
    group: toGroup,
  });
  fromDeck.splice(fromIndex, 1, {
    ...toItem,
    group: fromGroup,
  });

  return {
    fromDeck: Array.from(reIndexDeck(fromDeck)),
    toDeck: Array.from(reIndexDeck(toDeck)),
  };
}

export function moveInDeck(
  id: string,
  deck: DndCard<DeckCategory>[],
  toIndex: number
): DndCard<DeckCategory>[] {
  let newDeck: DndCard<DeckCategory>[] = snapCardsToIndex(deck);
  const { index: fromIndex } = findInDeck(id, newDeck);

  if ((toIndex === 0 || toIndex === deck.length - 1) && !deck[toIndex].item) {
    directSwap(fromIndex, toIndex, newDeck);
  } else {
    newDeck = bubbleSwap(fromIndex, toIndex, newDeck);
  }

  return Array.from(newDeck);
}

function bubbleSwap(
  fromIndex: number,
  toIndex: number,
  deck: DndCard<DeckCategory>[]
): DndCard<DeckCategory>[] {
  const [fromItem] = deck.splice(fromIndex, 1);

  deck.splice(toIndex, 0, fromItem);

  return Array.from(deck);
}

function directSwap(
  fromIndex: number,
  toIndex: number,
  deck: DndCard<DeckCategory>[]
) {
  const toItem = deck[toIndex];
  deck[toIndex] = deck[fromIndex];
  deck[fromIndex] = toItem;

  return Array.from(deck);
}

export function snapCardsToIndex(
  deck: DndCard<DeckCategory>[]
): DndCard<DeckCategory>[] {
  return deck.sort((a, b) => (a.index > b.index ? 1 : -1));
}

export function reIndexDeck(
  deck: DndCard<DeckCategory>[]
): DndCard<DeckCategory>[] {
  return Array.from(deck.map((card, index) => ({ ...card, index })));
}

export function isInSameDeck(
  cardOneId: string,
  cardTwoId: string,
  ...decks: DndCard<DeckCategory>[][]
): boolean {
  const deckOfCardOne = decks.find((deck) =>
    deck.find((card) => card.id === cardOneId)
  );

  if (!deckOfCardOne) {
    throw new Error(`Card ${cardOneId} is not in any of the provided decks.`);
  }

  return deckOfCardOne.some((card) => card.id === cardTwoId);
}
