import { Box, useTheme } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import Text from "../Text";

interface Props {
  icon?: any;
  onClick?: () => void;
  iconPosition?: "start" | "end";
  fontSize?: number;
  children?: string;
}

const IconLink: FC<Props> = ({
  icon: Icon,
  onClick,
  iconPosition,
  fontSize,
  children,
}): JSX.Element => {
  const theme = useTheme();

  const isIconStart = useMemo(() => {
    return iconPosition !== "end";
  }, [iconPosition]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      {isIconStart && (
        <Icon sx={{ fontSize: "3rem", color: theme.palette.gold }} />
      )}
      <Text
        size={fontSize ?? 1.4}
        color={theme.palette.gold}
        ml={isIconStart ? 1 : 0}
        mr={!isIconStart ? 1 : 0}
        onClick={onClick}
      >
        {children}
      </Text>
      {!isIconStart && (
        <Icon sx={{ fontSize: "3rem", color: theme.palette.gold }} />
      )}
    </Box>
  );
};

export default IconLink;
