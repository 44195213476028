import { createHook } from "react-sweet-state";
import { DialogueState } from "../types";
import { DeckCategory } from "../../../domain/backend";
import { DialogueActions, DialogueStore } from "..";
import { DndDeck } from "../../../domain/frontend";

const useNextAvailableDecks = createHook<
  DialogueState,
  DialogueActions,
  DndDeck<DeckCategory>[],
  string
>(DialogueStore, {
  selector: (
    { dialogues, currentDialogueIndex },
    currentDeckId
  ): DndDeck<DeckCategory>[] => {
    const currentDialogue = dialogues[currentDialogueIndex];

    return Object.values(currentDialogue.decks).filter(
      (deck) => deck.id !== currentDeckId && !deck.isComplete
    );
  },
});

export default useNextAvailableDecks;
