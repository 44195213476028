import React, { FC } from "react";
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Text from "../Text";
import { DivFooterContainer } from "./styles";
import IconsItem from "./parts/icons-item";
import Contact from "./parts/contact";
import Menu from "./parts/menu";
import { getNavbarLinks } from "../../domain/frontend";
import { useGlobalState } from "../../state/global";

const Footer: FC = (): JSX.Element => {
  const theme = useTheme();
  const [{ user }] = useGlobalState();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth={"xl"}>
      <DivFooterContainer>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12} md={3}>
            <IconsItem />
          </Grid>
          <Grid item container direction={"column"} xs={12} sm={5} md={9}>
            <Grid item container>
              <Grid item>
                <Text
                  font={theme.font.arialBlack}
                  size={1.3}
                  color={theme.palette.gold}
                  mt={isMdDown ? 5 : 0}
                  mb={3}
                >
                  CONTACT US
                </Text>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} md={4}>
                <Contact
                  site={{
                    city: "ST. Louis",
                    address: {
                      lineOne: "1401 South Brentwood Boulevard",
                      lineTwo: "Suite 630",
                      lineThree: "St. Louis, MO 63144",
                    },
                    email: "info@mosaicwealth.com",
                    phone: "314.627.5220",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Contact
                  site={{
                    city: "Denver",
                    address: {
                      lineOne: "6400 S. Fiddler’s Green Circle",
                      lineTwo: "Suite 1760",
                      lineThree: "Greenwood Village, CO 80111",
                    },
                    email: "info@mosaicwealth.com",
                    phone: "720.639.7200",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Menu navbarLinks={getNavbarLinks(user)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DivFooterContainer>
    </Container>
  );
};

export default Footer;
