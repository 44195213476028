import { AppConfig } from "./domain/frontend";

const config: AppConfig = {
  env: {
    publicUrl: process.env.PUBLIC_URL!,
  },
  talksig: {
    apiUrl: process.env.REACT_APP_API_URL!,
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },
  mosaic: {
    homeUrl: process.env.REACT_APP_MOSAIC_HOME!,
    facebookUrl: process.env.REACT_APP_MOSAIC_FB!,
    linkedInUrl: process.env.REACT_APP_MOSAIC_LI!,
  },
  amplify: {
    region: process.env.REACT_APP_AWS_REGION!,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
    authenticationFlowType: "USER_SRP_AUTH",
  },
};

export default config;
