import { ButtonSize, ButtonSizingModel } from "./types";

export const calculateButtonSizing = (
  size: ButtonSize = "normal",
  isFullWidth = false
): ButtonSizingModel => {
  if (size === "large") {
    return {
      height: "5rem",
      padding: "1rem 6rem",
      fontSize: "1.6rem",
      width: isFullWidth ? "100%" : "auto",
    };
  } else if (size === "normal") {
    return {
      height: "4.4rem",
      padding: "0.8rem 1.6rem",
      fontSize: "1.4rem",
      width: isFullWidth ? "100%" : "auto",
    };
  } else {
    return {
      height: "3rem",
      padding: "0.5rem 1.3rem",
      fontSize: "1.2rem",
      width: isFullWidth ? "100%" : "auto",
    };
  }
};
