import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { FC } from "react";
import { DivCardHolderContainer } from "./styles";
import Text from "../Text";
import { useCardSize } from "../../hooks/use-card-size";

interface Props {
  number: number;
  border?: string;
  size?: "regular" | "medium" | "small";
}

const CardHolder: FC<Props> = ({ number, border, size }): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const sizing = useCardSize(size ?? isMdDown ? "medium" : "regular");

  return (
    <DivCardHolderContainer
      border={border ?? `1px solid ${theme.palette.gold}`}
      height={sizing.height}
      width={sizing.width}
    >
      <div>
        <Text
          font={theme.font.arialBlack}
          size={9.4}
          color={theme.palette.gray}
        >
          {number}
        </Text>
      </div>
    </DivCardHolderContainer>
  );
};

export default CardHolder;
