import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Grid } from "@material-ui/core";
import { DragSourceMonitor, DropTargetMonitor } from "react-dnd";
import { DndCard } from "../../../domain/frontend";
import { DndCardComponentProps } from "../types";
import { DeckCategory } from "../../../domain/backend";
import {
  findInDeck,
  moveInDeck,
  reIndexDeck,
} from "../../../utils/deck-operations";

interface Props {
  cards: DndCard<DeckCategory>[];
  setCards: Dispatch<SetStateAction<DndCard<DeckCategory>[]>>;
  occupiedComponent: FC<DndCardComponentProps>;
  emptyComponent: FC<DndCardComponentProps>;
  size?: "regular" | "small";
  onExternalDrop?: (
    dropItem: DndCard<DeckCategory>,
    monitor: DropTargetMonitor,
    item: DndCard<DeckCategory>
  ) => void;
  canDrop?: (
    dropItem: DndCard<DeckCategory>,
    dndCard: DndCard<DeckCategory>,
    monitor: DropTargetMonitor<DndCard<DeckCategory>>
  ) => boolean;
  canHover?: (
    hoverItem: DndCard<DeckCategory>,
    dndCard: DndCard<DeckCategory>,
    monitor: DropTargetMonitor<DndCard<DeckCategory>>
  ) => boolean;
}

const DndCardSet: FC<Props> = ({
  size,
  cards,
  setCards,
  occupiedComponent: OccupiedComponent,
  emptyComponent: EmptyComponent,
  onExternalDrop,
  canDrop,
  canHover,
}): JSX.Element => {
  const findCard = useCallback(
    (id: string): { index: number; item: DndCard<DeckCategory> } =>
      findInDeck(id, cards),
    [cards]
  );

  const reIndex = useCallback(
    () => setCards((prevItems) => reIndexDeck(prevItems)),
    [setCards]
  );

  const moveCard = useCallback(
    (id: string, toIndex: number) =>
      setCards((prevItems) => moveInDeck(id, prevItems, toIndex)),
    [setCards]
  );

  const onEnd = useCallback(
    (
      dragCard: DndCard<DeckCategory>,
      monitor: DragSourceMonitor,
      card: DndCard<DeckCategory>
    ) => {
      if (!monitor.didDrop()) {
        moveCard(dragCard.id, dragCard.index);
      } else {
        reIndex();
      }
    },
    [moveCard, reIndex]
  );

  const onHover = useCallback(
    (
      hoverCard: DndCard<DeckCategory>,
      monitor: DropTargetMonitor,
      card: DndCard<DeckCategory>
    ) => {
      if (!!canHover && !canHover(hoverCard, card, monitor)) {
        return;
      }

      if (hoverCard.id !== card.id) {
        const { index } = findCard(card.id);
        moveCard(hoverCard.id, index);
      }
    },
    [canHover, findCard, moveCard]
  );

  const spacing = useMemo(() => {
    return !size || size === "regular" ? 3 : 1;
  }, [size]);

  return (
    <Grid item container justifyContent={"center"} spacing={spacing}>
      {cards.map((dndCard, index) => (
        <Grid item key={dndCard.id}>
          {dndCard.item ? (
            <OccupiedComponent
              size={size}
              canDrop={canDrop}
              index={index}
              dndCard={dndCard}
              onHover={onHover}
              onEnd={onEnd}
              onExternalDrop={onExternalDrop}
              dragOpacity={0.3}
            />
          ) : (
            <EmptyComponent
              size={size}
              canDrop={canDrop}
              canDrag={false}
              index={index}
              dndCard={dndCard}
              onHover={onHover}
              onEnd={onEnd}
              onExternalDrop={onExternalDrop}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DndCardSet;
