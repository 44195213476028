import styled from "styled-components/macro";
import { AnchorNoStyle } from "../Anchor/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Anchor from "../Anchor";
import { Theme } from "@material-ui/core/styles";

export const AnchorNavbar = styled(Anchor)`
  color: white;
  text-decoration: none;
`;

export const DivNavbarContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: ${(props) => props.theme.navbar.height}rem;
  background-color: var(--black);
`;

export const AnchorNavbarLink = styled(AnchorNoStyle)<{
  isActive: boolean;
  isMobile: boolean;
}>`
  font-family: var(--fontArialBlack);
  font-size: 1.3rem;
  text-transform: uppercase;
  color: var(${(props) => (props.isActive ? "--gold" : "--white")});
  margin-right: ${(props) => (props.isMobile ? "2rem" : "var(--g8)")};
  transition: color 200ms linear;

  &:hover {
    color: var(--gold);
  }
`;

export const UlNavbarLinkList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`;

export const LiNavbarLinkList = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

export const DivProfileContainer = styled.div`
  position: relative;
  margin-right: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivProfileIconsContainer = styled.div`
  cursor: pointer;
`;

export const SvgProfileIcon = styled(AccountCircleIcon)`
  cursor: pointer;
  font-size: 3rem;
`;

export const SvgChevDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;
  font-size: 3rem;
  color: var(--gold);
`;

export const DivDropdownContainer = styled.div<{
  theme: Theme;
  isVisible: boolean;
}>`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.tooltip};
  right: 0;
  top: calc(100% + var(--g1));
  min-width: 20rem;
  background-color: var(--bgDropdown);
  padding: 1.5rem 0;
`;

export const UlDropdownList = styled.ul`
  a {
    &:last-of-type {
      li {
        padding: 2.5rem 2rem 0;
      }
    }
  }
`;

export const LiDropdownItem = styled.li<{
  isSeparator?: boolean;
  isActive?: boolean;
}>`
  line-height: 1.5;
  padding: ${(props) => (props.isSeparator ? "0" : "1.3rem 2.1rem 1.5rem")};
  color: var(${(props) => (props.isActive ? "--gold" : "--white")});

  hr {
    opacity: 0.4;
    margin: 0 2rem;
  }

  &:hover {
    color: var(--gold);
  }
`;
