import styled from "styled-components/macro";

export const SvgStyledSliderButton = styled.svg<{
  scale: number;
  isEditable: boolean;
  isDisabled: boolean;
}>`
  user-select: none;
  cursor: ${(props) =>
    props.isDisabled || !props.isEditable ? "auto" : "pointer"};
  filter: grayscale(${(props) => (props.isDisabled ? "1" : "0")});
  transform: scale(${(props) => props.scale});
  overflow: visible;
`;
