import { Grid } from "@material-ui/core";
import styled from "styled-components/macro";

export const GridActionItemContainer = styled(Grid)`
  padding: var(--g3) var(--g2);

  &:nth-of-type(2n) {
    background-color: ${(props) => props.theme.palette.black};
  }
`;
