import React, { FC, forwardRef, useCallback, useState } from "react";
import Input from "../index";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { InputProps } from "../types";

const InputPassword: FC<InputProps> = forwardRef(
  (props: InputProps, ref): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = useCallback((): void => {
      setShowPassword((prevState) => !prevState);
    }, []);

    return (
      <Input
        {...props}
        ref={ref}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment
            sx={{
              "& .MuiSvgIcon-root": {
                width: "1.8rem",
                height: "1.8rem",
              },
            }}
            position="end"
          >
            <IconButton
              aria-label="toggle newPassword visibility"
              onClick={toggleShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    );
  }
);

export default InputPassword;
