import { DndCard } from "../domain/frontend";
import { v4 } from "uuid";
import { Card, DeckCategory } from "../domain/backend";

export function createDndCard<Category extends DeckCategory>(
  index: number,
  group: string,
  item?: Card<Category>,
  id: string = v4()
): DndCard<Category> {
  return {
    id,
    index,
    group,
    item,
  };
}
