import { Box, Grid, useTheme } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import AdminLayout from "src/layouts/AdminLayout";
import Text from "../../../../components/Text";
import { useHistory, useParams } from "react-router-dom";
import { AdminUserDetails, UserStatus } from "./types";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import { UserGroups } from "../../../../domain/backend/enums/user-groups";
import {
  changeUserStatus,
  deleteUser,
  getUserDetails,
  resendUserInvite,
  setUserGroups,
} from "./utils";
import { defaultErrorMessage } from "../../../../domain/frontend";
import { OFFICES } from "../../../../domain/frontend";
import { ROUTES } from "../../../../domain/frontend";
import ButtonTertiary from "../../../../components/Button/ButtonTertiary";
import Modal from "../../../../components/Modal";
import { TEMP_USER_STATUS } from "./constants";

interface Props {}

const UserDetails: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [userDetails, setUserDetails] = useState<AdminUserDetails>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const isAdmin = useMemo((): boolean | undefined => {
    return userDetails?.groups.some((group) => group === UserGroups.ADMIN);
  }, [userDetails]);

  const isEnabled = useMemo(() => userDetails?.enabled, [userDetails]);

  const isTempUser = useMemo(
    () => userDetails?.status === TEMP_USER_STATUS,
    [userDetails?.status]
  );

  const tempPasswordExpiryDate = useMemo(() => {
    if (!userDetails?.updated) {
      return undefined;
    }

    const lastModifiedDate = new Date(userDetails.updated);
    lastModifiedDate.setDate(lastModifiedDate.getDate() + 7);

    return lastModifiedDate;
  }, [userDetails?.updated]);

  const isTempPasswordExpired = useMemo(
    () =>
      isTempUser && tempPasswordExpiryDate
        ? tempPasswordExpiryDate < new Date()
        : false,
    [isTempUser, tempPasswordExpiryDate]
  );

  const submitDeleteUser = useCallback(async () => {
    try {
      setIsDeleteUserModalOpen(false);
      setIsLoading(true);

      await deleteUser({
        username: userDetails!.email,
      });

      history.push(ROUTES.adminDashboard);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [history, userDetails]);

  const toggleIsAdmin = useCallback(
    async (isAdmin: boolean) => {
      try {
        setIsLoading(true);
        const groups = isAdmin ? [UserGroups.ADMIN] : [];

        await setUserGroups({
          groups,
          username: userDetails!.email,
        });

        setUserDetails(await getUserDetails({ userId: userDetails!.id }));
      } catch (error) {
        setErrorMessage(defaultErrorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [userDetails]
  );

  const toggleIsEnabled = useCallback(
    async (isEnabled: boolean) => {
      try {
        setIsLoading(true);
        await changeUserStatus({
          username: userDetails!.email,
          status: isEnabled ? UserStatus.ENABLED : UserStatus.DISABLED,
        });

        setUserDetails(await getUserDetails({ userId: userDetails!.id }));
      } catch (error) {
        setErrorMessage(defaultErrorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [userDetails]
  );

  const submitResentUserInvite = useCallback(async () => {
    try {
      setIsLoading(true);
      await resendUserInvite({
        email: userDetails!.email,
      });

      setUserDetails(await getUserDetails({ userId: userDetails!.id }));
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [userDetails]);

  const renderHeading = useCallback(
    (body: string) => (
      <Text font={theme.font.arialBlack} my={1}>
        {body}
      </Text>
    ),
    [theme]
  );

  const renderValue = useCallback(
    (body?: string) => <Text my={1}>{body ?? "-"}</Text>,
    []
  );

  useEffect(() => {
    setIsLoading(true);

    getUserDetails({ userId })
      .then((userDetails) => setUserDetails(userDetails))
      .catch(() => setErrorMessage(defaultErrorMessage))
      .finally(() => setIsLoading(false));
  }, [history, userId]);

  return (
    <>
      <Modal
        isOpen={isDeleteUserModalOpen}
        onClose={() => setIsDeleteUserModalOpen(false)}
      >
        <Box>
          <Text mb={3} size={1.8}>
            Are you sure you want to delete {userDetails?.name}?
          </Text>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box mr={2}>
              <ButtonPrimary onClick={submitDeleteUser}>Confirm</ButtonPrimary>
            </Box>
            <Box ml={2}>
              <ButtonTertiary onClick={() => setIsDeleteUserModalOpen(false)}>
                Cancel
              </ButtonTertiary>
            </Box>
          </Box>
        </Box>
      </Modal>
      <AdminLayout
        title={"User Details"}
        errorMessage={errorMessage}
        isLoading={isLoading}
      >
        <Grid container>
          <Grid item>
            <Box my={3} mr={5}>
              {renderHeading("Id")}
              {renderHeading("Name")}
              {renderHeading("Email")}
              {renderHeading("Phone")}
              {renderHeading("Office")}
              {renderHeading("Enabled")}
              {renderHeading("Is Admin")}
              {renderHeading("Created")}
            </Box>
          </Grid>
          {userDetails && (
            <>
              <Grid item>
                <Box my={3}>
                  {renderValue(userDetails?.id)}
                  {renderValue(userDetails?.name)}
                  {renderValue(userDetails?.email)}
                  {renderValue(userDetails?.phone)}
                  {userDetails?.office !== undefined &&
                    renderValue(OFFICES[userDetails.office]?.title)}
                  {renderValue(userDetails?.enabled ? "Yes" : "No")}
                  {renderValue(isAdmin ? "Yes" : "No")}
                  {renderValue(userDetails?.created?.toLocaleString())}
                </Box>
              </Grid>
              {isTempUser && (
                <Grid item xs={12} mb={3}>
                  {!isTempPasswordExpired && (
                    <Text color={theme.palette.warningBanner}>
                      The user's temporary password will expire on&nbsp;
                      {tempPasswordExpiryDate?.toLocaleString()}.
                    </Text>
                  )}
                  {isTempPasswordExpired && (
                    <Text color={theme.palette.formError}>
                      The user's temporary password has expired. Re-invite them
                      to send another.
                    </Text>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid item container spacing={5}>
                  <Grid item>
                    {isAdmin ? (
                      <ButtonPrimary
                        isDisabled={isLoading}
                        onClick={() => toggleIsAdmin(false)}
                      >
                        Revoke Admin
                      </ButtonPrimary>
                    ) : (
                      <ButtonPrimary
                        isDisabled={isLoading}
                        onClick={() => toggleIsAdmin(true)}
                      >
                        Make Admin
                      </ButtonPrimary>
                    )}
                  </Grid>
                  <Grid item>
                    {isEnabled ? (
                      <ButtonPrimary
                        isDisabled={isLoading}
                        onClick={() => toggleIsEnabled(false)}
                      >
                        Disable User
                      </ButtonPrimary>
                    ) : (
                      <ButtonPrimary
                        isDisabled={isLoading}
                        onClick={() => toggleIsEnabled(true)}
                      >
                        Enable User
                      </ButtonPrimary>
                    )}
                  </Grid>
                  <Grid item>
                    <ButtonTertiary
                      isDisabled={isLoading}
                      onClick={() => setIsDeleteUserModalOpen(true)}
                    >
                      Delete User
                    </ButtonTertiary>
                  </Grid>
                  {isTempUser && (
                    <Grid item>
                      <ButtonTertiary
                        isDisabled={isLoading}
                        onClick={submitResentUserInvite}
                      >
                        Resend Invite
                      </ButtonTertiary>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </AdminLayout>
    </>
  );
};

export default UserDetails;
