import { DndCard } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";

export function isAllCardsChosen(
  cardsToCheck: DndCard<DeckCategory>[]
): boolean {
  return cardsToCheck.filter((card) => !!card.item).length >= 4;
}

export function validateTop4Cards(top4Cards: DndCard<DeckCategory>[]): {
  isValid: boolean;
  errorMessage: string;
} {
  if (!isAllCardsChosen(top4Cards)) {
    return {
      isValid: false,
      errorMessage:
        "Please rank all cards from 1 - 4 in this deck before continuing.",
    };
  }

  return { isValid: true, errorMessage: "" };
}
