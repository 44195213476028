import { Action } from "react-sweet-state";
import { DialogueState } from "../types";
import { DeckCategory } from "../../../domain/backend";
import { DndCard } from "../../../domain/frontend";
import dcopy from "deep-copy";

const saveOverallTopFour =
  ({
    relationship,
    financial,
    experience,
    legacy,
    overall,
  }: {
    relationship: DndCard<DeckCategory>[];
    financial: DndCard<DeckCategory>[];
    experience: DndCard<DeckCategory>[];
    legacy: DndCard<DeckCategory>[];
    overall: DndCard<DeckCategory>[];
  }): Action<DialogueState> =>
  ({ getState, setState }) => {
    const prevState = getState();
    const dialogue = prevState.dialogues[prevState.currentDialogueIndex];

    dialogue.decks[DeckCategory.FINANCIAL].top4 = financial;
    dialogue.decks[DeckCategory.RELATIONSHIP].top4 = relationship;
    dialogue.decks[DeckCategory.EXPERIENCE].top4 = experience;
    dialogue.decks[DeckCategory.LEGACY].top4 = legacy;
    dialogue.overallTop4 = overall;

    setState(dcopy(prevState));
  };

export default saveOverallTopFour;
