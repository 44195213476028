import React, { FC } from "react";
import MainLayout from "../MainLayout";
import { DivFormContainer, DivManageUserLayoutContainer } from "./styles";
import Text from "../../components/Text";
import { useTheme } from "@material-ui/core";

interface Props {
  heading: string;
  children?: any;
}

const UserManagementLayout: FC<Props> = ({
  heading,
  children,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <MainLayout>
      <DivManageUserLayoutContainer>
        <div>
          <Text size={4.8} font={theme.font.arialBlack} textAlign={"center"}>
            {heading}
          </Text>
          <DivFormContainer>{children}</DivFormContainer>
        </div>
      </DivManageUserLayoutContainer>
    </MainLayout>
  );
};

export default UserManagementLayout;
