import styled from "styled-components/macro";

export const DivFooterContainer = styled.div`
  min-height: ${(props) => props.theme.footer.height}rem;
  display: flex;
  align-items: center;
  padding: 2rem 0;
`;

export const ImgFooterLogo = styled.img`
  width: 16.1rem;
  height: auto;
`;

export const ImgFooterSocialIcon = styled.img<{ mr?: number; ml?: number }>`
  height: 2.6rem;
  width: 2.6rem;
  margin-right: ${(prop) => prop.mr}rem;
  margin-left: ${(prop) => prop.ml}rem;
`;
