export function deepFreeze<T>(object: T): T {
  Object.freeze(object);

  Object.values(object).forEach((child) => {
    if (typeof child === "object") {
      deepFreeze(child);
    }
  });

  return object;
}
