import { defaultErrorMessage } from "../../../domain/frontend";

export function mapErrorMessage(statusCode: number): string {
  switch (statusCode) {
    case 404:
      return "Something went wrong fetching the linked client details.";
    default:
      return defaultErrorMessage;
  }
}
