import { NavbarLinkModel } from "../../../components/Navbar/types";
import { ROUTES } from "./routes";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { User } from "../models/user";

const navbarLinksPublic: NavbarLinkModel[] = [
  { href: `/#${ROUTES.home}`, label: "Home" },
  { href: `/#${ROUTES.login}`, label: "Login" },
];

const navbarLinksSecure: NavbarLinkModel[] = [
  { href: `/#${ROUTES.dashboard}`, label: "Dashboard" },
];

const navbarLinksAdmin: NavbarLinkModel[] = [
  { href: `/#${ROUTES.adminDashboard}`, label: "Admin" },
];

export const dropdownLinks: NavbarLinkModel[] = [
  {
    href: `/#${ROUTES.changePassword}`,
    label: "Change Password",
    icon: VpnKeyIcon,
  },
  { isSeparator: true, href: "", label: "" },
  { href: `/#${ROUTES.logout}`, label: "Log Out", icon: ExitToAppIcon },
];

export function getNavbarLinks(user?: User): NavbarLinkModel[] {
  return !!user
    ? user.isAdmin
      ? [...navbarLinksSecure, ...navbarLinksAdmin]
      : navbarLinksSecure
    : navbarLinksPublic;
}
