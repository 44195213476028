import React, { forwardRef } from "react";
import { Box, BoxProps, useTheme } from "@material-ui/core";

interface Props extends BoxProps {
  font?: string;
  size?: number;
  color?: string;
  children?: any;
}

const Text: React.FC<Props> = forwardRef((props: Props, ref): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      fontFamily={props.font ?? theme.font.arial}
      color={props.color ?? theme.palette.white}
      style={{
        fontSize: props.size !== undefined ? `${props.size}rem` : "1.6rem",
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
});

export default Text;
