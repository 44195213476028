import React, { FC, useMemo, useState } from "react";
import Form from "src/components/Form";
import UserManagementLayout from "../../../layouts/UserManagementLayout";
import FieldSet from "../../../components/Form/FieldSet";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import { useDefaultForm } from "../../../hooks/use-default-form";
import { forgotPassSubmitFormSchema } from "./schema";
import { ForgotPassSubmitForm, ForgotPassSubmitLocationState } from "./types";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { Auth } from "aws-amplify";
import { defaultErrorMessage } from "../../../domain/frontend";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../../domain/frontend";
import InputPassword from "../../../components/Input/InputPassword";
import { Box } from "@material-ui/core";
import Text from "../../../components/Text";
import Input from "../../../components/Input";
import * as Sentry from "@sentry/react";

interface Props {}

const ForgottenPasswordSubmit: FC<Props> = (props: Props): JSX.Element => {
  const history = useHistory();
  const { state } = useLocation<ForgotPassSubmitLocationState>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useDefaultForm<ForgotPassSubmitForm>(forgotPassSubmitFormSchema);
  const [formError, setFormError] = useState("");

  const { username } = useMemo(() => {
    return {
      username: state?.username,
    };
  }, [state]);

  const submitForgottenPassword = handleSubmit(
    async ({ code, newPassword }) => {
      try {
        await Auth.forgotPasswordSubmit(username, code, newPassword);

        history.push(ROUTES.login);
      } catch (error) {
        Sentry.captureException(error);
        setFormError(defaultErrorMessage);
      }
    }
  );

  return (
    <UserManagementLayout heading={"Forgot password"}>
      <Box mb={5}>
        <Text size={1.7}>
          A verification code was sent to {username}. Please enter the code
          below to reset your password.
        </Text>
      </Box>
      <Form
        onSubmit={submitForgottenPassword}
        isLoading={isSubmitting}
        error={formError}
        justifyButtons={"flex-end"}
        buttons={[{ type: "submit", text: "Submit", component: ButtonPrimary }]}
      >
        <FieldSet>
          <FieldWrapper name={"Code"} error={errors.code} required>
            <Input autoFocus {...register("code")} type={"number"} />
          </FieldWrapper>
          <FieldWrapper
            error={errors.newPassword}
            name={"New Password"}
            required
          >
            <InputPassword
              {...register("newPassword")}
              placeholder={"Enter new password"}
              autoComplete={"new-password"}
            />
          </FieldWrapper>
          <FieldWrapper
            error={errors.confirmPassword}
            name={"Confirm New Password"}
            required
          >
            <InputPassword
              {...register("confirmPassword")}
              placeholder={"Enter new password"}
              autoComplete={"new-password"}
            />
          </FieldWrapper>
        </FieldSet>
      </Form>
    </UserManagementLayout>
  );
};

export default ForgottenPasswordSubmit;
