import React, { FC, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import { Grid } from "@material-ui/core";
import FieldSet from "../../../../components/Form/FieldSet";
import FieldWrapper from "../../../../components/Form/FieldWrapper";
import Form from "../../../../components/Form";
import Input from "../../../../components/Input";
import { useDefaultForm } from "../../../../hooks/use-default-form";
import { createUserFormSchema } from "./schema";
import { CreateUserForm } from "./types";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import Text from "../../../../components/Text";
import { createUser, mapErrorMessage } from "./utils";

interface Props {}

const CreateUser: FC<Props> = (props: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useDefaultForm<CreateUserForm>(createUserFormSchema);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formError, setFormError] = useState("");

  const submitCreateUser = handleSubmit(async ({ email }) => {
    setShowSuccess(false);
    setFormError("");

    try {
      await createUser({ email });

      reset();
      setShowSuccess(true);
    } catch (error) {
      setShowSuccess(false);
      setFormError(mapErrorMessage(error.response?.status));
    }
  });

  return (
    <AdminLayout title={"Create User"}>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Text mb={2}>
            Enter the user's email address below to send a registration email
            containing a temporary password. On first login the user will be
            asked to enter their personal details.
          </Text>
          <Text mb={5}>
            Please be aware that users will have full access to customer
            information stored within the Talking Significance database - only
            invite users who are authorized to view such information.
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Form
            onSubmit={submitCreateUser}
            justifyButtons={"center"}
            buttons={[
              { type: "submit", component: ButtonPrimary, text: "Submit" },
            ]}
            error={formError}
            isLoading={isSubmitting}
            isSubmitSuccessful={showSuccess}
            successMessage={"Registration email sent successfully."}
          >
            <FieldSet>
              <FieldWrapper name={"Email"} error={errors.email} required>
                <Input
                  {...register("email")}
                  placeholder={"Enter user's email address"}
                />
              </FieldWrapper>
            </FieldSet>
          </Form>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default CreateUser;
