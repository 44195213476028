import React, { FC, useEffect, useMemo, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import Text from "../../../components/Text";
import TalkSigTextLogo from "../../../components/TalkSigTextLogo";
import { Box, Grid, MenuItem, useTheme } from "@material-ui/core";
import Input from "src/components/Input";
import FieldSet from "../../../components/Form/FieldSet";
import Select from "src/components/Select";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { DivClientRegisterPageContainer } from "./styles";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import Form from "../../../components/Form";
import { ConfirmSignupForm, ConfirmSignupLocationState } from "./types";
import { OFFICES } from "../../../domain/frontend";
import { useDefaultForm } from "../../../hooks/use-default-form";
import { confirmSignUpFormSchema } from "./schema";
import { Controller } from "react-hook-form";
import { OfficeLocation } from "../../../domain/backend";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { defaultErrorMessage } from "../../../domain/frontend";
import InputPassword from "../../../components/Input/InputPassword";
import { ROUTES } from "../../../domain/frontend";
import { parsePhoneNumber } from "./utils";
import * as Sentry from "@sentry/react";

interface Props {}

const ConfirmSignup: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useDefaultForm<ConfirmSignupForm>(confirmSignUpFormSchema, {
    officeLocation: OfficeLocation.DENVER,
  } as ConfirmSignupForm);
  const { state: locationState } = useLocation<ConfirmSignupLocationState>();
  const [formError, setFormError] = useState("");

  const { tempUser, existingEmail, existingPhone, canRememberDevice } =
    useMemo(() => {
      const userAttributes = (locationState?.user as any)?.challengeParam
        ?.userAttributes;

      const state = {
        tempUser: locationState?.user,
        existingEmail: userAttributes?.email,
        existingPhone: userAttributes?.phone,
        canRememberDevice: locationState?.canRememberDevice,
      };

      if (!state.tempUser) {
        history.push(ROUTES.login);
      }

      return state;
    }, [history, locationState]);

  const confirmSignUp = handleSubmit(async (form) => {
    try {
      const user = await Auth.completeNewPassword(tempUser, form.newPassword, {
        name: form.name,
        email: form.email,
        phone_number: parsePhoneNumber(form.phone),
        "custom:office": form.officeLocation.toString(),
      });

      history.push({
        pathname: ROUTES.verifyCode,
        state: { user, canRememberDevice },
      });
    } catch (error) {
      Sentry.captureException(error);
      setFormError(defaultErrorMessage);
    }
  });

  useEffect(() => {
    if (existingEmail) {
      setValue("email", existingEmail);
    }

    if (existingPhone) {
      setValue("phone", existingPhone);
    }
  }, [existingEmail, existingPhone, setValue]);

  return (
    <MainLayout>
      <DivClientRegisterPageContainer>
        <Grid container justifyContent={"center"}>
          <Grid item container justifyContent={"center"}>
            <Grid item>
              <Text font={theme.font.arialBlack} size={3.8}>
                Advisor Registration
              </Text>
              <Text font={theme.font.din} size={1.6}>
                Setup your personal advisor profile
              </Text>
            </Grid>
            <Grid item>
              <Box ml={20}>
                <TalkSigTextLogo />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={11} sm={8} md={5} lg={4}>
            <Box mt={8}>
              <Form
                error={formError}
                isLoading={isSubmitting}
                onSubmit={confirmSignUp}
                justifyButtons={"flex-end"}
                buttons={[
                  {
                    text: "Submit",
                    type: "submit",
                    component: ButtonPrimary,
                  },
                ]}
              >
                <FieldSet>
                  <FieldWrapper name={"Name"} error={errors.name} required>
                    <Input {...register("name")} placeholder={"Enter name"} />
                  </FieldWrapper>
                  <FieldWrapper
                    name={"Phone Number"}
                    error={errors.phone}
                    required
                  >
                    <Input
                      {...register("phone")}
                      defaultValue={existingPhone}
                      placeholder={"Enter phone number"}
                    />
                  </FieldWrapper>
                  <FieldWrapper name={"Email"} error={errors.email} required>
                    <Input
                      {...register("email")}
                      defaultValue={existingEmail}
                      placeholder={"Enter email address"}
                    />
                  </FieldWrapper>
                  <FieldWrapper
                    name={"Office"}
                    error={errors.officeLocation}
                    required
                  >
                    <Controller
                      control={control}
                      name={"officeLocation"}
                      render={({ field }) => (
                        <Select {...field}>
                          {Object.entries(OFFICES).map(([value, details]) => (
                            <MenuItem key={details.title} value={value}>
                              {details.title}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper
                    name={"New password"}
                    error={errors.newPassword}
                    required
                  >
                    <InputPassword
                      {...register("newPassword")}
                      placeholder={"Enter new password"}
                      autoComplete={"new-password"}
                    />
                  </FieldWrapper>
                </FieldSet>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </DivClientRegisterPageContainer>
    </MainLayout>
  );
};

export default ConfirmSignup;
