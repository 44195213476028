import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Container, Grid } from "@material-ui/core";
import { DivMainContent } from "./styles";
import { useGlobalState } from "../../state/global";
import { dropdownLinks, getNavbarLinks } from "../../domain/frontend";

interface Props {
  children?: any;
  isBgTiled?: boolean;
}

const MainLayout: React.FC<Props> = ({
  children,
  isBgTiled,
}: Props): JSX.Element => {
  const [{ user }] = useGlobalState();

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Navbar
          navbarLink={getNavbarLinks(user)}
          dropdownLinks={dropdownLinks}
          isSecure={!!user}
        />
      </Grid>
      <Grid item container xs={12} justifyContent={"center"}>
        <DivMainContent isTiled={isBgTiled}>
          <Container>{children}</Container>
        </DivMainContent>
      </Grid>
      <Grid item container xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default MainLayout;
