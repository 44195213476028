import React, { FC, useCallback } from "react";
import {
  Box,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Text from "../../../../components/Text";
import Input from "../../../../components/Input";
import HeadingSubtitle from "../../../../components/Heading/HeadingSubtitle";
import Select from "../../../../components/Select";
import { OFFICES } from "../../../../domain/frontend";
import { User } from "../../../../domain/frontend";
import { ClientFilterForm } from "../types";

interface Props {
  advisor: User;
  filterForm: ClientFilterForm;
  changeFilterForm: (field: string, value: any) => void;
}

const ClientSearch: FC<Props> = ({
  advisor,
  filterForm,
  changeFilterForm,
}): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const createChangeFormForField = useCallback(
    (field: string) => (event: any) =>
      changeFilterForm(field, event.target.value),
    [changeFilterForm]
  );

  return (
    <Box pt={0.5} pl={isMdDown ? 1 : 7} mr={isMdDown ? 1 : 0}>
      <Grid item>
        <HeadingSubtitle>{advisor?.name}</HeadingSubtitle>
      </Grid>
      <Grid item>
        <Text font={"Arial Black"} size={1.3} textAlign={"right"} mt={4}>
          Filter by office, client or advisor name
        </Text>
        <Box display={"flex"} justifyContent={"flex-end"} mt={1.5} mb={3}>
          <Box width={"15rem"}>
            <Select
              value={filterForm.office}
              onChange={createChangeFormForField("office")}
            >
              <MenuItem key={"Any"} value={"-1"}>
                Any
              </MenuItem>
              {Object.entries(OFFICES).map(([value, { title }]) => (
                <MenuItem key={title} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box ml={0.8}>
            <Input
              placeholder={"Advisor name"}
              value={filterForm.advisorName}
              onChange={createChangeFormForField("advisorName")}
            />
          </Box>
          <Box ml={0.8}>
            <Input
              placeholder={"Client name"}
              value={filterForm.name}
              onChange={createChangeFormForField("name")}
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default ClientSearch;
