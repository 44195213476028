import Joi from "joi";
import { passwordSchema, requiredString } from "../../../utils/schema";

export const changePasswordFormSchema = Joi.object({
  oldPassword: requiredString("Old Password", passwordSchema),
  newPassword: requiredString("New Password", passwordSchema),
  confirmPassword: requiredString("Confirm Password", passwordSchema)
    .valid(Joi.ref("newPassword"))
    .messages({
      "any.only": "New and confirm password doesn't match",
    }),
}).label("ChangePasswordFormSchema");
