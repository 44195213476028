import { FC, useCallback, useEffect, useState } from "react";
import { useGlobalState } from "../../state/global";
import { Auth } from "aws-amplify";
import { ACCESS_TOKEN_KEY, IS_TRUSTED_DEVICE } from "../../domain/frontend";
import httpClient from "../../utils/http-client";
import { CognitoUser } from "amazon-cognito-identity-js";
import MainLayout from "../../layouts/MainLayout";
import FullPageSpinner from "../FullPageSpinner";

interface Props {
  children?: any;
}

const SessionRehydrator: FC<Props> = ({ children }): JSX.Element => {
  const [, { setAuthenticatedUser, clearAuthenticatedUser }] = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);

  const storedAccessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
  if (storedAccessToken) {
    httpClient.setAccessToken(storedAccessToken);
  }

  const verifyIfDeviceTrusted = useCallback(async (user: CognitoUser) => {
    if (localStorage.getItem(IS_TRUSTED_DEVICE) === "true") {
      return;
    }

    user.getCachedDeviceKeyAndPassword();
    const deviceKey = (user as any).deviceKey;

    if ((await Auth.fetchDevices()).some((device) => device.id === deviceKey)) {
      localStorage.setItem(IS_TRUSTED_DEVICE, "true");
    }
  }, []);

  const detectExistingUserSession = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();

      const user = await Auth.currentAuthenticatedUser();
      await verifyIfDeviceTrusted(user);

      setAuthenticatedUser(user.attributes, accessToken);
    } catch (error) {
      clearAuthenticatedUser();
    } finally {
      setIsLoading(false);
    }
  }, [clearAuthenticatedUser, setAuthenticatedUser, verifyIfDeviceTrusted]);

  useEffect(() => {
    detectExistingUserSession();
  }, [detectExistingUserSession]);

  if (isLoading) {
    return (
      <>
        <FullPageSpinner isOpen={isLoading} />
        <MainLayout />
      </>
    );
  } else {
    return children;
  }
};

export default SessionRehydrator;
