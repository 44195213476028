import { deepFreeze } from "../../../utils/deep-freeze";
import { OfficeLocation } from "../../backend";
import { OfficeDetails } from "../models/office-details";

export const OFFICES: {
  [Location in OfficeLocation]: OfficeDetails;
} = deepFreeze({
  [OfficeLocation.ST_LOUIS]: {
    title: "St. Louis",
    address: {
      lineOne: "1401 South Brentwood Boulevard",
      lineTwo: "Suite 630",
      lineThree: "St. Louis, MO 63144",
    },
    email: "info@mosaicwealth.com",
    phone: "314.627.5220",
  },
  [OfficeLocation.DENVER]: {
    title: "Denver",
    address: {
      lineOne: "6400 S. Fiddler’s Green Circle",
      lineTwo: "Suite 1760",
      lineThree: "Greenwood Village, CO 80111",
    },
    email: "info@mosaicwealth.com",
    phone: "720.639.7200",
  },
});
