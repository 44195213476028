import { ActionTask } from "src/domain/backend";
import { deepFreeze } from "../../../utils/deep-freeze";

export const ACTION_TASKS: { [Type in ActionTask]: string } = deepFreeze({
  [ActionTask.GIVING_PLAN]: "Charitable Giving Plan",
  [ActionTask.TIME_WITH_LOVED]: "Plan for Time with Loved Ones",
  [ActionTask.RETIREMENT_ASSETS]: "Adequate Retirement Assets",
  [ActionTask.LIABILITY_REVIEW]: "Liability Review",
  [ActionTask.BENEFICIARY_REVIEW]: "Beneficiary Review",
  [ActionTask.SABBATICAL]: "Plan for Work Sabbatical",
  [ActionTask.BALANCE_SHEET]: "Updated Balance Sheet",
  [ActionTask.LIFE_INSURANCE_STATUS]: "Life Insurance Status",
  [ActionTask.FRAGMENTED_INVESTMENTS]: "Fragmented Investments",
  [ActionTask.EDUCATION_STRATEGY]: "Education Strategy",
  [ActionTask.TAX_GUIDANCE]: "Professional Tax Guidance",
  [ActionTask.EMERGENCY_FUND]: "Emergency Fund Status",
  [ActionTask.ANNUAL_BUDGET]: "Annual Budget Review",
  [ActionTask.FUND_LIFE]: "Strategy to Best Fund Financial Life",
  [ActionTask.LONG_TERM_CARE]: "Long-term Care Plan",
  [ActionTask.WILL_OR_TRUST]: "Will/Living Trust Status",
  [ActionTask.GUARANTEED_INCOME]: "Guarenteed Income Strategy",
  [ActionTask.ESTATE_PLAN]: "Estate Plan",
  [ActionTask.HEALTH_INSURANCE]: "Health Insurance Strategy",
  [ActionTask.DOCUMENT_STORAGE]: "Storage of legal/Financial Documents",
});
