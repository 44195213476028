import React, { FC } from "react";
import {
  Box,
  ButtonBase as MuiButtonBase,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DivButtonPrimaryLogoContainer } from "../styles";

import { ButtonProps } from "../types";
import { LoadingIcon } from "../../LoadingIcon";

interface Props extends ButtonProps {
  icon?: React.FC<{ sx: any }>;
}

const ButtonSpecial: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const Icon = props.isLoading ? LoadingIcon : props.icon;
  const { isDisabled, isLoading, ...omitProps } = props;

  return (
    <Box display={"flex"}>
      <MuiButtonBase
        sx={{
          height: isMobile ? "3.5rem" : "5rem",
          padding: isMobile ? "2.5rem 3.5rem" : "3rem 4rem",
          fontSize: isMobile ? "1.2rem" : "1.6rem",
          width: props.fullWidth ? "100%" : "auto",
          color: theme.palette.black,
          backgroundColor: theme.palette.white,
          fontFamily: theme.font.arialBlack,
          textTransform: "uppercase",
          letterSpacing: "0.2rem",
          transition: "all 200ms linear",
          "&:hover": {
            backgroundColor: theme.palette.gray,
            color: theme.palette.white,
          },
          "&:active,&:focus": {
            color: theme.palette.black,
            backgroundColor: theme.palette.white,
          },
        }}
        disabled={props.isDisabled}
        {...omitProps}
      >
        {props.children}
      </MuiButtonBase>
      {Icon && (
        <DivButtonPrimaryLogoContainer>
          <Icon sx={{ fontSize: "2.5em", color: theme.palette.black }} />
        </DivButtonPrimaryLogoContainer>
      )}
    </Box>
  );
};

export default ButtonSpecial;
