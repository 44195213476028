import { deepFreeze } from "../../../utils/deep-freeze";

export const ROUTES = deepFreeze({
  home: "/",
  error: "/error",
  dashboard: "/dashboard",
  adminDashboard: "/admin",
  adminUserDetails: "/admin/users/:userId",
  adminCreateUser: "/admin/create-user",
  registerClient: "/register/client",
  registerAdvisor: "/register/advisor",
  login: "/login",
  logout: "/logout",
  forgottenPasswordCode: "/forgotten-password",
  forgottenPasswordSubmit: "/forgotten-password/submit",
  verifyCode: "/verify-code",
  account: "/account",
  changePassword: "/account/change-password",
  dialogueStart: "/dialogue",
  dialogueDecks: "/dialogue/decks/",
  dialogueDeckCategory: "/dialogue/decks/:category",
  dialogueTop4: "/dialogue/top-four",
  dialoguePriorities: "/dialogue/priorities",
  dialogueSharedPriorities: "/dialogue/priorities/shared",
  dialogueThankYou: "/dialogue/thank-you",
  dialogueSummary: "/dialogue/:id/summary",
  dialogueTracker: "/dialogue/:id/tracker",
});
