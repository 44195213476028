import React, { FC } from "react";
import { useTheme } from "@material-ui/core";

import { ButtonProps } from "../types";
import ButtonBase from "..";

const ButtonTertiary: FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        color: theme.palette.gold,
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.gold}`,
        "&:hover": {
          color: theme.palette.white,
          border: `1px solid ${theme.palette.white}`,
        },
        "&:active,&:focus": {
          backgroundColor: "transparent",
          border: `1px solid ${theme.palette.gold}`,
        },
      }}
      {...props}
    >
      {props.children}
    </ButtonBase>
  );
};

export default ButtonTertiary;
