import React, { FC } from "react";
import { Box, Grid, GridSize } from "@material-ui/core";

interface Props {
  label?: string;
  rowSpan?: GridSize;
  children?: any;
}

const FieldSet: FC<Props> = ({ rowSpan, label, children }): JSX.Element => {
  const renderField = (field: JSX.Element, index: number = 0) => (
    <Grid item key={index} xs={rowSpan ?? 12}>
      <Box mr={rowSpan && index % 2 ? 2 : 0} mb={2}>
        {field}
      </Box>
    </Grid>
  );

  return (
    <Grid container>
      {Array.isArray(children)
        ? children.map((field, index) => renderField(field, ++index))
        : renderField(children)}
    </Grid>
  );
};

export default FieldSet;
