import * as React from "react";
import { useCallback, useMemo } from "react";
import { SvgStyledSliderButton } from "./styles";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import Text from "../../components/Text";
import { useCardSize } from "../../hooks/use-card-size";

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  isDisabled?: boolean;
}

function Clicker({ value: propValue, isDisabled, onChange }: Props) {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const sizing = useCardSize(isMdDown ? "medium" : "regular");

  const scale = useMemo(() => {
    return isMdDown ? 0.5 : 0.7;
  }, [isMdDown]);

  const value = useMemo(() => {
    return propValue ?? 0;
  }, [propValue]);

  const handleClick = useCallback(() => {
    if (isDisabled) {
      return;
    }

    const newValue = value + 1;

    onChange?.(newValue > 5 ? 0 : newValue);
  }, [isDisabled, onChange, value]);

  const label = useMemo(() => {
    switch (value) {
      case 5:
        return "Excellent";
      case 4:
        return "Above average";
      case 3:
        return "Average";
      case 2:
        return "Below average";
      case 1:
        return "Poor";
      default:
        return "";
    }
  }, [value]);

  return (
    <Box>
      <Box
        sx={{
          width: `${sizing.width}rem`,
          height: `${sizing.height * 0.65}rem`,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SvgStyledSliderButton
          id="prefix__Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={201.7}
          height={201.7}
          scale={scale}
          onClick={handleClick}
          isDisabled={isDisabled ?? false}
          isEditable={!!onChange}
        >
          <defs>
            <linearGradient
              id="prefix__linear-gradient"
              x1={41.198}
              y1={966.151}
              x2={186.504}
              y2={966.151}
              gradientTransform="matrix(1 0 0 -1 0 1080)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} />
              <stop offset={1} stopColor="#333" />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-2"
              x1={-157.796}
              y1={444.257}
              x2={-24.236}
              y2={444.257}
              gradientTransform="scale(1 -1) rotate(-37.642 -810.288 -144.07)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="gray" />
              <stop offset={1} />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-3"
              x1={-183.611}
              y1={444.257}
              x2={6.635}
              y2={444.257}
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-4"
              x1={64.585}
              y1={966.152}
              x2={172.763}
              y2={966.152}
              gradientTransform="matrix(1 0 0 -1 0 1080)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#4d4d4d" />
              <stop offset={1} stopColor="#1a1a1a" />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-5"
              x1={970.341}
              y1={550.884}
              x2={971.436}
              y2={563.404}
              gradientTransform="scale(1 -1) rotate(45 1287.285 -926.522)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-6"
              x1={642.181}
              y1={463.469}
              x2={642.693}
              y2={469.317}
              gradientTransform="scale(1 -1) rotate(22.47 1676.895 -1365.236)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-7"
              x1={922.63}
              y1={896.827}
              x2={935.2}
              y2={896.827}
              gradientTransform="scale(-1 1) rotate(-58.91 -314.04 1325.854)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-8"
              x1={207.877}
              y1={-42.167}
              x2={204.099}
              y2={-37.673}
              gradientTransform="scale(1 -1) rotate(-22.582 184.003 315.968)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-9"
              x1={201.505}
              y1={322.348}
              x2={202.6}
              y2={334.866}
              gradientTransform="scale(1 -1) rotate(16.606 1326.609 -190.702)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-10"
              x1={259.033}
              y1={-706.708}
              x2={253.183}
              y2={-706.208}
              gradientTransform="scale(1 -1) rotate(-67.641 706.551 -272.477)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-11"
              x1={342.659}
              y1={804.648}
              x2={343.755}
              y2={817.169}
              gradientTransform="scale(1 -1) rotate(44.83 1283.38 165.701)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-12"
              x1={765.5}
              y1={-1139.813}
              x2={761.014}
              y2={-1143.6}
              gradientTransform="scale(-1 1) rotate(67.302 -617.164 -1245.957)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-13"
              x1={449.294}
              y1={1968.509}
              x2={450.389}
              y2={1981.03}
              gradientTransform="scale(1 -1) rotate(45 2823.696 620.131)"
              xlinkHref="#prefix__linear-gradient-2"
            />
            <linearGradient
              id="prefix__linear-gradient-14"
              x1={211.694}
              y1={869.097}
              x2={15.396}
              y2={1063.809}
              gradientTransform="matrix(1 0 0 -1 -13 1067)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.037} stopColor="#333" />
              <stop offset={0.942} stopColor="#1a1a1a" />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-15"
              x1={92.98}
              y1={-196.909}
              x2={134.545}
              y2={-155.344}
              gradientTransform="translate(-13 277)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#f6e15f" />
              <stop offset={0.298} stopColor="#d5aa29" />
              <stop offset={0.714} stopColor="#825d24" />
              <stop offset={1} stopColor="#593c15" />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-16"
              x1={93.264}
              y1={985.535}
              x2={98.423}
              y2={982.556}
              gradientTransform="matrix(1 0 0 -1 0 1080)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} />
              <stop offset={1} stopColor="gray" />
            </linearGradient>
            <linearGradient
              id="prefix__linear-gradient-17"
              x1={96.575}
              y1={-200.505}
              x2={138.141}
              y2={-158.94}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-18"
              x1={100.455}
              y1={985.535}
              x2={105.615}
              y2={982.556}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-19"
              x1={100.17}
              y1={-204.1}
              x2={141.736}
              y2={-162.535}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-20"
              x1={107.645}
              y1={985.535}
              x2={112.804}
              y2={982.556}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-21"
              x1={103.766}
              y1={-207.696}
              x2={145.331}
              y2={-166.13}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-22"
              x1={114.836}
              y1={985.535}
              x2={119.996}
              y2={982.556}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-23"
              x1={107.361}
              y1={-211.291}
              x2={148.926}
              y2={-169.725}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-24"
              x1={122.024}
              y1={985.535}
              x2={127.183}
              y2={982.556}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-25"
              x1={110.956}
              y1={-214.885}
              x2={152.521}
              y2={-173.32}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-26"
              x1={129.216}
              y1={985.535}
              x2={134.375}
              y2={982.556}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-27"
              x1={89.385}
              y1={-193.315}
              x2={130.95}
              y2={-151.749}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-28"
              x1={93.264}
              y1={978.345}
              x2={98.423}
              y2={975.367}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-29"
              x1={92.981}
              y1={-196.91}
              x2={134.546}
              y2={-155.345}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-30"
              x1={100.455}
              y1={978.345}
              x2={105.615}
              y2={975.367}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-32"
              x1={107.645}
              y1={978.345}
              x2={112.804}
              y2={975.367}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-33"
              x1={100.171}
              y1={-204.101}
              x2={141.737}
              y2={-162.536}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-34"
              x1={114.836}
              y1={978.345}
              x2={119.996}
              y2={975.367}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-35"
              x1={103.766}
              y1={-207.696}
              x2={145.331}
              y2={-166.13}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-36"
              x1={122.024}
              y1={978.345}
              x2={127.183}
              y2={975.367}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-38"
              x1={129.216}
              y1={978.345}
              x2={134.375}
              y2={975.367}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-39"
              x1={85.79}
              y1={-189.72}
              x2={127.355}
              y2={-148.154}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-40"
              x1={93.264}
              y1={971.156}
              x2={98.423}
              y2={968.177}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-41"
              x1={89.386}
              y1={-193.316}
              x2={130.951}
              y2={-151.75}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-42"
              x1={100.455}
              y1={971.156}
              x2={105.615}
              y2={968.177}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-44"
              x1={107.645}
              y1={971.156}
              x2={112.804}
              y2={968.177}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-45"
              x1={96.576}
              y1={-200.506}
              x2={138.142}
              y2={-158.941}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-46"
              x1={114.836}
              y1={971.156}
              x2={119.996}
              y2={968.177}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-47"
              x1={100.171}
              y1={-204.101}
              x2={141.737}
              y2={-162.536}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-48"
              x1={122.024}
              y1={971.156}
              x2={127.183}
              y2={968.177}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-50"
              x1={129.216}
              y1={971.156}
              x2={134.375}
              y2={968.177}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-51"
              x1={82.195}
              y1={-186.125}
              x2={123.761}
              y2={-144.56}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-52"
              x1={93.264}
              y1={963.966}
              x2={98.423}
              y2={960.987}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-53"
              x1={85.791}
              y1={-189.721}
              x2={127.356}
              y2={-148.155}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-54"
              x1={100.455}
              y1={963.966}
              x2={105.615}
              y2={960.987}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-56"
              x1={107.645}
              y1={963.966}
              x2={112.804}
              y2={960.987}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-57"
              x1={92.981}
              y1={-196.911}
              x2={134.547}
              y2={-155.346}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-58"
              x1={114.836}
              y1={963.966}
              x2={119.996}
              y2={960.987}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-60"
              x1={122.024}
              y1={963.966}
              x2={127.183}
              y2={960.987}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-62"
              x1={129.216}
              y1={963.966}
              x2={134.375}
              y2={960.987}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-63"
              x1={78.599}
              y1={-182.529}
              x2={120.165}
              y2={-140.964}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-64"
              x1={93.264}
              y1={956.775}
              x2={98.423}
              y2={953.796}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-66"
              x1={100.455}
              y1={956.775}
              x2={105.615}
              y2={953.796}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-68"
              x1={107.645}
              y1={956.775}
              x2={112.804}
              y2={953.796}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-70"
              x1={114.836}
              y1={956.775}
              x2={119.996}
              y2={953.796}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-72"
              x1={122.024}
              y1={956.775}
              x2={127.183}
              y2={953.796}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-74"
              x1={129.216}
              y1={956.775}
              x2={134.375}
              y2={953.796}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-75"
              x1={75.005}
              y1={-178.934}
              x2={116.57}
              y2={-137.369}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-76"
              x1={93.264}
              y1={949.585}
              x2={98.423}
              y2={946.606}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-77"
              x1={78.6}
              y1={-182.53}
              x2={120.166}
              y2={-140.965}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <linearGradient
              id="prefix__linear-gradient-78"
              x1={100.455}
              y1={949.585}
              x2={105.615}
              y2={946.606}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-80"
              x1={107.645}
              y1={949.585}
              x2={112.804}
              y2={946.606}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-82"
              x1={114.836}
              y1={949.585}
              x2={119.996}
              y2={946.606}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-84"
              x1={122.024}
              y1={949.585}
              x2={127.183}
              y2={946.606}
              xlinkHref="#prefix__linear-gradient-16"
            />
            <linearGradient
              id="prefix__linear-gradient-85"
              x1={92.981}
              y1={-196.91}
              x2={134.546}
              y2={-155.345}
              xlinkHref="#prefix__linear-gradient-15"
            />
            <radialGradient
              id="prefix__radial-gradient"
              cx={179.014}
              cy={917.415}
              r={226.203}
              gradientTransform="matrix(1 0 0 -1 -13 1067)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#1a1a1a" />
              <stop offset={1} stopColor="#4d4d4d" />
            </radialGradient>
            <radialGradient
              id="prefix__radial-gradient-2"
              cx={171.897}
              cy={932.752}
              r={208.935}
              gradientTransform="matrix(1 0 0 -1 -13 1067)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} />
              <stop offset={1} stopColor="#666" />
            </radialGradient>
            <radialGradient
              id="prefix__radial-gradient-3"
              cx={79.652}
              cy={1007.507}
              r={133.362}
              gradientTransform="matrix(1 0 0 -1 0 1080)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#f6e15f" />
              <stop offset={0.298} stopColor="#d5aa29" />
              <stop offset={0.714} stopColor="#825d24" />
              <stop offset={0.939} stopColor="#593c15" />
            </radialGradient>
            <radialGradient
              id="prefix__radial-gradient-4"
              cx={520.504}
              cy={-855.284}
              r={20.924}
              gradientTransform="scale(-.766 .766) rotate(45 -940.977 -1012.71)"
              xlinkHref="#prefix__linear-gradient-15"
            />
            <radialGradient
              id="prefix__radial-gradient-5"
              cx={854.771}
              cy={-698.176}
              r={19.353}
              gradientTransform="matrix(-.5556 .5556 .5556 .5556 902.36 -47.025)"
              xlinkHref="#prefix__linear-gradient-15"
            />
            <radialGradient
              id="prefix__radial-gradient-6"
              cx={1194.537}
              cy={-853.041}
              r={19.846}
              gradientTransform="scale(-.766 .766) rotate(45 -520.866 -2017.497)"
              xlinkHref="#prefix__linear-gradient-15"
            />
            <radialGradient
              id="prefix__radial-gradient-7"
              cx={448}
              cy={-1021.24}
              r={16.02}
              gradientTransform="matrix(0 .95 .95 0 1131.923 -386.044)"
              xlinkHref="#prefix__linear-gradient-15"
            />
            <radialGradient
              id="prefix__radial-gradient-8"
              cx={1124.287}
              cy={-1274.07}
              r={16.447}
              gradientTransform="scale(-.925 .925) rotate(45 -1208.236 -2184.163)"
              xlinkHref="#prefix__linear-gradient-15"
            />
            <style>
              {
                ".prefix__cls-3{fill:#1a1a1a}.prefix__cls-20{fill:url(#prefix__linear-gradient-17)}.prefix__cls-26{fill:url(#prefix__linear-gradient-23)}.prefix__cls-32{fill:url(#prefix__linear-gradient-29)}.prefix__cls-37{fill:url(#prefix__linear-gradient-35)}.prefix__cls-40{fill:url(#prefix__linear-gradient-39)}.prefix__cls-42{fill:url(#prefix__linear-gradient-41)}.prefix__cls-45{fill:url(#prefix__linear-gradient-45)}.prefix__cls-47{fill:url(#prefix__linear-gradient-47)}.prefix__cls-50{fill:url(#prefix__linear-gradient-51)}.prefix__cls-52{fill:url(#prefix__linear-gradient-53)}.prefix__cls-79{opacity:.9}"
              }
            </style>
          </defs>
          <g id="prefix__Layer_1-2" data-name="Layer 1">
            <circle
              cx={100.85}
              cy={100.85}
              r={100.85}
              fill="url(#prefix__radial-gradient)"
            />
            <circle
              cx={100.85}
              cy={100.85}
              r={71.764}
              fill="url(#prefix__radial-gradient-2)"
            />
            <path
              className="prefix__cls-3"
              d="M70.228 170.405a71.203 71.203 0 1125.442 12.534 70.97 70.97 0 01-25.442-12.534zm86.85-112.6a70.55 70.55 0 1018.42 21.23 70.295 70.295 0 00-18.42-21.23z"
              transform="translate(-13 -13)"
            />
            <path
              className="prefix__cls-3"
              d="M69.857 170.885a71.809 71.809 0 1125.658 12.641 71.598 71.598 0 01-25.658-12.641zm87.59-113.557a71.156 71.156 0 1018.576 21.412 70.937 70.937 0 00-18.576-21.412z"
              transform="translate(-13 -13)"
            />
            <path
              d="M69.484 171.37a72.424 72.424 0 1125.876 12.75 72.188 72.188 0 01-25.876-12.75zm88.334-114.522a71.76 71.76 0 1018.733 21.594 71.52 71.52 0 00-18.733-21.594z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient)"
            />
            <path
              d="M70.63 169.886a70.555 70.555 0 1125.209 12.42 70.323 70.323 0 01-25.209-12.42zm86.052-111.567a69.9 69.9 0 1018.25 21.035 69.66 69.66 0 00-18.25-21.035z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-2)"
            />
            <path
              d="M52.286 193.669a100.77 100.77 0 11159.075-54.16 100.773 100.773 0 01-159.075 54.16zM174.86 34.75A99.895 99.895 0 1052.84 192.949 99.895 99.895 0 10174.86 34.751z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-3)"
            />
            <path
              d="M113.85 171.168a57.145 57.145 0 1122.311-4.505 56.965 56.965 0 01-22.311 4.505zm0-114.121a56.632 56.632 0 1022.11 4.464 56.449 56.449 0 00-22.11-4.464z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-4)"
            />
            <circle
              cx={14.448}
              cy={101.137}
              r={4.784}
              stroke="url(#prefix__linear-gradient-5)"
              strokeMiterlimit={10}
              strokeWidth={3}
              fill="#1a1a1a"
            />
            <circle
              cx={20.932}
              cy={68.012}
              r={1.435}
              stroke="url(#prefix__linear-gradient-6)"
              fill="#333"
              strokeMiterlimit={10}
              strokeWidth={3}
            />
            <circle
              cx={39.611}
              cy={39.898}
              r={4.784}
              stroke="url(#prefix__linear-gradient-7)"
              strokeMiterlimit={10}
              strokeWidth={3}
              fill="#1a1a1a"
            />
            <circle
              cx={67.638}
              cy={21.087}
              r={1.435}
              stroke="url(#prefix__linear-gradient-8)"
              fill="#333"
              strokeMiterlimit={10}
              strokeWidth={3}
            />
            <circle
              cx={100.542}
              cy={14.448}
              r={4.782}
              stroke="url(#prefix__linear-gradient-9)"
              strokeMiterlimit={10}
              strokeWidth={3}
              fill="#1a1a1a"
            />
            <circle
              cx={133.845}
              cy={20.997}
              r={1.435}
              stroke="url(#prefix__linear-gradient-10)"
              fill="#333"
              strokeMiterlimit={10}
              strokeWidth={3}
            />
            <circle
              cx={161.731}
              cy={39.54}
              r={4.784}
              stroke="url(#prefix__linear-gradient-11)"
              strokeMiterlimit={10}
              strokeWidth={3}
              fill="#1a1a1a"
            />
            <circle
              cx={180.678}
              cy={67.793}
              r={1.435}
              stroke="url(#prefix__linear-gradient-12)"
              fill="#333"
              strokeMiterlimit={10}
              strokeWidth={3}
            />
            <circle
              cx={187.252}
              cy={100.563}
              r={4.784}
              stroke="url(#prefix__linear-gradient-13)"
              strokeMiterlimit={10}
              strokeWidth={3}
              fill="#1a1a1a"
            />
          </g>
          <g id="prefix__Layer_3" data-name="Layer 3">
            <circle
              cx={100.85}
              cy={100.85}
              r={56.754}
              fill="url(#prefix__linear-gradient-14)"
            />
            <path
              fill="url(#prefix__linear-gradient-15)"
              d="M81.046 81.156h3.596v3.596h-3.596z"
            />
            <path
              d="M97.641 94.156v3.596h-3.596v-3.596h3.596m.383-.382h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-16)"
            />
            <path
              className="prefix__cls-20"
              d="M88.237 81.156h3.596v3.596h-3.596z"
            />
            <path
              d="M104.833 94.156v3.596h-3.596v-3.596h3.596m.383-.382h-4.362v4.361h4.362v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-18)"
            />
            <path
              fill="url(#prefix__linear-gradient-19)"
              d="M95.427 81.156h3.596v3.596h-3.596z"
            />
            <path
              d="M112.022 94.156v3.596h-3.595v-3.596h3.595m.383-.382h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-20)"
            />
            <path
              fill="url(#prefix__linear-gradient-21)"
              d="M102.618 81.156h3.596v3.596h-3.596z"
            />
            <path
              d="M119.214 94.156v3.596h-3.596v-3.596h3.596m.383-.382h-4.361v4.361h4.36v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-22)"
            />
            <path
              className="prefix__cls-26"
              d="M109.808 81.156h3.596v3.596h-3.596z"
            />
            <path
              d="M126.402 94.156v3.596h-3.596v-3.596h3.596m.382-.382h-4.36v4.361h4.36v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-24)"
            />
            <path
              fill="url(#prefix__linear-gradient-25)"
              d="M116.998 81.156h3.596v3.596h-3.596z"
            />
            <path
              d="M133.593 94.156v3.596h-3.595v-3.596h3.595m.383-.382h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-26)"
            />
            <path
              fill="url(#prefix__linear-gradient-27)"
              d="M81.046 88.346h3.596v3.596h-3.596z"
            />
            <path
              d="M97.641 101.346v3.596h-3.596v-3.596h3.596m.383-.383h-4.361v4.362h4.361v-4.362z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-28)"
            />
            <path
              className="prefix__cls-32"
              d="M88.237 88.346h3.596v3.596h-3.596z"
            />
            <path
              d="M104.833 101.346v3.596h-3.596v-3.596h3.596m.383-.383h-4.362v4.362h4.362v-4.362z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-30)"
            />
            <path
              className="prefix__cls-20"
              d="M95.427 88.346h3.596v3.596h-3.596z"
            />
            <path
              d="M112.022 101.346v3.596h-3.595v-3.596h3.595m.383-.383h-4.361v4.362h4.361v-4.362z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-32)"
            />
            <path
              fill="url(#prefix__linear-gradient-33)"
              d="M102.618 88.346h3.596v3.596h-3.596z"
            />
            <path
              d="M119.214 101.346v3.596h-3.596v-3.596h3.596m.383-.383h-4.361v4.362h4.36v-4.362z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-34)"
            />
            <path
              className="prefix__cls-37"
              d="M109.808 88.346h3.596v3.596h-3.596z"
            />
            <path
              d="M126.402 101.346v3.596h-3.596v-3.596h3.596m.382-.383h-4.36v4.362h4.36v-4.362z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-36)"
            />
            <path
              className="prefix__cls-26"
              d="M116.998 88.346h3.596v3.596h-3.596z"
            />
            <path
              d="M133.593 101.346v3.596h-3.595v-3.596h3.595m.383-.383h-4.361v4.362h4.361v-4.362z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-38)"
            />
            <path
              className="prefix__cls-40"
              d="M81.046 95.536h3.596v3.596h-3.596z"
            />
            <path
              d="M97.641 108.536v3.595h-3.596v-3.595h3.596m.383-.383h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-40)"
            />
            <path
              className="prefix__cls-42"
              d="M88.237 95.536h3.596v3.596h-3.596z"
            />
            <path
              d="M104.833 108.536v3.595h-3.596v-3.595h3.596m.383-.383h-4.362v4.361h4.362v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-42)"
            />
            <path
              className="prefix__cls-32"
              d="M95.427 95.536h3.596v3.596h-3.596z"
            />
            <path
              d="M112.022 108.536v3.595h-3.595v-3.595h3.595m.383-.383h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-44)"
            />
            <path
              className="prefix__cls-45"
              d="M102.618 95.536h3.596v3.596h-3.596z"
            />
            <path
              d="M119.214 108.536v3.595h-3.596v-3.595h3.596m.383-.383h-4.361v4.361h4.36v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-46)"
            />
            <path
              className="prefix__cls-47"
              d="M109.808 95.536h3.596v3.596h-3.596z"
            />
            <path
              d="M126.402 108.536v3.595h-3.596v-3.595h3.596m.382-.383h-4.36v4.361h4.36v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-48)"
            />
            <path
              className="prefix__cls-37"
              d="M116.998 95.536h3.596v3.596h-3.596z"
            />
            <path
              d="M133.593 108.536v3.595h-3.595v-3.595h3.595m.383-.383h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-50)"
            />
            <path
              className="prefix__cls-50"
              d="M81.046 102.725h3.596v3.596h-3.596z"
            />
            <path
              d="M97.641 115.725v3.596h-3.596v-3.596h3.596m.383-.382h-4.361v4.36h4.361v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-52)"
            />
            <path
              className="prefix__cls-52"
              d="M88.237 102.725h3.596v3.596h-3.596z"
            />
            <path
              d="M104.833 115.725v3.596h-3.596v-3.596h3.596m.383-.382h-4.362v4.36h4.362v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-54)"
            />
            <path
              className="prefix__cls-42"
              d="M95.427 102.725h3.596v3.596h-3.596z"
            />
            <path
              d="M112.022 115.725v3.596h-3.595v-3.596h3.595m.383-.382h-4.361v4.36h4.361v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-56)"
            />
            <path
              fill="url(#prefix__linear-gradient-57)"
              d="M102.618 102.725h3.596v3.596h-3.596z"
            />
            <path
              d="M119.214 115.725v3.596h-3.596v-3.596h3.596m.383-.382h-4.361v4.36h4.36v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-58)"
            />
            <path
              className="prefix__cls-45"
              d="M109.808 102.725h3.596v3.596h-3.596z"
            />
            <path
              d="M126.402 115.725v3.596h-3.596v-3.596h3.596m.382-.382h-4.36v4.36h4.36v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-60)"
            />
            <path
              className="prefix__cls-47"
              d="M116.998 102.725h3.596v3.596h-3.596z"
            />
            <path
              d="M133.593 115.725v3.596h-3.595v-3.596h3.595m.383-.382h-4.361v4.36h4.361v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-62)"
            />
            <path
              fill="url(#prefix__linear-gradient-63)"
              d="M81.046 109.917h3.596v3.596h-3.596z"
            />
            <path
              d="M97.641 122.917v3.596h-3.596v-3.596h3.596m.383-.383h-4.361v4.361h4.361v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-64)"
            />
            <path
              className="prefix__cls-50"
              d="M88.237 109.917h3.596v3.596h-3.596z"
            />
            <path
              d="M104.833 122.917v3.596h-3.596v-3.596h3.596m.383-.383h-4.362v4.361h4.362v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-66)"
            />
            <path
              className="prefix__cls-40"
              d="M95.427 109.917h3.596v3.596h-3.596z"
            />
            <path
              d="M112.022 122.917v3.596h-3.595v-3.596h3.595m.383-.383h-4.361v4.361h4.361v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-68)"
            />
            <path
              className="prefix__cls-42"
              d="M102.618 109.917h3.596v3.596h-3.596z"
            />
            <path
              d="M119.214 122.917v3.596h-3.596v-3.596h3.596m.383-.383h-4.361v4.361h4.36v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-70)"
            />
            <path
              className="prefix__cls-32"
              d="M109.808 109.917h3.596v3.596h-3.596z"
            />
            <path
              d="M126.402 122.917v3.596h-3.596v-3.596h3.596m.382-.383h-4.36v4.361h4.36v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-72)"
            />
            <path
              className="prefix__cls-20"
              d="M116.998 109.917h3.596v3.596h-3.596z"
            />
            <path
              d="M133.593 122.917v3.596h-3.595v-3.596h3.595m.383-.383h-4.361v4.361h4.361v-4.36z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-74)"
            />
            <path
              fill="url(#prefix__linear-gradient-75)"
              d="M81.046 117.107h3.596v3.596h-3.596z"
            />
            <path
              d="M97.641 130.107v3.595h-3.596v-3.595h3.596m.383-.383h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-76)"
            />
            <path
              fill="url(#prefix__linear-gradient-77)"
              d="M88.237 117.107h3.596v3.596h-3.596z"
            />
            <path
              d="M104.833 130.107v3.595h-3.596v-3.595h3.596m.383-.383h-4.362v4.361h4.362v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-78)"
            />
            <path
              className="prefix__cls-50"
              d="M95.427 117.107h3.596v3.596h-3.596z"
            />
            <path
              d="M112.022 130.107v3.595h-3.595v-3.595h3.595m.383-.383h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-80)"
            />
            <path
              className="prefix__cls-52"
              d="M102.618 117.107h3.596v3.596h-3.596z"
            />
            <path
              d="M119.214 130.107v3.595h-3.596v-3.595h3.596m.383-.383h-4.361v4.361h4.36v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-82)"
            />
            <path
              className="prefix__cls-42"
              d="M109.808 117.107h3.596v3.596h-3.596z"
            />
            <path
              d="M126.402 130.107v3.595h-3.596v-3.595h3.596m.382-.383h-4.36v4.361h4.36v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-84)"
            />
            <path
              fill="url(#prefix__linear-gradient-85)"
              d="M116.998 117.107h3.596v3.596h-3.596z"
            />
            <path
              d="M133.593 130.107v3.595h-3.595v-3.595h3.595m.383-.383h-4.361v4.361h4.361v-4.361z"
              transform="translate(-13 -13)"
              fill="url(#prefix__linear-gradient-86)"
            />
          </g>
          <g id="prefix__Layer_9" data-name="Layer 9" fillRule="evenodd">
            <path
              d="M114.365 57.994A56.516 56.516 0 1174.4 74.547a56.35 56.35 0 0139.965-16.553zm39.461 17.056a55.81 55.81 0 1016.347 39.464 55.922 55.922 0 00-16.347-39.463z"
              transform="translate(-13 -13)"
              fill="#333"
            />
            <path
              d="M113.695 57.326A56.516 56.516 0 1173.73 73.88a56.35 56.35 0 0139.965-16.553zm39.464 17.055a55.812 55.812 0 10-39.464 95.608 55.813 55.813 0 0039.464-95.608z"
              transform="translate(-13 -13)"
              fill="#1a1a1a"
            />
            <path
              d="M114.03 57.661a56.516 56.516 0 11-39.965 16.553 56.35 56.35 0 0139.965-16.553zm39.464 17.055a55.81 55.81 0 1016.346 39.463 55.628 55.628 0 00-16.347-39.463z"
              transform="translate(-13 -13)"
              fill="url(#prefix__radial-gradient-3)"
            />
          </g>
          {value >= 1 && (
            <g id="prefix___1" data-name={1}>
              <circle
                cx={14.448}
                cy={101.137}
                r={4.665}
                fill="url(#prefix__radial-gradient-4)"
              />
            </g>
          )}
          {value >= 2 && (
            <g id="prefix___2" data-name={2}>
              <circle
                cx={39.536}
                cy={39.973}
                r={4.424}
                fill="url(#prefix__radial-gradient-5)"
              />
            </g>
          )}
          {value >= 3 && (
            <g id="prefix___3" data-name={3}>
              <circle
                cx={100.569}
                cy={14.448}
                r={4.424}
                fill="url(#prefix__radial-gradient-6)"
              />
            </g>
          )}
          {value >= 4 && (
            <g id="prefix___4" data-name={4}>
              <circle
                cx={161.745}
                cy={39.555}
                r={4.424}
                fill="url(#prefix__radial-gradient-7)"
              />
            </g>
          )}
          {value >= 5 && (
            <g id="prefix___5" data-name={5}>
              <circle
                cx={187.252}
                cy={100.563}
                r={4.424}
                fill="url(#prefix__radial-gradient-8)"
              />
            </g>
          )}
        </SvgStyledSliderButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          bottom: "2rem",
          height: "2.5rem",
          userSelect: "none",
        }}
      >
        <Text font={theme.font.arialBlack}>{label}</Text>
      </Box>
    </Box>
  );
}

export default Clicker;
