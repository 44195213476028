import * as H from "history";
import { ROUTES } from "../domain/frontend";
import { HTTPError } from "ky";

export function handleHttpError(error: Error, history: H.History): void {
  if (error instanceof HTTPError && error.response.status === 401) {
    history.push(
      `${ROUTES.error}?cause=sessionTimeout&redirect=${encodeURIComponent(
        window.location.href
      )}`
    );
  } else {
    history.push(ROUTES.error);
  }
}
