import Joi from "joi";

export const phoneUsRegEx = new RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);

export const linkedUserSchema = Joi.object({
  id: Joi.string().uuid().required(),
  name: Joi.string().min(1).max(200).required(),
}).unknown(true);

export const phoneSchema = Joi.string()
  .allow("+61411321130")
  .pattern(phoneUsRegEx)
  .messages({
    "string.pattern.base":
      "Phone number must be a valid US cell phone number e.g. (222)-333-4444.",
  });

export const passwordSchema = Joi.string()
  .pattern(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z.,/@#!$%^&*;:{}=\-_`~()\d]{10,}$/
  )
  .messages({
    "string.pattern.base":
      "Password must be at least 10 characters long, contain both upper and lower case and at least one number",
  });

export function requiredString(
  label: string,
  schema: Joi.StringSchema
): Joi.AnySchema {
  const reqMessage = `Please enter a value for ${label}`;

  return schema
    .required()
    .label(label)
    .messages({
      "any.required": reqMessage,
      "string.empty": reqMessage,
      "string.email": `${label} must be a valid email address`,
    });
}
