import { AutoCompleteOption } from "../../../components/AutoComplete/types";
import httpClient from "../../../utils/http-client";
import config from "../../../config";
import {
  ClientRegistrationForm,
  QueryAdvisorsByNameResponse,
  QueryClientsByNameResponse,
} from "./types";
import { Client } from "../../../domain/backend";
import { defaultErrorMessage } from "../../../domain/frontend";

export async function registerClient({
  name,
  linkedClient,
  location,
  advisor,
  email,
  phone,
  office,
}: ClientRegistrationForm): Promise<void> {
  const client: Omit<Client, "id"> = {
    name,
    email,
    phone,
    location,
    office,
    advisorName: advisor?.value.name,
    advisorId: advisor?.value.id,
    linkedClientName: linkedClient?.value?.name,
    linkedClientId: linkedClient?.value?.id,
  };

  await httpClient.fetch(`${config.talksig.apiUrl}/clients/`, {
    method: "POST",
    json: client,
  });
}

export async function fetchAdvisorComboOptions(
  searchValue: string
): Promise<AutoCompleteOption[]> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/advisors/query`,
    {
      method: "POST",
      json: { name: searchValue },
    }
  );

  const { advisors }: QueryAdvisorsByNameResponse = await response.json();

  return advisors.map((advisor) => ({
    title: advisor.name,
    value: advisor,
  }));
}

export async function fetchLinkedClientComboOptions(
  searchValue: string
): Promise<AutoCompleteOption[]> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/clients/query`,
    {
      method: "POST",
      json: { name: searchValue },
    }
  );

  const { clients }: QueryClientsByNameResponse = await response.json();

  return clients.map((client) => ({
    title: client.name,
    value: client,
  }));
}

export function mapErrorMessage(statusCode: number): string {
  switch (statusCode) {
    case 409:
      return "A user with that email address already exists.";
    default:
      return defaultErrorMessage;
  }
}
