import * as React from "react";
import { TableBodyStyled } from "./styled";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const TableBody: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <TableBodyStyled>
      {Array.isArray(props.children)
        ? props.children.map((child) => child)
        : props.children}
    </TableBodyStyled>
  );
};

export default TableBody;
