import React from "react";
import Text from "../../Text";
import { Box } from "@material-ui/core";
import { ContactSectionModel } from "../types";

interface Props {
  site: ContactSectionModel;
}

const Contact: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <Text font={"Arial Black"} size={1.3} mb={3}>
        {props.site.city}
      </Text>
      <Text lineHeight={1.5}>
        {props.site.address.lineOne}
        <br />
        {props.site.address.lineTwo}
        <br />
        {props.site.address.lineThree}
        <Box mt={3}>
          Mail: {props.site.email}
          <br />
          Tel: {props.site.phone}
        </Box>
      </Text>
    </>
  );
};

export default Contact;
