import config from "../../../../config";
import {
  ChangeUserStatusRequest,
  DeleteUserRequest,
  GetUserDetailsRequest,
  GetUserDetailsResponse,
  ResendUserInviteRequest,
  SetUserGroupsRequest,
} from "./types";
import httpClient from "../../../../utils/http-client";

export async function setUserGroups(
  request: SetUserGroupsRequest
): Promise<void> {
  await httpClient.fetch(`${config.talksig.apiUrl}/admin/users/groups`, {
    method: "PUT",
    json: request,
  });
}

export async function changeUserStatus(
  request: ChangeUserStatusRequest
): Promise<void> {
  await httpClient.fetch(`${config.talksig.apiUrl}/admin/users/status`, {
    method: "PUT",
    json: request,
  });
}

export async function deleteUser(request: DeleteUserRequest): Promise<void> {
  await httpClient.fetch(`${config.talksig.apiUrl}/admin/users`, {
    method: "DELETE",
    json: request,
  });
}

export async function resendUserInvite(
  request: ResendUserInviteRequest
): Promise<void> {
  await httpClient.fetch(`${config.talksig.apiUrl}/admin/users/invite`, {
    method: "PUT",
    json: request,
  });
}

export async function getUserDetails(
  request: GetUserDetailsRequest
): Promise<GetUserDetailsResponse> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/admin/users/details/retrieve`,
    {
      method: "POST",
      json: request,
    }
  );

  return response.json();
}
