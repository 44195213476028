import Joi from "joi";
import { OfficeLocation } from "../../../domain/backend";
import {
  passwordSchema,
  phoneSchema,
  requiredString,
} from "../../../utils/schema";

export const confirmSignUpFormSchema = Joi.object({
  name: requiredString("Name", Joi.string().min(1).max(200)),
  email: requiredString(
    "Email",
    Joi.string().email({ tlds: false }).min(1).max(200)
  ),
  phone: phoneSchema.label("Phone").required().messages({
    "any.required": "Please enter a value for Phone.",
    "string.empty": "Please enter a value for Phone.",
  }),
  officeLocation: Joi.number()
    .label("Office location")
    .valid(...Object.values(OfficeLocation))
    .required()
    .messages({
      "any.required": "Please select an Office Location.",
    }),
  newPassword: passwordSchema.label("New Password").required().messages({
    "any.required": "Please enter a value for New Password.",
    "string.empty": "Please enter a value for New Password.",
  }),
});
