import styled from "styled-components/macro";

export const AnchorNoStyle = styled.a`
  cursor: pointer;
  text-decoration-skip-ink: inherit;
  color: inherit;
  text-decoration: inherit;
  outline: 0;
  &:active,
  &:focus {
    outline: none;
  }
`;

export const AnchorStyled = styled.a`
  cursor: pointer;
  text-decoration-skip-ink: inherit;
  text-decoration: underline;
  outline: 0;
  color: var(--gold);
  font-family: var(--fontArial);
  font-size: 1.5rem;
  line-height: 1.5;
  z-index: 10;

  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    color: var(--white);
  }
`;
