import { DeckCategory } from "../../backend";
import { DndCardGroup } from "../enums/dnd-card-group";
import { DndDeck } from "../models/dnd-deck";
import { ROUTES } from "./routes";
import { deepFreeze } from "../../../utils/deep-freeze";
import { createDndCard } from "../../../utils/create-dnd-card";

export const FINANCIAL_DECK: DndDeck<DeckCategory.FINANCIAL> = deepFreeze({
  id: "deck-fin",
  title: "Financial",
  description: "What are your financial priorities?",
  category: DeckCategory.FINANCIAL,
  href: ROUTES.dialogueDeckCategory.replace(":category", "financial"),
  cards: [
    {
      id: "fin-0",
      index: 0,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.FINANCIAL,
        title: "Optimize Tax Planning",
        priority: 0,
      },
    },
    {
      id: "fin-1",
      index: 1,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.FINANCIAL,
        title: "Ensure Family Security If I'm Gone",
        priority: 0,
      },
    },
    {
      id: "fin-2",
      index: 2,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.FINANCIAL,
        title: "Achieve Financial Freedom",
        priority: 0,
      },
    },
    {
      id: "fin-3",
      index: 3,
      group: DndCardGroup.UNASSIGNED,
      item: {
        category: DeckCategory.FINANCIAL,
        title: "Protect Income and Lifestyle",
        priority: 0,
      },
    },
  ],
  top4: [0, 1, 2, 3].map((num) => createDndCard(num, DndCardGroup.TOP4)),
});
