import Joi from "joi";
import { passwordSchema, requiredString } from "../../../utils/schema";

export const forgotPassSubmitFormSchema = Joi.object({
  code: Joi.string().min(6).max(6).required().label("Code").messages({
    "any.required": "Please enter Code",
    "string.empty": "Please enter Code",
    "string.min": "Please enter a valid 6-digit code",
    "string.max": "Please enter a valid 6-digit code",
  }),
  newPassword: requiredString("New Password", passwordSchema),
  confirmPassword: requiredString("Confirm Password", passwordSchema)
    .valid(Joi.ref("newPassword"))
    .messages({
      "any.only": "New and confirm password doesn't match",
    }),
});
