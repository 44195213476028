import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import Table from "src/components/Table";
import TableHead from "src/components/TableHead";
import TableRow from "src/components/TableRow";
import TableBody from "src/components/TableBody";
import TableData from "src/components/TableData";
import TableHeader from "../../../../components/TableHeader";
import { getUserList } from "./utils";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../domain/frontend";
import { AdminUser } from "./types";
import { OFFICES } from "../../../../domain/frontend";
import { defaultErrorMessage } from "../../../../domain/frontend";
import { Box, Grid, useTheme } from "@material-ui/core";
import Input from "../../../../components/Input";
import Text from "../../../../components/Text";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import Anchor from "src/components/Anchor";

interface Props {}

const AdminDashboard: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const viewUserDetails = useCallback(
    (user: AdminUser) => {
      history.push({
        pathname: ROUTES.adminUserDetails.replace(":userId", user.id),
        state: { user },
      });
    },
    [history]
  );

  const filteredUsers = useMemo(() => {
    return nameFilter
      ? users.filter((user) =>
          user.name?.toLowerCase().includes(nameFilter.toLowerCase())
        )
      : users;
  }, [nameFilter, users]);

  const changeNameFilter = useCallback((event: any) => {
    setNameFilter(event.target.value);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getUserList()
      .then((list) => {
        setUsers(list.users);
      })
      .catch(() => setErrorMessage(defaultErrorMessage))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <AdminLayout
      title={"Mosaic Users"}
      errorMessage={errorMessage}
      isLoading={isLoading}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Text color={theme.palette.gold} size={2} mr={2}>
              Filter results
            </Text>
            <Input
              width={"30rem"}
              placeholder={"Enter name"}
              onChange={changeNameFilter}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box my={5}>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone</TableHead>
                  <TableHead>Office</TableHead>
                  <TableHead>Enabled</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow
                    key={user.id}
                    onClick={() => viewUserDetails(user)}
                    canSelect
                  >
                    <TableData>{user.name}</TableData>
                    <TableData>{user.email}</TableData>
                    <TableData>{user.phone}</TableData>
                    <TableData>{OFFICES[user.office]?.title}</TableData>
                    <TableData>{user.enabled ? "Yes" : "No"}</TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Anchor href={`/#${ROUTES.adminCreateUser}`} noStyle>
              <ButtonPrimary>Create User</ButtonPrimary>
            </Anchor>
          </Box>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default AdminDashboard;
