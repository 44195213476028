import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useState } from "react";
import TalkSigTextLogo from "../../../components/TalkSigTextLogo";
import HeadingTitle from "../../../components/Heading/HeadingTitle";
import HeadingSubtitle from "../../../components/Heading/HeadingSubtitle";
import ActionStatusIndicator from "../../../components/ActionStatusIndicator";
import TrackerActionGridItem from "./parts/TrackerActionGridItem";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ButtonTertiary from "../../../components/Button/ButtonTertiary";
import {
  ACTION_STATUSES,
  defaultErrorMessage,
  ROUTES,
} from "../../../domain/frontend";
import {
  ActionStatus,
  ActionTask,
  TrackerAction,
} from "../../../domain/backend";
import { useHistory, useParams } from "react-router-dom";
import { getDialogue } from "../../../utils/get-dialogue";
import FullPageSpinner from "../../../components/FullPageSpinner";
import Text from "../../../components/Text";
import Form from "../../../components/Form";
import { generateSignificanceTracker, setDialogueActions } from "./utils";
import MainLayout from "../../../layouts/MainLayout";
import IconLink from "../../../components/IconLink";
import AddIcon from "@material-ui/icons/Add";
import { isSharedDialogue } from "../../../utils/is-dialogue";
import { getLastName } from "../../../utils/name-parsing";
import { handleHttpError } from "../../../utils/handle-http-error";
import {
  Dialogue,
  SharedDialogue,
} from "../../../domain/backend/models/dialogue";

interface Props {}

const DialogueTracker: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { id: dialogueId } = useParams<{ id: string }>();
  const [dialogue, setDialogue] = useState<Dialogue | SharedDialogue>();
  const [tasks, setTasks] = useState<TrackerAction[]>();
  const [clientName, setClientName] = useState("");
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const createAction = useCallback(() => {
    setTasks((prevState) => {
      if (!prevState) {
        prevState = [];
      }

      prevState?.push({
        id: `new-task-${prevState.length + 1}`,
        type: ActionTask.ANNUAL_BUDGET,
        status: ActionStatus.IMMEDIATE,
        checkInDate: null,
        description: "",
      });

      return Array.from(prevState);
    });
  }, []);

  const save = useCallback(
    async (shouldRedirect = true) => {
      if (!tasks) {
        return;
      }

      setIsSubmitting(true);
      setFormError("");

      try {
        await setDialogueActions(dialogueId, tasks);

        if (shouldRedirect) {
          history.push(ROUTES.dashboard);
        }
      } catch (error) {
        setFormError(defaultErrorMessage);
      } finally {
        setIsSubmitting(false);
      }
    },
    [tasks, dialogueId, history]
  );

  const print = useCallback(async () => {
    if (!tasks || !dialogue) {
      return;
    }

    try {
      setIsGeneratingPdf(true);

      const [, url] = await Promise.all([
        save(false),
        generateSignificanceTracker({
          ...dialogue,
          actions: tasks,
        }),
      ]);

      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = URL.createObjectURL(url);
      }
    } catch (error) {
      setFormError(defaultErrorMessage);
    } finally {
      setIsGeneratingPdf(false);
    }
  }, [dialogue, save, tasks]);

  useEffect(() => {
    setIsLoading(true);

    getDialogue(dialogueId)
      .then((dialogue) => {
        setDialogue(dialogue);
        setTasks(dialogue.actions);
        setClientName(
          isSharedDialogue(dialogue)
            ? `${getLastName(dialogue.dialogueOne.client.name)} & ${getLastName(
                dialogue.dialogueTwo.client.name
              )}`
            : dialogue.client.name
        );
      })
      .catch((error) => handleHttpError(error, history))
      .finally(() => setIsLoading(false));
  }, [dialogueId, history]);

  return (
    <>
      <FullPageSpinner isOpen={isLoading} />
      <MainLayout isBgTiled>
        <Form
          onSubmit={save}
          error={formError}
          isLoading={isSubmitting}
          justifyButtons={"flex-end"}
          buttons={[
            { type: "submit", text: "Save", component: ButtonPrimary },
            {
              type: "button",
              text: "Print",
              component: ButtonTertiary,
              isLoading: isGeneratingPdf,
              onClick: print,
            },
          ]}
        >
          <Grid container>
            <Grid item container mb={isMdDown ? 5 : 0}>
              <Grid item xs={12} md={6}>
                <Box mb={3}>
                  <Grid item>
                    <Box
                      mb={2.5}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <TalkSigTextLogo />
                    </Box>
                  </Grid>
                  <Grid item>
                    <HeadingTitle fontSize={2.4} width={19.3}>
                      Significance Journey Tracker
                    </HeadingTitle>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <HeadingSubtitle>{clientName}</HeadingSubtitle>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                my={3}
                mx={isMdDown ? 2 : 10}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {Object.values(ACTION_STATUSES).map(({ color, title }) => (
                  <ActionStatusIndicator
                    key={title}
                    title={title}
                    color={color}
                  />
                ))}
              </Box>
            </Grid>
            {!isLoading && !tasks?.[0] && (
              <Grid item xs={12}>
                <Text size={2} my={5} textAlign={"center"}>
                  No tasks found.
                </Text>
              </Grid>
            )}
            {isLoading && (
              <Grid item xs={12}>
                <Text size={2} my={5} textAlign={"center"}>
                  Loading dialogue...
                </Text>
              </Grid>
            )}
            {!isLoading &&
              tasks?.map((action) => (
                <TrackerActionGridItem
                  key={action.id}
                  action={action}
                  setActions={setTasks}
                />
              ))}
          </Grid>
          {!isLoading && (
            <Grid item mt={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconLink
                  icon={AddIcon}
                  iconPosition={"end"}
                  fontSize={2}
                  onClick={createAction}
                >
                  Create New Task
                </IconLink>
              </Box>
            </Grid>
          )}
        </Form>
      </MainLayout>
    </>
  );
};

export default DialogueTracker;
