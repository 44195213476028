import styled from "styled-components/macro";
import { InputBase } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export const SelectInput = styled(InputBase)(({ theme }) => {
  return {
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.white,
      fontFamily: theme.font.arial,
      fontSize: "1.4rem",
      color: theme.palette.black,
      height: "4.4rem",
      borderRadius: "4px",
      padding: "10px 26px 10px 12px",
    },
  };
});

export const SelectIcon = styled(KeyboardArrowDownIcon)`
  font-size: 24px;
`;
