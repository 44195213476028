import httpClient from "../../../utils/http-client";
import config from "../../../config";
import {
  ClientFilterForm,
  ClientsSummaryResponse,
  ClientSummaryRequest,
} from "./types";

export async function getClientSummaries({
  name,
  advisorName,
  office,
}: ClientFilterForm): Promise<ClientsSummaryResponse> {
  const request: ClientSummaryRequest = {
    name,
    advisorName,
  };

  if (office && office !== "-1") {
    request.office = Number(office);
  }

  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/clients/summary`,
    {
      method: "POST",
      json: request,
    }
  );

  return response.json();
}
