import React, { FC } from "react";
import { CheckboxProps, FormControl } from "@material-ui/core";
import {
  SpanCheckboxFull,
  SpanCheckboxEmpty,
  StyledCheckbox,
  StyledFormControlLabel,
} from "./styles";

interface Props extends CheckboxProps {
  children?: any;
  field?: any;
}

const Checkbox: FC<Props> = (props: Props): JSX.Element => {
  return (
    <FormControl>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            {...{ ...props, field: undefined }}
            {...props.field}
            icon={<SpanCheckboxEmpty />}
            checkedIcon={<SpanCheckboxFull />}
          />
        }
        label={props.children ?? ""}
      />
    </FormControl>
  );
};

export default Checkbox;
