import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import { Box, Grid } from "@material-ui/core";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import Heading from "./parts/heading";
import ClientSearch from "./parts/client-search";
import ClientCard from "./parts/client-card";
import { DivDashboard, DivDashboardPageContainer } from "./styles";
import { getClientSummaries } from "./utils";
import { useGlobalState } from "../../../state/global";
import { ROUTES } from "../../../domain/frontend";
import Anchor from "../../../components/Anchor";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { ClientFilterForm, ClientSummary } from "./types";
import { useHistory } from "react-router-dom";
import Text from "../../../components/Text";
import debounce from "lodash.debounce";
import { handleHttpError } from "../../../utils/handle-http-error";

interface Props {}

const AdvisorDashboard: FC<Props> = (props: Props): JSX.Element => {
  const history = useHistory();
  const [{ user }] = useGlobalState();
  const [clients, setClients] = useState<ClientSummary[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [haveLoaded, setHaveLoaded] = useState(false);
  const [filterForm, setFilterForm] = useState<ClientFilterForm>({
    name: "",
    advisorName: "",
    office: "-1",
  });

  const fetchSummaries = useCallback(
    async (form: ClientFilterForm) => {
      setIsLoading(true);

      await getClientSummaries(form)
        .then((response) => setClients(response.clients))
        .catch((error) => handleHttpError(error, history))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [history]
  );

  const throttledFetchClientSummaries = useMemo(
    () =>
      debounce(
        async (form: ClientFilterForm) => await fetchSummaries(form),
        1000
      ),
    [fetchSummaries]
  );

  const changeFilterForm = useCallback((field: string, value: any) => {
    setFilterForm((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  }, []);

  useEffect(() => {
    if (haveLoaded && !isLoading) {
      throttledFetchClientSummaries(filterForm);
    }
  }, [filterForm.advisorName, filterForm.name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (haveLoaded && !isLoading) {
      fetchSummaries(filterForm);
    }
  }, [filterForm.office]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchSummaries(filterForm).then(() => setHaveLoaded(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FullPageSpinner isOpen={isLoading} />
      <MainLayout>
        <DivDashboard>
          <DivDashboardPageContainer>
            <Grid container justifyContent={"center"}>
              <Grid
                item
                container
                direction={"column"}
                xs={12}
                md={5}
                justifyContent={"flex-end"}
              >
                <Box mb={3}>
                  <Heading />
                </Box>
              </Grid>
              <Grid item container direction={"column"} xs={12} md={7}>
                <Box mb={3}>
                  <ClientSearch
                    advisor={user!}
                    filterForm={filterForm}
                    changeFilterForm={changeFilterForm}
                  />
                </Box>
              </Grid>
              {!haveLoaded || isLoading ? (
                <Text size={2}>Loading clients...</Text>
              ) : (
                clients.map((summary) => (
                  <ClientCard key={summary.client.id} clientSummary={summary} />
                ))
              )}
              <Grid item xs={12} mt={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!isLoading && (
                    <Anchor href={`/#${ROUTES.registerClient}`} noStyle>
                      <ButtonPrimary>Add new client</ButtonPrimary>
                    </Anchor>
                  )}
                </Box>
              </Grid>
            </Grid>
          </DivDashboardPageContainer>
        </DivDashboard>
      </MainLayout>
    </>
  );
};

export default AdvisorDashboard;
