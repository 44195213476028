import { Box, Grid, useTheme } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import Text from "../../../components/Text";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import useCurrentDialogue from "../../../state/dialogue/selectors/use-current-dialogue";
import { isFormValid } from "./utils";
import { defaultErrorMessage, DndCard, ROUTES } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";
import { setPriorityForCardInDeck } from "../../../utils/deck-operations";
import { useHistory } from "react-router-dom";
import Clicker from "../../../components/Clicker";
import DialogueLayout from "../../../layouts/DialogueLayout";
import CardDisplay from "../../../components/CardDisplay";
import { useDialogueState } from "../../../state/dialogue";
import { submitDialogue } from "../../../utils/submit-dialogue";

interface Props {}

const DialoguePriorities: FC<Props> = (): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const [state] = useDialogueState();
  const [{ overallTop4, client }, { savePriorities }] = useCurrentDialogue();
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [overallTop4Cards, setOverallTop4Cards] = useState(
    Array.from(overallTop4)
  );

  const createSetCardPriority = useCallback(
    (card: DndCard<DeckCategory>) => {
      return (value: number) =>
        setOverallTop4Cards(
          setPriorityForCardInDeck(card.id, value, overallTop4Cards)
        );
    },
    [overallTop4Cards]
  );

  const finishDialogue = useCallback(async () => {
    const { isValid, errorMessage } = isFormValid(overallTop4Cards);

    setFormError(errorMessage);

    if (!isValid) {
      return;
    }

    savePriorities(overallTop4Cards);

    try {
      setIsLoading(true);

      await submitDialogue(state.dialogues, state.topFourSharedCards);

      history.push(ROUTES.dialogueThankYou);
    } catch (error) {
      setFormError(defaultErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [history, overallTop4Cards, savePriorities, state]);

  return (
    <DialogueLayout
      title={"Top Priorities"}
      subTitle={client.name}
      description={
        <Text
          font={theme.font.arialBlack}
          size={1.3}
          my={8}
          textAlign={"center"}
        >
          Using the slider below each card, let’s determine how well you think
          you are doing with the top priorities you selected.
        </Text>
      }
    >
      <Grid item container justifyContent={"center"} spacing={3}>
        {overallTop4Cards.map((card) => (
          <Grid key={card.id} item>
            <CardDisplay card={card.item} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Clicker
                value={card.item?.priority}
                onChange={createSetCardPriority(card)}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        justifyContent={"center"}
        columnSpacing={10}
        rowSpacing={5}
        mt={1}
      >
        <Grid item xs={12}>
          {formError && (
            <Text
              size={1.8}
              color={theme.palette.formError}
              textAlign={"center"}
            >
              {formError}
            </Text>
          )}
        </Grid>
        <Grid item>
          <ButtonPrimary
            size={"large"}
            onClick={finishDialogue}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Finish Dialogue
          </ButtonPrimary>
        </Grid>
      </Grid>
    </DialogueLayout>
  );
};

export default DialoguePriorities;
