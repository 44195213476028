import { createStore, createHook } from "react-sweet-state";
import { GlobalState } from "./types";
import setAuthenticatedUser from "./actions/setAuthenticatedUser";
import clearAuthenticatedUser from "./actions/clearAuthenticationUser";

const actions = {
  setAuthenticatedUser,
  clearAuthenticatedUser,
};

const Store = createStore<GlobalState, GlobalStateActions>({
  actions,
  name: "global-state",
  initialState: {},
});

export type GlobalStateActions = typeof actions;
export const useGlobalState = createHook(Store);
