import { useTheme } from "@material-ui/core";
import { useMemo } from "react";
import imgCardFinancial from "../assets/card-financial.png";
import imgCardRelationship from "../assets/card-relationship.png";
import imgCardLegacy from "../assets/card-legacy.png";
import imgCardExperience from "../assets/card-experience.png";
import imgCardBackface from "../assets/card-backface.png";
import { DeckCategory } from "../domain/backend";

interface DeckStyles {
  bg: string;
  text: string;
  image: string;
}

function useDeckStyles(category?: DeckCategory): DeckStyles {
  const theme = useTheme();

  return useMemo(() => {
    switch (category) {
      case DeckCategory.LEGACY:
        return {
          bg: theme.palette.legacy,
          text: theme.palette.white,
          image: imgCardLegacy,
        };
      case DeckCategory.EXPERIENCE:
        return {
          bg: theme.palette.experience,
          text: theme.palette.white,
          image: imgCardExperience,
        };
      case DeckCategory.RELATIONSHIP:
        return {
          bg: theme.palette.relationship,
          text: theme.palette.white,
          image: imgCardRelationship,
        };
      case DeckCategory.FINANCIAL:
        return {
          bg: theme.palette.financial,
          text: theme.palette.white,
          image: imgCardFinancial,
        };
      default:
        return {
          bg: theme.palette.black,
          text: theme.palette.white,
          image: imgCardBackface,
        };
    }
  }, [
    category,
    theme.palette.black,
    theme.palette.experience,
    theme.palette.financial,
    theme.palette.legacy,
    theme.palette.relationship,
    theme.palette.white,
  ]);
}

export default useDeckStyles;
