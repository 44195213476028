import React, { FC, useLayoutEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Text from "../../../components/Text";
import { StatusColorCircle, Divider } from "../styles";

interface Props {}

const SignificanceTracker: FC<Props> = (props: Props): JSX.Element => {
  const colorRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    colorRef.current?.setAttribute(
      "style",
      "background-color:{{this.status.color}};"
    );
  }, []);

  return (
    <>
      <Box>
        <Text fontWeight={"bold"} size={1.2} color={"black"} mb={0.5}>
          {"{{clientName}}"}
        </Text>
        <Divider width={"50%"} />
      </Box>
      {"{{#each actions}}"}
      <Box
        mt={2}
        sx={{
          breakInside: "avoid",
        }}
      >
        <Text fontWeight={"bold"} size={1} color={"black"}>
          {"{{this.type}}"}
        </Text>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StatusColorCircle ref={colorRef} />
          <Text size={1} color={"black"} ml={0.5} my={0.5}>
            {"{{this.status.title}}"}
          </Text>
        </Box>
        <Text size={1} color={"black"}>
          {"{{this.checkInDate}}"}
        </Text>
        <Text size={0.9} color={"black"} mt={2} mb={3.5}>
          {"{{this.description}}"}
        </Text>
        <Divider width={"100%"} />
      </Box>
      {"{{/each}}"}
    </>
  );
};

export default SignificanceTracker;
