import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled(motion.div)<{ height: string }>`
  position: relative;
  height: ${(props) => props.height};
  width: auto;
`;

export const ImgTalkSigWheel = styled(motion.img)`
  -webkit-user-drag: none;
  user-select: none;
  width: auto;
  height: 100%;
`;

export const ImgTalkSigText = styled.img`
  -webkit-user-drag: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
`;
