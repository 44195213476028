import * as React from "react";
import { TableRowStyled } from "./styled";

interface Props {
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
  canSelect?: boolean;
}

const TableRow: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <TableRowStyled
      canSelect={props.canSelect}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {Array.isArray(props.children)
        ? props.children.map((child) => child)
        : props.children}
    </TableRowStyled>
  );
};

export default TableRow;
