import React, { FC, useState, useRef, useLayoutEffect } from "react";
import { Box } from "@material-ui/core";
import HeadingSubtitle from "./HeadingSubtitle";
import HeadingTitle from "./HeadingTitle";

interface Props {
  title?: string;
  subTitle?: string;
}

const Heading: FC<Props> = ({ title, subTitle }): JSX.Element => {
  const refSubtitle = useRef<HTMLDivElement>();
  const [subtitleWidth, setSubtitleWidth] = useState<number>();

  useLayoutEffect(() => {
    setSubtitleWidth(refSubtitle.current?.getBoundingClientRect?.().width);
  }, [refSubtitle, setSubtitleWidth]);

  return (
    <div>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "1rem",
            width: "48rem",
          }}
        >
          <HeadingSubtitle ref={refSubtitle}>{subTitle}</HeadingSubtitle>
        </Box>
      </Box>
      <Box
        sx={{
          width: `calc(100% - ${subtitleWidth}px)`,
        }}
      >
        <HeadingTitle>{title}</HeadingTitle>
      </Box>
    </div>
  );
};

export default Heading;
