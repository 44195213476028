import React, { FC } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import TalkSigWheel from "../../../components/TalkSigWheel";
import Text from "../../../components/Text";
import MainLayout from "../../../layouts/MainLayout";
import { motion } from "framer-motion";

interface Props {}

const DialogueEnd: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <MainLayout isBgTiled>
      <Grid item container spacing={5} alignItems={"center"}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: isMdDown ? "center" : "flex-end",
            }}
          >
            <TalkSigWheel height={isMdDown ? "30vh" : undefined} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            textAlign: isMdDown ? "center" : "left",
          }}
        >
          <motion.div
            initial={{ opacity: 0, translateX: "-10rem" }}
            animate={{ opacity: 1, translateX: "0rem" }}
            transition={{ duration: 0.5, delay: 3.3 }}
          >
            <Text font={theme.font.arialBlack} size={isMdDown ? 5 : 9.4}>
              Thanks!
            </Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, translateX: "-10rem" }}
            animate={{ opacity: 1, translateX: "0rem" }}
            transition={{ duration: 0.5, delay: 3.5 }}
          >
            <Text
              size={isMdDown ? 1.8 : 2.5}
              font={theme.font.arialBlack}
              color={theme.palette.gold}
            >
              We're on the journey
              <br />
              Beyond Success towards
              <br />
              Significance.
            </Text>
          </motion.div>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default DialogueEnd;
