import React, { FC } from "react";
import imgTalkSigTextLogo from "../../assets/talksig-text-logo.png";
import { ImgTalkSigTextLogo } from "./styles";

interface Props {
  width?: number;
  height?: number;
}

const TalkSigTextLogo: FC<Props> = ({ width, height }): JSX.Element => {
  return (
    <ImgTalkSigTextLogo
      src={imgTalkSigTextLogo}
      alt={"Talking Significance Logo"}
      width={width?.toString() ?? "29rem"}
      height={height?.toString() ?? "auto"}
    />
  );
};

export default TalkSigTextLogo;
