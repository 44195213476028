import * as React from "react";
import { TableDataStyled } from "./styled";

interface Border {
  right?: string;
  left?: string;
  top?: string;
  bottom?: string;
}

interface Props {
  align?: "start" | "end" | "center";
  children?: JSX.Element | JSX.Element[] | string;
  border?: Border;
  onClick?: () => void;
}

const TableData: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <TableDataStyled
      textAlign={props.align}
      borderRight={props.border ? props.border.right : undefined}
      borderLeft={props.border ? props.border.left : undefined}
      borderTop={props.border ? props.border.top : undefined}
      borderBottom={props.border ? props.border.bottom : undefined}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {Array.isArray(props.children)
        ? props.children.map((child) => child)
        : props.children}
    </TableDataStyled>
  );
};

export default TableData;
