export interface LoginForm {
  username: string;
  password: string;
  canRememberDevice: boolean;
}

export enum AuthErrors {
  UNAUTHORIZED = "NotAuthorizedException",
  PASSWORD_RESET = "PasswordResetRequiredException",
  NOT_FOUND = "UserNotFoundException",
  NOT_CONFIRMED = "UserNotConfirmedException",
}
