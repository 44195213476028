import React, { FC, useCallback } from "react";
import MainLayout from "../../../layouts/MainLayout";
import { Box, Grid } from "@material-ui/core";
import Text from "../../../components/Text";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useTheme } from "@material-ui/core";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { ROUTES } from "../../../domain/frontend";
import { Redirect } from "react-router-dom";
import { extractRedirectUrl } from "../../../utils/extract-redirect-url";

const InternalError: FC = (): JSX.Element => {
  const theme = useTheme();
  const url = new URL(window.location.href.replace("/#", ""));
  const isSessionTimeout = url.searchParams.get("cause") === "sessionTimeout";
  const redirectUrl =
    url.searchParams.get("redirect") ?? window.location.origin;

  const handleRestart = useCallback(() => {
    window.location.href = ROUTES.home;
  }, []);

  return isSessionTimeout && redirectUrl ? (
    <Redirect
      to={`${ROUTES.login}?redirect=${extractRedirectUrl(redirectUrl)}`}
    />
  ) : (
    <MainLayout>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: `calc(100vh - ${theme.footer.height}rem)`,
        }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item>
            <SentimentVeryDissatisfiedIcon
              sx={{
                fontSize: "7rem",
                color: theme.palette.gold,
              }}
            />
          </Grid>
          <Grid item>
            <Text px={3} size={3.5}>
              Oops...
            </Text>
            <Text px={3}>
              Something went wrong and we couldn't recover. This error has been
              reported to the support team.
              <br />
              Click the button below to restart at the home page.
            </Text>
            <Box mt={3} sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonPrimary onClick={handleRestart}>Restart</ButtonPrimary>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default InternalError;
