import React, { FC } from "react";
import { DivCardContainer } from "./styles";
import useDeckStyles from "../../hooks/use-deck-styles";
import { Card, DeckCategory } from "../../domain/backend";
import { useCardSize } from "../../hooks/use-card-size";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  card?: Card<DeckCategory>;
  opacity?: number;
  size?: "regular" | "medium" | "small";
}

const CardDisplay: FC<Props> = ({ card, opacity, size }): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const deckColors = useDeckStyles(card?.category);
  const cardSizing = useCardSize(size ?? (isMdDown ? "medium" : "regular"));

  return (
    <DivCardContainer
      size={cardSizing}
      deckStyles={deckColors}
      style={{
        opacity: opacity ?? 1,
      }}
    >
      {card?.title}
    </DivCardContainer>
  );
};

export default CardDisplay;
