import React, { FC, useMemo } from "react";
import { Box, ButtonBase as MuiButtonBase, useTheme } from "@material-ui/core";
import { calculateButtonSizing } from "./functions";
import { ButtonBaseProps } from "./types";
import { LoadingIcon } from "../LoadingIcon";

const ButtonBase: FC<ButtonBaseProps> = ({
  fullWidth,
  sx,
  size,
  children,
  onClick,
  type,
  isLoading,
  isDisabled,
}): JSX.Element => {
  const theme = useTheme();
  const btnSizing = useMemo(
    () => calculateButtonSizing(size, fullWidth),
    [fullWidth, size]
  );

  return (
    <MuiButtonBase
      sx={{
        ...sx,
        padding: btnSizing.padding,
        fontSize: btnSizing.fontSize,
        height: btnSizing.height,
        width: btnSizing.width,
        fontFamily: theme.font.arialBlack,
        textTransform: "uppercase",
        letterSpacing: "0.2rem",
        transition: "all 200ms linear",
      }}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        {children}
        {isLoading && (
          <Box ml={1}>
            <LoadingIcon />
          </Box>
        )}
      </Box>
    </MuiButtonBase>
  );
};

export default ButtonBase;
