import React, { FC, useCallback } from "react";
import Text from "../../../../components/Text";
import DividingLine from "../../../../components/DividingLine";
import ButtonTertiary from "../../../../components/Button/ButtonTertiary";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { DivDeckContainer } from "../styles";
import { useHistory } from "react-router-dom";
import DeckDisplay from "../../../../components/DeckDisplay";
import { DeckCategory } from "../../../../domain/backend";
import { DndDeck } from "../../../../domain/frontend";
import { useCardSize } from "../../../../hooks/use-card-size";

interface Props {
  deck: DndDeck<DeckCategory>;
}

const ExploreDeckTile: FC<Props> = ({ deck }): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const sizing = useCardSize(isMdDown ? "medium" : "regular");
  const history = useHistory();

  const exploreDeck = useCallback(() => {
    history.push(deck.href);
  }, [deck.href, history]);

  return (
    <DivDeckContainer width={sizing.width}>
      <DeckDisplay />
      <Box
        py={3}
        px={3}
        sx={{
          textAlign: "center",
        }}
      >
        <Text font={theme.font.arialBlack} size={isMdDown ? 1.8 : 2.4}>
          {deck.title}
        </Text>
        <Text
          font={theme.font.din}
          color={theme.palette.gold}
          size={isMdDown ? 1.5 : 1.6}
          mt={2}
          mb={2.5}
        >
          {deck.description}
        </Text>
        <DividingLine color={theme.palette.gray} />
        <Box mt={5}>
          <ButtonTertiary onClick={exploreDeck}>Explore</ButtonTertiary>
        </Box>
      </Box>
    </DivDeckContainer>
  );
};

export default ExploreDeckTile;
