import React from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import Text from "../../Text";
import Anchor from "../../Anchor";
import config from "../../../config";
import Button from "../../Button/ButtonTertiary";
import { NavbarLinkModel } from "../../Navbar/types";

interface Props {
  navbarLinks: NavbarLinkModel[];
}

const Menu: React.FC<Props> = ({ navbarLinks }): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Text font={"Arial Black"} size={1.3} mb={3} mt={isMdDown ? 3 : 0}>
        Menu
      </Text>
      <Text mb={3} lineHeight={1.5}>
        <ul>
          {navbarLinks.map((link) => (
            <li key={link.label}>
              <Anchor href={`${link.href}`}>{link.label}</Anchor>
            </li>
          ))}
        </ul>
      </Text>
      <Box mt={3}>
        <Anchor
          noStyle
          href={config.mosaic.homeUrl}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <Button>More About Mosaic</Button>
        </Anchor>
      </Box>
    </>
  );
};

export default Menu;
