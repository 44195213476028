import styled from "styled-components/macro";

export const TableStyled = styled.table`
  width: 100%;
  border-spacing: 0;
  tbody {
    tr {
      &:hover {
        background-color: ${(props) => props.theme.palette.gray};
      }
      transition: background-color 200ms ease-in-out;
    }
  }
`;
