import styled from "styled-components/macro";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 1.4rem;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: gold !important;

  &:hover {
    background-color: rgba(200, 146, 10, 0.08);
  }
`;

export const SpanCheckboxEmpty = styled.span`
  height: 1.8rem;
  width: 1.8rem;
  border: 2px solid ${(props) => props.theme.palette.gold};

  input:hover {
    border: 2px solid darkgray;
  }
`;

export const SpanCheckboxFull = styled(SpanCheckboxEmpty)`
  &:before {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/%3E%3C/svg%3E");
  }

  input:hover {
    &:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='var(--gray)' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='gray'/%3E%3C/svg%3E");
    }
  }
`;
