import { Action } from "react-sweet-state";
import { DialogueState } from "../types";
import { DeckCategory } from "../../../domain/backend";
import { DndCard } from "../../../domain/frontend";
import dcopy from "deep-copy";

const savePriorities =
  (overall: DndCard<DeckCategory>[]): Action<DialogueState> =>
  ({ getState, setState }) => {
    const prevState = getState();
    const dialogue = prevState.dialogues[prevState.currentDialogueIndex];

    dialogue.overallTop4 = overall;

    setState(dcopy(prevState));
  };

export default savePriorities;
