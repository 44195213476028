import styled from "styled-components/macro";
import imgCardBackface from "../../assets/card-backface.png";

export const DivDeckDisplayContainer = styled.div<{
  size: {
    width: number;
    height: number;
    padding: number;
    font: number;
  };
}>`
  user-select: none;
  width: ${(props) => props.size.width}rem;
  height: ${(props) => props.size.height}rem;
  padding: ${(props) => props.size.padding}rem;
  font-size: ${(props) => props.size.font}rem;
  font-family: ${(props) => props.theme.font.arialBlack};
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  background-image: url("${imgCardBackface}");
  background-size: contain;
  background-repeat: no-repeat;
`;
