import React, { FC } from "react";
import { Backdrop, CircularProgress, useTheme } from "@material-ui/core";

interface Props {
  isOpen: boolean;
}

const FullPageSpinner: FC<Props> = ({ isOpen }): JSX.Element => {
  const theme = useTheme();

  return (
    <Backdrop style={{ zIndex: theme.zIndex.modal }} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullPageSpinner;
