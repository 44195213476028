import { Box, Grid, useTheme } from "@material-ui/core";
import React, { FC } from "react";
import MainLayout from "../MainLayout";
import FullPageSpinner from "../../components/FullPageSpinner";
import Text from "../../components/Text";
import HeadingTitle from "../../components/Heading/HeadingTitle";

interface Props {
  title: string;
  errorMessage?: string;
  isLoading?: boolean;
  children?: any;
}

const AdminLayout: FC<Props> = ({
  title,
  errorMessage,
  isLoading,
  children,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <FullPageSpinner isOpen={isLoading ?? false} />
      <MainLayout>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} md={10} lg={7} xl={8}>
            <Box mb={3}>
              <HeadingTitle>{title}</HeadingTitle>
            </Box>
            {children}
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid item xs={12} my={5}>
            <Text
              color={theme.palette.formError}
              size={1.8}
              textAlign={"center"}
            >
              {errorMessage}
            </Text>
          </Grid>
        )}
      </MainLayout>
    </>
  );
};

export default AdminLayout;
