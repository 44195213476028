import { useMemo } from "react";
import { useTheme } from "@material-ui/core";

export function useCardSize(size: "regular" | "medium" | "small") {
  const theme = useTheme();

  return useMemo(() => {
    if (size === "small") {
      return {
        width: theme.card.small.width,
        height: theme.card.small.height,
        padding: 1,
        font: 0.9,
      };
    } else if (size === "medium") {
      return {
        width: theme.card.medium.width,
        height: theme.card.medium.height,
        padding: 1.8,
        font: 1.4,
      };
    } else {
      return {
        width: theme.card.normal.width,
        height: theme.card.normal.height,
        padding: 2,
        font: 1.5,
      };
    }
  }, [
    size,
    theme.card.medium.height,
    theme.card.medium.width,
    theme.card.normal.height,
    theme.card.normal.width,
    theme.card.small.height,
    theme.card.small.width,
  ]);
}
