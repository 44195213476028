import { Action } from "react-sweet-state";
import { DialogueState } from "../types";
import dcopy from "deep-copy";

const moveToNextDialogue =
  (): Action<DialogueState> =>
  ({ setState, getState }) => {
    const prevState = getState();

    const lastIndex = prevState.dialogues.length - 1;
    const nextIndex = prevState.currentDialogueIndex + 1;

    setState(
      dcopy({
        ...prevState,
        currentDialogueIndex: nextIndex > lastIndex ? lastIndex : nextIndex,
      })
    );
  };

export default moveToNextDialogue;
