import styled from "styled-components/macro";

export const DivCardContainer = styled.div<{
  deckStyles: {
    bg: string;
    text: string;
    image: string;
  };
  size: {
    width: number;
    height: number;
    padding: number;
    font: number;
  };
}>`
  user-select: none;
  width: ${(props) => props.size.width}rem;
  height: ${(props) => props.size.height}rem;
  padding: ${(props) => props.size.padding}rem;
  font-size: ${(props) => props.size.font}rem;
  font-family: ${(props) => props.theme.font.arialBlack};
  background-color: ${(props) => props.deckStyles.bg};
  background-image: url("${(props) => props.deckStyles.image}");
  background-repeat: no-repeat;
  background-size: contain;
  color: ${(props) => props.deckStyles.text};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
