import Joi from "joi";
import { requiredString } from "../../../utils/schema";

export const loginFormSchema = Joi.object({
  username: requiredString(
    "Username",
    Joi.string().email({ tlds: false }).min(1).max(200)
  ),
  password: requiredString("Password", Joi.string().min(1).max(200)),
  canRememberDevice: Joi.boolean().optional(),
}).label("LoginFormSchema");
