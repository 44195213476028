import config from "../../../../config";
import httpClient from "../../../../utils/http-client";
import { ListUsersResponse } from "./types";

export async function getUserList(): Promise<ListUsersResponse> {
  const response = await httpClient.fetch(
    `${config.talksig.apiUrl}/admin/users`,
    {
      method: "GET",
    }
  );

  return response.json();
}
