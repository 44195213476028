import { CognitoUser } from "amazon-cognito-identity-js";

export interface VerifyCodeForm {
  code: string;
}

export interface VerifyCodeLocationState {
  user?: CognitoUser;
  username?: string;
  isUnconfirmedUser: boolean;
  canRememberDevice: boolean;
}

export enum MfaError {
  MISMATCH = "CodeMismatchException",
  EXPIRED = "ExpiredCodeException",
}
