import React, { FC, useLayoutEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import SignificanceTracker from "./significance-tracker";
import { Box, capitalize, useTheme } from "@material-ui/core";
import { ImgLogo } from "./styles";
import { LOGO_DATA_IMG } from "./constants";
import Text from "../../components/Text";
import { SummarySession } from "./summary-session";

interface Props {}

const Templates: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const { template } = useParams<{ template: string }>();

  const renderTemplate = useMemo(() => {
    switch (template) {
      case "significance-tracker":
        return <SignificanceTracker />;
      case "summary-session":
        return <SummarySession />;
      default:
        throw new Error(`Template ${template} doesn't exist.`);
    }
  }, [template]);

  const templateDisplayName = useMemo(
    () =>
      template
        .split("-")
        .filter((word) => word !== "shared")
        .map((word) => capitalize(word))
        .join(" "),
    [template]
  );

  useLayoutEffect(() => {
    document.body.setAttribute(
      "style",
      "-webkit-print-color-adjust: exact !important;background-color:white;color:black;"
    );
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "50%",
          }}
          mb={3}
        >
          <ImgLogo src={LOGO_DATA_IMG} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "right",
            justifyContent: "space-around",
          }}
        >
          <Text size={1} color={"black"}>
            {"{{createdDate}}"}
          </Text>
          <Text
            size={1.6}
            color={theme.palette.gold}
            sx={{
              textTransform: "uppercase",
              letterSpacing: "0.2rem",
            }}
          >
            {templateDisplayName}
          </Text>
        </Box>
      </Box>
      {renderTemplate}
    </Box>
  );
};

export default Templates;
