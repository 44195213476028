import React, { FC } from "react";
import {
  DatePickerProps,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import Input from "../Input";

interface Props extends Omit<DatePickerProps, "renderInput"> {
  renderInput?: (props: TextFieldProps) => React.ReactElement;
}

const DatePicker: FC<Props> = (props: Props): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        {...props}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Input
            ref={inputRef}
            {...(inputProps as any)}
            endAdornment={InputProps?.endAdornment}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
