import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import HeadingTitle from "../../../components/Heading/HeadingTitle";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import {
  defaultErrorMessage,
  DndCard,
  DndCardGroup,
  ROUTES,
} from "../../../domain/frontend";
import { useDialogueState } from "../../../state/dialogue";
import { Client, DeckCategory } from "../../../domain/backend";
import { useHistory } from "react-router-dom";
import { DropTargetMonitor } from "react-dnd/dist/types/types";
import {
  isInSameDeck,
  moveBetweenDecks,
  setPriorityForCardInDeck,
} from "../../../utils/deck-operations";
import { isAllCardsChosen } from "../dialogue-deck-category/utils";
import DndCardHolder from "../../../components/Dnd/DndCardHolder";
import DndCardSet from "../../../components/Dnd/DndCardSet";
import DndCardDisplay from "../../../components/Dnd/DndCardDisplay";
import DndDeckDisplay from "../../../components/Dnd/DndDeckDisplay";
import ButtonTertiary from "../../../components/Button/ButtonTertiary";
import Text from "../../../components/Text";
import { validateTop4Cards } from "./utils";
import Clicker from "../../../components/Clicker";
import { getFirstName, getLastName } from "../../../utils/name-parsing";
import DialogueLayout from "../../../layouts/DialogueLayout";
import { submitDialogue } from "../../../utils/submit-dialogue";
import dcopy from "deep-copy";

interface Props {}

const DialogueSharedPriorities: FC<Props> = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [{ dialogues, topFourSharedCards }] = useDialogueState();
  const [formError, setFormError] = useState("");
  const [top4Cards, setTop4Cards] = useState<DndCard<DeckCategory>[]>([]);
  const [linkedTop4Cards, setLinkedTop4Cards] = useState<
    DndCard<DeckCategory>[]
  >([]);
  const [chosenTop4Cards, setChosenTop4Cards] = useState<
    DndCard<DeckCategory>[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const familyName = useMemo(() => {
    return dialogues
      .map((dialogue) => dialogue.client)
      .reduce((familyName: string, client: Client) => {
        const clientLastName = getLastName(client.name);
        if (!familyName) {
          return clientLastName;
        }

        if (familyName !== clientLastName) {
          familyName += ` & ${clientLastName}`;
        }

        return familyName;
      }, "");
  }, [dialogues]);

  const listOfClientStacks = useMemo(() => {
    return [
      { dialogue: dialogues[0], cards: top4Cards, setCards: setTop4Cards },
      {
        dialogue: dialogues[1],
        cards: linkedTop4Cards,
        setCards: setLinkedTop4Cards,
      },
    ];
  }, [dialogues, linkedTop4Cards, top4Cards]);

  const getTop4ForClient = useCallback(
    (dialogueIndex: number): DndCard<DeckCategory>[] => {
      const dialogue = dialogues[dialogueIndex];

      if (!dialogue) {
        throw new Error(`Dialogue at index ${dialogueIndex} doesnt exist.`);
      }

      return Array.from(
        dialogue.overallTop4.map((card) => {
          const newCard = dcopy(card);

          newCard.group = DndCardGroup.OVERALL_TOP4;
          if (newCard.item?.priority) {
            newCard.item.priority = 0;
          }

          return newCard;
        })
      );
    },
    [dialogues]
  );

  const moveToSharedTop4 = useCallback(
    (
      hoverCard: DndCard<DeckCategory>,
      monitor: DropTargetMonitor,
      card: DndCard<DeckCategory>
    ): void => {
      const isUnassigned = hoverCard.group === DndCardGroup.OVERALL_TOP4;
      const stack = listOfClientStacks.find(({ cards }) =>
        cards.find((crd) => crd.id === hoverCard.id)
      );

      if (!stack) {
        throw new Error(`Card not found in Overall Top 4 Stacks ${card.id}.`);
      }

      const { fromDeck, toDeck } = moveBetweenDecks({
        id: hoverCard.id,
        fromDeck: isUnassigned ? stack.cards : chosenTop4Cards,
        fromGroup: hoverCard.group,
        toIndex: card.index,
        toDeck: isUnassigned ? chosenTop4Cards : stack.cards,
        toGroup: card.group,
      });

      stack.setCards(isUnassigned ? fromDeck : toDeck);
      setChosenTop4Cards(isUnassigned ? toDeck : fromDeck);

      if (isAllCardsChosen(isUnassigned ? toDeck : fromDeck)) {
        setFormError("");
      }

      stack.setCards(isUnassigned ? fromDeck : toDeck);
      setChosenTop4Cards(isUnassigned ? toDeck : fromDeck);
    },
    [listOfClientStacks, chosenTop4Cards]
  );

  const createMoveToClientStack = useCallback(
    (
      cards: DndCard<DeckCategory>[],
      setCards: Dispatch<SetStateAction<DndCard<DeckCategory>[]>>
    ) => {
      return (
        hoverCard: DndCard<DeckCategory>,
        monitor: DropTargetMonitor,
        card: DndCard<DeckCategory>
      ): void => {
        const { fromDeck, toDeck } = moveBetweenDecks({
          id: hoverCard.id,
          fromDeck: chosenTop4Cards,
          fromGroup: hoverCard.group,
          toIndex: card.index,
          toDeck: cards,
          toGroup: card.group,
        });

        setChosenTop4Cards(fromDeck);
        setCards(toDeck);
      };
    },
    [chosenTop4Cards]
  );

  const createSetCardPriority = useCallback(
    (card: DndCard<DeckCategory>) => {
      return (value: number) =>
        setChosenTop4Cards(
          setPriorityForCardInDeck(card.id, value, chosenTop4Cards)
        );
    },
    [chosenTop4Cards]
  );

  const finishDialogue = useCallback(async () => {
    const { isValid, errorMessage } = validateTop4Cards(chosenTop4Cards);
    setFormError(errorMessage);

    if (!isValid) {
      return;
    }

    try {
      setIsLoading(true);

      await submitDialogue(dialogues, chosenTop4Cards);

      history.push(ROUTES.dialogueThankYou);
    } catch (error) {
      setFormError(defaultErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [dialogues, history, chosenTop4Cards]);

  const resetCards = useCallback(() => {
    setFormError("");
    setTop4Cards(getTop4ForClient(0));
    setLinkedTop4Cards(getTop4ForClient(1));
    setChosenTop4Cards(Array.from(topFourSharedCards!));
  }, [getTop4ForClient, topFourSharedCards]);

  useEffect(() => {
    resetCards();
  }, [resetCards]);

  return (
    <DialogueLayout
      title={"Shared Priorities"}
      subTitle={familyName}
      description={
        <Text font={theme.font.arialBlack} size={1.5} textAlign={"center"}>
          Using the slider below each card, let’s determine how well you think
          you are doing with the top priorities you selected.
        </Text>
      }
    >
      <Grid item>
        <DndCardSet
          cards={chosenTop4Cards}
          occupiedComponent={DndCardDisplay}
          emptyComponent={DndCardHolder}
          setCards={setChosenTop4Cards}
          onExternalDrop={moveToSharedTop4}
        />
      </Grid>
      <Grid item container spacing={isMdDown ? 3 : 4} justifyContent={"center"}>
        {chosenTop4Cards.map((card) => (
          <Grid key={card.id} item>
            <Clicker
              value={card.item?.priority}
              onChange={createSetCardPriority(card)}
              isDisabled={!card.item}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container spacing={5}>
        {listOfClientStacks.map((stack, index) => (
          <Grid
            sx={{ paddingTop: "2rem !important" }}
            key={stack.dialogue.client.id}
            item
            container
            direction={"column"}
            xs={6}
          >
            <Grid item mb={3}>
              <HeadingTitle isInverted={index % 2 === 1}>
                {getFirstName(stack.dialogue.client.name)}
              </HeadingTitle>
            </Grid>
            <Grid item container spacing={1} justifyContent={"space-between"}>
              <DndCardSet
                size={"small"}
                cards={stack.cards}
                occupiedComponent={DndCardDisplay}
                emptyComponent={DndDeckDisplay}
                setCards={stack.setCards}
                onExternalDrop={createMoveToClientStack(
                  stack.cards,
                  stack.setCards
                )}
                canHover={(hoverItem, dndCard) => {
                  return isInSameDeck(
                    hoverItem.id,
                    dndCard.id,
                    ...listOfClientStacks.map((stack) => stack.cards)
                  );
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        justifyContent={"center"}
        columnSpacing={10}
        rowSpacing={4}
      >
        <Grid item xs={12} mt={5}>
          {formError && (
            <Text
              size={1.8}
              color={theme.palette.formError}
              textAlign={"center"}
            >
              {formError}
            </Text>
          )}
        </Grid>
        <Grid item>
          <ButtonTertiary size={"large"} onClick={resetCards}>
            Reset
          </ButtonTertiary>
        </Grid>
        <Grid item>
          <ButtonPrimary
            size={"large"}
            onClick={finishDialogue}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Finish Dialogue
          </ButtonPrimary>
        </Grid>
      </Grid>
    </DialogueLayout>
  );
};

export default DialogueSharedPriorities;
