import styled from "styled-components/macro";
import { Autocomplete } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";

export const StyledAutoComplete = styled(Autocomplete)``;

export const InputAutoComplete = styled.input`
  background-color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.font.arial};
  font-size: 1.4rem;
  width: 100%;
  color: ${(props) => props.theme.palette.black};
  border-radius: 4px;
  min-height: 5rem;
  padding: 0 1.8rem;
  border: none;
`;

export const SvgErrorIcon = styled(ErrorOutline)`
  font-size: 2.2rem;
  color: ${(props) => props.theme.palette.formError};
`;
