import React from "react";
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Anchor from "../Anchor";
import {
  AnchorNavbarLink,
  DivNavbarContainer,
  LiNavbarLinkList,
  UlNavbarLinkList,
} from "./styles";
import { NavbarLinkModel } from "./types";
import { useHash } from "react-use";
import ProfileSection from "./parts/profile-section";
import { isActiveLink } from "./functions";
import { ROUTES } from "../../domain/frontend";
import { useGlobalState } from "../../state/global";
import TalkSigLogo from "../TalkSigLogo";

interface Props {
  navbarLink: NavbarLinkModel[];
  dropdownLinks: NavbarLinkModel[];
  isSecure?: boolean;
}

const Navbar: React.FC<Props> = ({
  navbarLink,
  dropdownLinks,
  isSecure,
}): JSX.Element => {
  const [url] = useHash();
  const [{ user }] = useGlobalState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth={"xl"} disableGutters>
      <DivNavbarContainer>
        <Grid item container xs={2} justifyContent={"center"}>
          <Grid item>
            <Anchor noStyle href={!!user ? `/#${ROUTES.dashboard}` : `/#/`}>
              <TalkSigLogo />
            </Anchor>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={9}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <UlNavbarLinkList>
              {navbarLink.map((link) => (
                <LiNavbarLinkList key={link.label}>
                  <AnchorNavbarLink
                    isMobile={isMobile}
                    isActive={isActiveLink(url, link)}
                    href={link.href}
                  >
                    {link.label}
                  </AnchorNavbarLink>
                </LiNavbarLinkList>
              ))}
              {!isMobile && isSecure && (
                <li>
                  <ProfileSection links={dropdownLinks} />
                </li>
              )}
            </UlNavbarLinkList>
          </Grid>
        </Grid>
      </DivNavbarContainer>
    </Container>
  );
};

export default Navbar;
