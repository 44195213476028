import { DndCard } from "../../../domain/frontend";
import { DeckCategory } from "../../../domain/backend";

export function isAllCardsChosen(
  cardsToCheck: DndCard<DeckCategory>[]
): boolean {
  return cardsToCheck.filter((card) => !!card.item).length >= 4;
}

export function areAllCardsPrioritized(
  cards: DndCard<DeckCategory>[]
): boolean {
  return cards.every((card) => card.item?.priority && card.item.priority > 0);
}

export function validateTop4Cards(top4Cards: DndCard<DeckCategory>[]): {
  isValid: boolean;
  errorMessage: string;
} {
  if (!isAllCardsChosen(top4Cards)) {
    return {
      isValid: false,
      errorMessage: "Please choose a shared top 4 cards before continuing.",
    };
  }

  if (!areAllCardsPrioritized(top4Cards)) {
    return {
      isValid: false,
      errorMessage:
        "Please choose a health index for each shared top 4 card before continuing.",
    };
  }

  return { isValid: true, errorMessage: "" };
}
