import React, { FC } from "react";
import { Box, useTheme } from "@material-ui/core";
import Text from "../../Text";

interface Props {
  name: string;
  error?: any;
  required?: boolean;
  children?: any;
}

const FieldWrapper: FC<Props> = ({
  name,
  error,
  required,
  children,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Box>
      <Box mb={1}>
        <Text color={theme.palette.gold} font={theme.font.arial}>
          {name}
          {required && (
            <Text component={"span"} color={theme.palette.formError}>
              {" *"}
            </Text>
          )}
        </Text>
      </Box>
      <Box>{children}</Box>
      <Box mt={1}>
        <Text color={theme.palette.formError}>
          {!!error ? error.message : ""}
        </Text>
      </Box>
    </Box>
  );
};

export default FieldWrapper;
