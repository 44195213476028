import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../domain/frontend";
import useCurrentDialogue from "../../state/dialogue/selectors/use-current-dialogue";
import SecureRoute from "../SecureRoute";

interface Props {
  path: string;
  children: any;
  exact?: boolean;
}

const DialogueRoute: FC<Props> = ({
  path,
  children,
  exact,
}: Props): JSX.Element => {
  const [dialogue] = useCurrentDialogue();

  if (!!dialogue) {
    return (
      <SecureRoute path={path} exact={exact}>
        {children}
      </SecureRoute>
    );
  } else {
    return <Redirect to={ROUTES.dashboard} />;
  }
};

export default DialogueRoute;
