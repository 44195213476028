import React, { FC } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import TalkSigTextLogo from "../../../../components/TalkSigTextLogo";
import HeadingTitle from "../../../../components/Heading/HeadingTitle";

interface Props {}

const Heading: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box mb={3} ml={isMdDown ? 1 : 0} mr={isMdDown ? 1 : 0}>
      <Grid item>
        <Box mb={2.5}>
          <TalkSigTextLogo />
        </Box>
      </Grid>
      <Grid item>
        <HeadingTitle fontSize={2.4} width={19.3}>
          Client Dialogue Dashboard
        </HeadingTitle>
      </Grid>
    </Box>
  );
};

export default Heading;
