import React, { FC, useLayoutEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Text from "../../../components/Text";
import { Divider, PdfCard, PdfCardText, SummaryContainer } from "../styles";

interface Props {}

export const SummarySession: FC<Props> = (props: Props): JSX.Element => {
  const cardRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    cardRef.current?.setAttribute("style", "background-color:{{this.color}};");
  });

  return (
    <SummaryContainer className={"container"}>
      {"{{#each dialogues}}"}
      <Box
        className={"dialogue"}
        sx={{
          breakInside: "avoid",
        }}
      >
        <Box>
          <Text fontWeight={"bold"} size={1.2} color={"black"} mb={0.5}>
            {"{{this.clientName}}"}
          </Text>
          <Divider width={"50%"} />
        </Box>
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              breakInside: "avoid",
            }}
          >
            {"{{#each this.cards}}"}
            <Box
              sx={{
                position: "relative",
                width: "24%",
              }}
            >
              <PdfCardText>{"{{this.title}}"}</PdfCardText>
              <PdfCard src={"{{this.dataImg}}"} />
            </Box>
            {"{{/each}}"}
          </Box>
        </Box>
        {"{{#each this.cards}}"}
        <Box
          mt={3}
          sx={{
            breakInside: "avoid",
          }}
          className={"card"}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Text fontWeight={"bold"} size={1} color={"black"}>
              {"{{this.title}}"}
            </Text>
            <Text ml={3} size={1} color={"black"}>
              {"{{this.healthText}}"}
            </Text>
          </Box>
          <Text size={0.9} color={"black"} mt={1.5} mb={2.5}>
            {"{{this.commentary}}"}
          </Text>
          <Divider className={"divider"} width={"100%"} />
        </Box>
        {"{{/each}}"}
      </Box>
      {"{{/each}}"}
    </SummaryContainer>
  );
};

export default SummarySession;
