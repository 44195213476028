import styled from "styled-components/macro";

export const TableDataStyled = styled.td<{
  textAlign?: string;
  borderRight?: string;
  borderLeft?: string;
  borderTop?: string;
  borderBottom?: string;
}>`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.font.arial};
  font-size: 1.6rem;
  border-top: 1px solid darkgray;
  padding: 1.6rem 0;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  ${(props) =>
    props.borderRight ? `border-right: ${props.borderRight};` : undefined};
  ${(props) =>
    props.borderLeft ? `border-left: ${props.borderLeft};` : undefined};
  ${(props) =>
    props.borderTop ? `border-top: ${props.borderTop};` : undefined};
  ${(props) =>
    props.borderBottom ? `border-bottom: ${props.borderBottom};` : undefined};
`;
