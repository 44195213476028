import React, { FC } from "react";
import { DivDeckDisplayContainer } from "./styled";
import { useCardSize } from "../../hooks/use-card-size";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  size?: "regular" | "small";
}

const DeckDisplay: FC<Props> = ({ size }): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const cardSizing = useCardSize(size ?? isMdDown ? "medium" : "regular");

  return <DivDeckDisplayContainer size={cardSizing} />;
};

export default DeckDisplay;
