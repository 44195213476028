import React, {
  FC,
  forwardRef,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { InputAutoComplete, StyledAutoComplete, SvgErrorIcon } from "./styles";
import { AutoCompleteOption } from "./types";
import debounce from "lodash.debounce";
import { Box, AutocompleteInputChangeReason } from "@material-ui/core";
import { LoadingIcon } from "../LoadingIcon";

interface Props {
  onChange?: (option: any) => void;
  options?: AutoCompleteOption[];
  fetchOptions?: (search: string) => Promise<AutoCompleteOption[]>;
  placeholder?: string;
}

const AutoComplete: FC<Props> = forwardRef(
  (props: Props, ref: any): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [retrievedOptions, setRetrievedOptions] = useState<
      AutoCompleteOption[]
    >([]);
    const { fetchOptions, ...omitProps } = props;

    const getLabel = useCallback((option: any): string => {
      return option.title;
    }, []);

    const isOptionEqual = useCallback((option: any, value: any): boolean => {
      if (!value.title) {
        return true;
      }

      return value.value === option.value;
    }, []);

    const throttledFetch = useMemo(
      () =>
        debounce(async (searchVal: string): Promise<void> => {
          if (props.fetchOptions) {
            setIsErrored(false);
            setIsLoading(true);

            try {
              setRetrievedOptions(await props.fetchOptions(searchVal));
            } catch (error) {
              setIsErrored(true);
            } finally {
              setIsLoading(false);
            }
          }
        }, 1000),
      [props]
    );

    const handleSelectValue = useCallback(
      (_: SyntheticEvent, option: any) => {
        props.onChange?.(option);
      },
      [props]
    );

    const handleInputChange = useCallback(
      (
        _: SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => {
        if (value.length >= 1 && !props.options && reason === "input") {
          throttledFetch(value);
        }
      },
      [props.options, throttledFetch]
    );

    return (
      <StyledAutoComplete
        {...omitProps}
        ref={ref}
        options={props.options ?? retrievedOptions}
        getOptionLabel={getLabel}
        isOptionEqualToValue={isOptionEqual}
        onChange={handleSelectValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <Box
            ref={params.InputProps.ref}
            sx={{
              position: "relative",
            }}
          >
            <InputAutoComplete
              placeholder={props.placeholder}
              {...params.inputProps}
            />
            <Box
              sx={{
                position: "absolute",
                top: "1.5rem",
                right: "1rem",
              }}
            >
              {isLoading && <LoadingIcon iconcolor={"black"} />}
              {isErrored && <SvgErrorIcon />}
            </Box>
          </Box>
        )}
      />
    );
  }
);

export default AutoComplete;
