import * as React from "react";
import { TableHeadStyled } from "./styled";

interface Props {
  align?: "start" | "end" | "center";
  children?: JSX.Element | JSX.Element[] | string;
  colSpan?: number;
}

const TableHead: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <TableHeadStyled colSpan={props.colSpan} textAlign={props.align}>
      {Array.isArray(props.children)
        ? props.children.map((child) => child)
        : props.children}
    </TableHeadStyled>
  );
};

export default TableHead;
