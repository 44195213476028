import styled from "styled-components/macro";

export const DivManageUserLayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivFormContainer = styled.div`
  padding: 6rem 6rem 4rem;
  width: 60rem;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
  } ;
`;
