import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {}

const ScrollToTop: FC<Props> = (props: Props): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, [pathname]);

  return <></>;
};

export default ScrollToTop;
