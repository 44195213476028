import styled from "styled-components/macro";

export const DivCardHolderContainer = styled.div<{
  border: string;
  height: number;
  width: number;
}>`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width}rem;
  height: ${(props) => props.height}rem;
  border: ${(props) => props.border};
`;
