import { MfaError } from "./types";
import { defaultErrorMessage } from "../../../domain/frontend/constants/copy";

export function getErrorMessage(error: Error): string {
  switch (error.name) {
    case MfaError.MISMATCH:
      return "The code is incorrect.";
    case MfaError.EXPIRED:
      return "The code has expired, request a new one.";
    default:
      return defaultErrorMessage;
  }
}
