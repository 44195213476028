import { createStore, createHook } from "react-sweet-state";
import { DialogueState } from "./types";
import startDialogue from "./actions/start-dialogue";
import saveDeck from "./actions/save-deck";
import saveOverallTopFour from "./actions/save-overall-top-four";
import savePriorities from "./actions/save-priorities";
import moveToNextDialogue from "./actions/move-to-next-dialogue";

const actions = {
  startDialogue,
  saveDeck,
  saveOverallTopFour,
  savePriorities,
  moveToNextDialogue,
};

export const DialogueStore = createStore<DialogueState, DialogueActions>({
  actions,
  name: "dialogue-state",
  initialState: {
    currentDialogueIndex: 0,
    dialogues: [],
  },
});

export type DialogueActions = typeof actions;
export const useDialogueState = createHook(DialogueStore);
