import styled from "styled-components/macro";
import LoopIcon from "@material-ui/icons/Loop";

export const LoadingIcon = styled(LoopIcon)<{ iconcolor?: string }>`
  font-size: 2.2rem;
  animation: spin 2s linear infinite;
  color: ${(props) => props.iconcolor ?? undefined};

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
