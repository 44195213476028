import React, { FC, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { DropTargetMonitor } from "react-dnd";
import { DndCard } from "../../../domain/frontend";
import { DndCardComponentProps } from "../types";
import { DeckCategory } from "../../../domain/backend";
import { useCardSize } from "../../../hooks/use-card-size";

interface Props {
  cards: DndCard<DeckCategory>[];
  faceUpComponent: FC<DndCardComponentProps>;
  faceDownComponent: FC<DndCardComponentProps>;
  onExternalDrop?: (
    dropItem: DndCard<DeckCategory>,
    monitor: DropTargetMonitor,
    item: DndCard<DeckCategory>
  ) => void;
  canDrop?: (
    dropItem: DndCard<DeckCategory>,
    dndCard: DndCard<DeckCategory>,
    monitor: DropTargetMonitor<DndCard<DeckCategory>>
  ) => boolean;
}

const DndCardSolitaireSet: FC<Props> = ({
  cards,
  faceUpComponent: FaceUpComponent,
  faceDownComponent: FaceDownComponent,
  onExternalDrop,
  canDrop,
}): JSX.Element => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const sizing = useCardSize(isMdDown ? "medium" : "regular");

  const deckLengthOffset = useMemo(() => {
    return (cards.length - 1) * 5.6;
  }, [cards.length]);

  return (
    <Box
      sx={{
        height: `${sizing.height + deckLengthOffset}rem`,
        width: `${sizing.width}rem`,
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        {cards
          .filter((dndCard) => !!dndCard.item)
          .map((dndCard, index) => (
            <Box
              key={dndCard.id}
              sx={{
                position: "absolute",
                top: `calc(var(--g7) * ${index})`,
                zIndex: cards.length - index,
              }}
            >
              {index === 0 ? (
                <FaceUpComponent
                  canDrop={canDrop}
                  index={index}
                  dndCard={dndCard}
                  onExternalDrop={onExternalDrop}
                  dragOpacity={0}
                />
              ) : (
                <FaceDownComponent
                  canDrop={() => false}
                  canDrag={false}
                  index={index}
                  dndCard={dndCard}
                  onExternalDrop={onExternalDrop}
                />
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default DndCardSolitaireSet;
