import React, { FC } from "react";
import { AnchorNoStyle, AnchorStyled } from "./styles";

interface Props {
  href: string;
  noStyle?: boolean;
  target?: string;
  rel?: string;
  onClick?: () => void;
}

const Anchor: FC<Props> = (props: Props): JSX.Element => {
  return props.noStyle ? (
    <AnchorNoStyle onClick={props.onClick} {...props} />
  ) : (
    <AnchorStyled {...props} />
  );
};

export default Anchor;
