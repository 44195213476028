import { Box, useTheme } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import Text from "../../../components/Text";
import { Auth } from "aws-amplify";
import { defaultErrorMessage } from "../../../domain/frontend";
import TalkSigTextLogo from "../../../components/TalkSigTextLogo";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../domain/frontend";
import { useGlobalState } from "../../../state/global";

interface Props {}

const Logout: FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const [, { clearAuthenticatedUser }] = useGlobalState();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const message = useMemo(() => {
    return isLoggedOut
      ? "You have been logged out successfully."
      : "Logging out...";
  }, [isLoggedOut]);

  const logUserOut = useCallback(async () => {
    try {
      await Auth.signOut();

      clearAuthenticatedUser();

      setIsLoggedOut(true);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
    }
  }, [clearAuthenticatedUser]);

  useEffect(() => {
    logUserOut().then(() => {
      setTimeout(() => {
        history.push(ROUTES.home);
      }, 2500);
    });
  }, [history, logUserOut]);

  return (
    <MainLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TalkSigTextLogo />
          </Box>
          <Box
            mt={5}
            p={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.black,
              borderRadius: "1rem",
            }}
          >
            {!errorMessage && <Text size={2.5}>{message}</Text>}
            {errorMessage && (
              <Text size={2.5} color={theme.palette.formError}>
                {errorMessage}
              </Text>
            )}
          </Box>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Logout;
