import * as React from "react";
import { TableStyled } from "./styled";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const Table: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <TableStyled>
      {Array.isArray(props.children)
        ? props.children.map((child) => child)
        : props.children}
    </TableStyled>
  );
};

export default Table;
