import { Action } from "react-sweet-state";
import dcopy from "deep-copy";
import { GlobalState } from "../types";
import { User } from "../../../domain/frontend";
import { OfficeLocation } from "../../../domain/backend";
import httpClient from "../../../utils/http-client";
import { ACCESS_TOKEN_KEY } from "../../../domain/frontend";
import { decode, JwtPayload } from "jsonwebtoken";
import { UserGroups } from "../../../domain/backend/enums/user-groups";
import * as Sentry from "@sentry/react";

const setAuthenticatedUser =
  (
    userAttributes: Record<string, unknown>,
    accessToken: string
  ): Action<GlobalState> =>
  ({ getState, setState }) => {
    const prevState = getState();
    const isAdmin = isUserAdmin(accessToken);

    const user: User = {
      isAdmin,
      username: userAttributes.sub as string,
      name: userAttributes.name as string,
      email: userAttributes.email as string,
      isEmailVerified: userAttributes.email_verified as boolean,
      phone: userAttributes.phone_number as string,
      isPhoneVerified: userAttributes.phone_number_verified as boolean,
      office: userAttributes["custom:office"] as OfficeLocation,
    };

    Sentry.setUser(user);
    httpClient.setAccessToken(accessToken);
    sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

    setState(dcopy({ ...prevState, user }));
  };

function isUserAdmin(accessToken: string): boolean {
  try {
    const decoded = decode(accessToken) as JwtPayload;
    const groups = decoded["cognito:groups"] as string[];

    return groups?.some((group) => group === UserGroups.ADMIN);
  } catch (error) {
    return false;
  }
}

export default setAuthenticatedUser;
