import React from "react";
import MainLayout from "../../../layouts/MainLayout";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import ButtonSpecial from "../../../components/Button/ButtonSpecial";
import TalkSigWheel from "../../../components/TalkSigWheel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Anchor from "../../../components/Anchor";
import { ROUTES } from "../../../domain/frontend";
import { useGlobalState } from "../../../state/global";
import { getFirstName } from "../../../utils/name-parsing";
import FadeIn from "../../../components/Motion/FadeIn";
import { DivWelcomeText } from "./styles";

interface Props {}

const Home: React.FC<Props> = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [{ user }] = useGlobalState();

  return (
    <MainLayout isBgTiled>
      {!!user && (
        <Box pb={5}>
          <DivWelcomeText
            initial={{ opacity: 0, translateX: "-15rem" }}
            animate={{ opacity: 1, translateX: "0rem" }}
            transition={{ duration: 0.5, delay: 3.25 }}
            fontSize={isMobile ? 2 : 3}
          >
            Welcome back, {getFirstName(user.name)}!
          </DivWelcomeText>
        </Box>
      )}
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>
          <TalkSigWheel height={isMobile ? "27.5vh" : "55vh"} />
          <Box display={"flex"} justifyContent={"center"} mt={5}>
            <FadeIn distance={2} delay={3.85} noReplay>
              {user ? (
                <Anchor href={`/#${ROUTES.dashboard}`} noStyle>
                  <ButtonSpecial icon={ArrowForwardIcon}>
                    Dashboard
                  </ButtonSpecial>
                </Anchor>
              ) : (
                <Anchor href={`/#${ROUTES.login}`} noStyle>
                  <ButtonSpecial icon={ArrowForwardIcon}>Login</ButtonSpecial>
                </Anchor>
              )}
            </FadeIn>
          </Box>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Home;
