import React, { FC, useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import { useGlobalState } from "../../state/global";
import { ROUTES } from "../../domain/frontend";
import { extractRedirectUrl } from "../../utils/extract-redirect-url";

interface Props {
  path: string;
  children: any;
  requiresAdmin?: boolean;
  exact?: boolean;
}

const SecureRoute: FC<Props> = ({
  path,
  children,
  requiresAdmin,
  exact,
}: Props): JSX.Element => {
  const [{ user }] = useGlobalState();

  const isUserLoggedIn = useMemo(() => {
    return !!user;
  }, [user]);

  if (isUserLoggedIn) {
    if (requiresAdmin && !user?.isAdmin) {
      return <Redirect to={ROUTES.dashboard} />;
    }

    return (
      <Route exact={exact} path={path}>
        {children}
      </Route>
    );
  } else {
    return (
      <Redirect
        to={`${ROUTES.login}?redirect=${extractRedirectUrl(
          window.location.href
        )}`}
      />
    );
  }
};

export default SecureRoute;
